{
  "_comment": "[CI-LOCKED] Nội dung của tệp này đã bị khóa. Hoan nghênh ý kiến đóng góp, nhưng việc xác nhận thay đổi có thể bị trì hoãn.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Bot $1 đã nói:",
  "ACTIVITY_YOU_SAID_ALT": "Bạn đã nói:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot đã đính kèm:",
  "ACTIVITY_ERROR_BOX_TITLE": "Thông báo lỗi",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Nhấp để tương tác.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Thông báo có tính tương tác.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Có một hoặc nhiều đường liên kết trong tin nhắn.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Không gửi được.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Bạn đã đính kèm:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 tệp đính kèm.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 tệp đính kèm.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 tệp đính kèm.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 tệp đính kèm.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 tệp đính kèm.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Đã không gửi được. [Thử lại][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Đang gửi",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Đã gửi vào lúc $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Vừa xong",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Một giờ trước",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Một phút trước",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Hôm nay",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Hôm qua",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Lỗi phân tích Thẻ thích nghi",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Lỗi kết xuất Thẻ thích nghi",
  "ATTACHMENT_AUDIO": "Đoạn âm thanh.",
  "ATTACHMENT_CARD": "Thẻ: $1 $2 $3",
  "ATTACHMENT_FILE": "Tệp: $1",
  "ATTACHMENT_IMAGE": "Hình ảnh",
  "ATTACHMENT_TEXT": "Văn bản: $1",
  "ATTACHMENT_VIDEO": "Đoạn video",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Tiếp theo",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Trước",
  "CITATION_MODEL_DIALOG_ALT": "Trích dẫn",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Đã kết nối",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Đang kết nối...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Không thể kết nối.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Đã bị gián đoạn mạng. Đang kết nối lại...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Lỗi kết xuất. Vui lòng kiểm tra bảng điều khiển hoặc liên hệ với nhà phát triển bot.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Việc kết nối đang mất nhiều thời gian hơn bình thường.",
  "CONNECTIVITY_STATUS_ALT": "Trạng thái Kết nối: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Tải tệp '$1' xuống",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Tải tệp '$1' kích thước $2 xuống",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' kích thước $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Phím Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Truy nhập các mục trong tin nhắn",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Lịch sử trò chuyện",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Phím Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Thoát khỏi thông báo",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Phím tab\nShift + phím tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Di chuyển giữa các mục trong một tin nhắn",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Phím mũi tên lên\nPhím mũi tên xuống",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Di chuyển giữa các tin nhắn",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Phím Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Thực hiện hành động",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Phím tab\nShift + phím tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Di chuyển giữa các mục trong cửa sổ trò chuyện",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Cửa sổ trò chuyện",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Đóng",
  "KEYBOARD_HELP_HEADER": "Bàn phím điều khiển",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Mở trong cửa sổ mới; bên ngoài.",
  "RECEIPT_CARD_TAX": "Thuế",
  "RECEIPT_CARD_TOTAL": "Tổng số",
  "RECEIPT_CARD_VAT": "Thuế GTGT",
  "REFERENCE_LIST_HEADER_ONE": "$1 tham chiếu",
  "REFERENCE_LIST_HEADER_FEW": "$1 tham chiếu",
  "REFERENCE_LIST_HEADER_MANY": "$1 tham chiếu",
  "REFERENCE_LIST_HEADER_OTHER": "$1 tham chiếu",
  "REFERENCE_LIST_HEADER_TWO": "$1 tham chiếu",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Không thể gửi tin nhắn.",
  "SPEECH_INPUT_LISTENING": "Đang nghe…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Tắt micrô",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Bật micrô",
  "SPEECH_INPUT_STARTING": "Đang bắt đầu...",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Tiếp theo",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Trước",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Hành động được đề xuất",
  "TEXT_INPUT_ALT": "Hộp nhập tin nhắn",
  "TEXT_INPUT_PLACEHOLDER": "Nhập tin nhắn của bạn",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Gửi",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Nói",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Tải lên tệp",
  "TOAST_ACCORDION_FEW": "$1 Thông báo: Bấm vào đây để xem chi tiết",
  "TOAST_ACCORDION_MANY": "$1 Thông báo: Bấm vào đây để xem chi tiết",
  "TOAST_ACCORDION_OTHER": "$1 Thông báo: Bấm vào đây để xem chi tiết",
  "TOAST_ACCORDION_TWO": "$1 Thông báo: Bấm vào đây để xem chi tiết",
  "TOAST_ALT_ERROR": "Lỗi",
  "TOAST_ALT_INFO": "Thông tin",
  "TOAST_ALT_SUCCESS": "Thành công",
  "TOAST_ALT_WARN": "Cảnh báo",
  "TOAST_DISMISS_BUTTON": "Bỏ qua",
  "TOAST_TITLE_ALT": "Thông báo",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Lịch sử trò chuyện, nhấn các phím mũi tên để điều hướng.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "lịch sử trò chuyện",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Tin nhắn có tính tương tác. Bấm phím shift tab 2 đến 3 lần để chuyển sang lịch sử trò chuyện. Sau đó bấm vào tin nhắn để tương tác.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Một hoặc nhiều liên kết trong tin nhắn. Bấm phím shift tab 2 đến 3 lần để chuyển sang lịch sử trò chuyện. Sau đó bấm vào tin nhắn để tương tác.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Thông báo có các hành động được đề xuất. Nhấn tổ hợp phím shift tab 2 đến 3 lần để chọn các hành động đó.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Thông báo có các hành động được đề xuất. Nhấn $1 để chọn các hành động đó.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Không thể gửi tin nhắn.",
  "TRANSCRIPT_MORE_MESSAGES": "Các tin nhắn khác",
  "TRANSCRIPT_NEW_MESSAGES": "Tin nhắn mới",
  "TRANSCRIPT_TERMINATOR_TEXT": "Kết thúc lịch sử trò chuyện",
  "TYPING_INDICATOR_ALT": "Hiển thị chỉ báo đang nhập",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 đang nhập.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 và những người khác đang nhập.",
  "VOTE_DISLIKE_ALT": "Không thích",
  "VOTE_LIKE_ALT": "Thích"
}