{
  "_comment": "[CI-LOCKED] Le contenu de ce fichier est verrouillé. Les contributions sont les bienvenues, mais la validation des modifications peut être retardée.",
  "ACCESS_KEY_ALT": "Alt + Maj + $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Contrôle + Option + $1",
  "ACTIVITY_BOT_SAID_ALT": "Le bot $1 a dit :",
  "ACTIVITY_YOU_SAID_ALT": "Vous avez dit :",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot joint :",
  "ACTIVITY_ERROR_BOX_TITLE": "Message d'erreur",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Cliquez pour interagir.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Le message est interactif.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Un ou plusieurs liens dans le message.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Échec de l’envoi.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Vous avez joint :",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 pièce jointe.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 pièces jointes.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 pièces jointes.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 pièces jointes.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 pièces jointes.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Échec de l'envoi. [Réessayez][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Envoi en cours",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Envoyé à $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "À l'instant",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Il y a une heure",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Il y a une minute",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Aujourd'hui",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Hier",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Erreur d'analyse de la carte adaptative",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Erreur d'affichage de la carte adaptative",
  "ATTACHMENT_AUDIO": "Un clip audio.",
  "ATTACHMENT_CARD": "Une carte : $1 $2 $3",
  "ATTACHMENT_FILE": "Un fichier : $1",
  "ATTACHMENT_IMAGE": "Une image",
  "ATTACHMENT_TEXT": "Un texte : $1",
  "ATTACHMENT_VIDEO": "Un clip vidéo",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Suivant",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Précédent",
  "CITATION_MODEL_DIALOG_ALT": "Citation",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Connecté",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Connexion en cours...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Impossible de se connecter.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Une interruption du réseau s'est produite. Reconnexion en cours...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Erreur de rendu. Vérifiez la console ou contactez le développeur du bot.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "La connexion prend plus de temps que d'habitude.",
  "CONNECTIVITY_STATUS_ALT": "Statut de la connectivité : $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Télécharger le fichier '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Télécharger le fichier '$1' de taille $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' de taille $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Touche Entrée",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Accéder aux éléments du message",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Historique des conversations instantanées",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Touche Échap",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Message de sortie",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Touche Tab\nTouche Maj + Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Se déplacer entre les éléments d’un message",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Touche Flèche haut\nTouche Flèche bas",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Se déplacer entre les messages",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Touche Entrée",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Effectuer une action",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Touche Tab\nTouche Maj + Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Se déplacer entre les éléments de la fenêtre de conversation instantanée",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Fenêtre de conversation instantanée",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Fermer",
  "KEYBOARD_HELP_HEADER": "Contrôles du clavier",
  "MARKDOWN_EXTERNAL_LINK_ALT": "S’ouvre dans une nouvelle fenêtre ; externe.",
  "RECEIPT_CARD_TAX": "Taxe",
  "RECEIPT_CARD_TOTAL": "Total",
  "RECEIPT_CARD_VAT": "TVA",
  "REFERENCE_LIST_HEADER_ONE": "$1 référence",
  "REFERENCE_LIST_HEADER_FEW": "$1 références",
  "REFERENCE_LIST_HEADER_MANY": "$1 références",
  "REFERENCE_LIST_HEADER_OTHER": "$1 références",
  "REFERENCE_LIST_HEADER_TWO": "$1 références",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Impossible d’envoyer un message vide.",
  "SPEECH_INPUT_LISTENING": "Écoute en cours...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Microphone désactivé",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Microphone activé",
  "SPEECH_INPUT_STARTING": "Démarrage en cours...",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Suivant",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Précédent",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Actions suggérées",
  "TEXT_INPUT_ALT": "Zone de saisie du message",
  "TEXT_INPUT_PLACEHOLDER": "Tapez votre message",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Envoyer",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Parler",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Charger le fichier",
  "TOAST_ACCORDION_FEW": "$1 Notifications : cliquez ici pour afficher les détails",
  "TOAST_ACCORDION_MANY": "$1 Notifications : cliquez ici pour afficher les détails",
  "TOAST_ACCORDION_OTHER": "$1 Notifications : cliquez ici pour afficher les détails",
  "TOAST_ACCORDION_TWO": "$1 Notifications : cliquez ici pour afficher les détails",
  "TOAST_ALT_ERROR": "Erreur",
  "TOAST_ALT_INFO": "Informations",
  "TOAST_ALT_SUCCESS": "Réussite",
  "TOAST_ALT_WARN": "Avertissement",
  "TOAST_DISMISS_BUTTON": "Ignorer",
  "TOAST_TITLE_ALT": "Notification",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Historique des conversations instantanées, appuyez sur les touches de direction pour naviguer.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "historique des conversations instantanées",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Le message est interactif. Appuyez sur la touche Maj + Tab 2 à 3 fois pour passer à l’historique des conversations instantanées. Ensuite, cliquez sur le message pour interagir.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Un ou plusieurs liens dans le message. Appuyez sur la touche Maj + Tab 2 à 3 fois pour passer à l’historique des conversations instantanées. Ensuite, cliquez sur le message pour interagir.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Le message a des actions suggérées. Appuyez sur Maj + Tab 2 à 3 fois pour les sélectionner.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Le message a des actions suggérées. Appuyez sur $1 pour les sélectionner.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Impossible d’envoyer le message.",
  "TRANSCRIPT_MORE_MESSAGES": "Plus de messages",
  "TRANSCRIPT_NEW_MESSAGES": "Nouveaux messages",
  "TRANSCRIPT_TERMINATOR_TEXT": "Fin de l’historique des conversations instantanées",
  "TYPING_INDICATOR_ALT": "Affichage de l'indicateur de saisie",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 effectue une saisie.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 et d’autres effectuent une saisie.",
  "VOTE_DISLIKE_ALT": "Je n’aime pas",
  "VOTE_LIKE_ALT": "J’aime"
}