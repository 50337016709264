{
  "_comment": "[CI-LOCKED] Il contenuto del file è bloccato. I contributi sono ben accetti, ma la convalida delle modifiche potrebbe essere posticipata.",
  "ACCESS_KEY_ALT": "ALT MAIUSC $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Controllo Opzione $1",
  "ACTIVITY_BOT_SAID_ALT": "Il bot $1 ha detto:",
  "ACTIVITY_YOU_SAID_ALT": "Hai detto:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Il bot ha allegato:",
  "ACTIVITY_ERROR_BOX_TITLE": "Messaggio di errore",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Fare clic per interagire.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Messaggio inattivo.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Uno o più collegamenti nel messaggio.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Invio non riuscito.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Hai allegato:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 allegato.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 allegati.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 allegati.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 allegati.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 allegati.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Invio non riuscito. [tentativo][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Invio in corso",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Inviato alle $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Adesso",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Un'ora fa",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Un minuto fa",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Oggi",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Ieri",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Errore di analisi scheda adattiva",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Errore di rendering scheda adattiva",
  "ATTACHMENT_AUDIO": "Clip audio.",
  "ATTACHMENT_CARD": "Scheda: $1 $2 $3",
  "ATTACHMENT_FILE": "File: $1",
  "ATTACHMENT_IMAGE": "Immagine",
  "ATTACHMENT_TEXT": "Testo: $1",
  "ATTACHMENT_VIDEO": "Clip video",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Avanti",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Indietro",
  "CITATION_MODEL_DIALOG_ALT": "Citazione",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Connesso",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Connessione in corso…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Impossibile connettersi.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Interruzione di rete. Riconnessione in corso…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Errore di rendering. Controlla la console o contatta lo sviluppatore del bot.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "La connessione sta richiedendo più tempo del solito.",
  "CONNECTIVITY_STATUS_ALT": "Stato connettività: $1",
  "FILE_CONTENT_ALT": "\"$1\"",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Scarica file \"$1\"",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Scarica il file \"$1\" con dimensioni pari a $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "\"$1\" con dimensioni pari a $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Tasto INVIO",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Accedi agli elementi nel messaggio",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Cronologia chat",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Tasto ESC",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Messaggio di uscita",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tasto TAB\nTasto MAIUSC + TAB",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Spostati tra gli elementi di un messaggio",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Tasto freccia SU\nTasto freccia GIÙ",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Spostati tra i messaggi",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Tasto INVIO",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Esegui azione",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tasto TAB\nTasto MAIUSC + TAB",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Spostati tra elementi nella finestra di chat",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Finestra di chat",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Chiudi",
  "KEYBOARD_HELP_HEADER": "Comandi della tastiera",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Apre in una nuova finestra; esterna.",
  "RECEIPT_CARD_TAX": "Imposte",
  "RECEIPT_CARD_TOTAL": "Totali",
  "RECEIPT_CARD_VAT": "IVA",
  "REFERENCE_LIST_HEADER_ONE": "Riferimento $1",
  "REFERENCE_LIST_HEADER_FEW": "Riferimenti $1",
  "REFERENCE_LIST_HEADER_MANY": "Riferimenti $1",
  "REFERENCE_LIST_HEADER_OTHER": "Riferimenti $1",
  "REFERENCE_LIST_HEADER_TWO": "Riferimenti $1",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Impossibile inviare un messaggio vuoto.",
  "SPEECH_INPUT_LISTENING": "In ascolto…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Microfono disattivato",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Microfono attivo",
  "SPEECH_INPUT_STARTING": "Avvio in corso…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Avanti",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Indietro",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Azioni suggerite",
  "TEXT_INPUT_ALT": "Casella di input messaggio",
  "TEXT_INPUT_PLACEHOLDER": "Digita il tuo messaggio",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Invia",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Parla",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Carica file",
  "TOAST_ACCORDION_FEW": "$1 notifiche: fai clic qui per visualizzare i dettagli",
  "TOAST_ACCORDION_MANY": "$1 notifiche: fai clic qui per visualizzare i dettagli",
  "TOAST_ACCORDION_OTHER": "$1 notifiche: fai clic qui per visualizzare i dettagli",
  "TOAST_ACCORDION_TWO": "$1 notifiche: fai clic qui per visualizzare i dettagli",
  "TOAST_ALT_ERROR": "Errore",
  "TOAST_ALT_INFO": "Informazioni",
  "TOAST_ALT_SUCCESS": "Riuscito",
  "TOAST_ALT_WARN": "Avviso",
  "TOAST_DISMISS_BUTTON": "Ignora",
  "TOAST_TITLE_ALT": "Notifica",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Cronologia chat, premi i tasti freccia per spostarti.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "cronologia chat",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Il messaggio è interattivo. Premi MAIUSC +TAB da 2 a 3 volte per passare alla cronologia della chat. Fai clic sul messaggio per interagire.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Uno o più collegamenti nel messaggio. Premi MAIUSC +TAB da 2 a 3 volte per passare alla cronologia della chat. Fai clic sul messaggio per interagire.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Azioni suggerite per il messaggio. Premi TAB MAIUSC da 2 a 3 volte per selezionarle.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Azioni suggerite per il messaggio. Premi $1 per selezionarle.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Impossibile inviare il messaggio.",
  "TRANSCRIPT_MORE_MESSAGES": "Altri messaggi",
  "TRANSCRIPT_NEW_MESSAGES": "Nuovi messaggi",
  "TRANSCRIPT_TERMINATOR_TEXT": "Fine della cronologia chat",
  "TYPING_INDICATOR_ALT": "Visualizzazione dell'indicatore di digitazione in corso",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 sta digitando.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 e altri stanno digitando.",
  "VOTE_DISLIKE_ALT": "Non mi piace",
  "VOTE_LIKE_ALT": "Mi piace"
}