{
  "_comment": "[CI-LOCKED] このファイルのコンテンツはロックされています。投稿は歓迎しますが、変更の検証は遅れる可能性があります。",
  "ACCESS_KEY_ALT": "Alt + Shift + $1",
  "ACCESS_KEY_FOR_MAC_ALT": "control + option + $1",
  "ACTIVITY_BOT_SAID_ALT": "ボット $1 の発言:",
  "ACTIVITY_YOU_SAID_ALT": "あなたの発言:",
  "ACTIVITY_BOT_ATTACHED_ALT": "ボットの添付:",
  "ACTIVITY_ERROR_BOX_TITLE": "エラー メッセージ",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "クリックして対話します。",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "メッセージはインタラクティブです。",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "メッセージ内に 1 つ以上のリンクがあります。",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "送信に失敗しました。",
  "ACTIVITY_YOU_ATTACHED_ALT": "あなたの添付:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 個の添付ファイル。",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 添付ファイル。",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 添付ファイル。",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 添付ファイル。",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 添付ファイル。",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "送信に失敗しました。[再試行][RETRY]。",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "送信中",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "送信時刻: $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "たった今",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "1 時間前",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "1 分前",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "今日",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "昨日",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "アダプティブ カードの解析エラー",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "アダプティブ カードの表示エラー",
  "ATTACHMENT_AUDIO": "オーディオクリップです。",
  "ATTACHMENT_CARD": "カード: $1 $2 $3",
  "ATTACHMENT_FILE": "ファイル: $1",
  "ATTACHMENT_IMAGE": "画像",
  "ATTACHMENT_TEXT": "テキスト: $1",
  "ATTACHMENT_VIDEO": "ビデオ クリップ",
  "CAROUSEL_FLIPPER_NEXT_ALT": "次へ",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "前へ",
  "CITATION_MODEL_DIALOG_ALT": "引用",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "接続済み",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "接続中…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "接続できません。",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "ネットワークの中断が発生しました。再接続しています...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "表示エラー。コンソールを確認するかボット開発者にお問合せください。",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "通常より接続に時間がかかっています。",
  "CONNECTIVITY_STATUS_ALT": "接続状態: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "ファイル '$1' のダウンロード",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "ファイル '$1' (サイズ: $2) をダウンロードする",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' (サイズ: $2)",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter キー",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "メッセージ内のアイテムにアクセスする",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "チャット履歴",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Esc キー",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "終了メッセージ",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tab キー\nShift + Tab キー",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "メッセージ内のアイテム間を移動する",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "上方向キー\n下方向キー",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "メッセージ間を移動する",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter キー",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "アクション実行",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tab キー\nShift + Tab キー",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "チャット ウィンドウ内のアイテム間を移動する",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "チャット ウィンドウ",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "閉じる",
  "KEYBOARD_HELP_HEADER": "キーボード コントロール",
  "MARKDOWN_EXTERNAL_LINK_ALT": "新しいウィンドウ (外部) でレコードを開きます。",
  "RECEIPT_CARD_TAX": "税額",
  "RECEIPT_CARD_TOTAL": "合計",
  "RECEIPT_CARD_VAT": "VAT",
  "REFERENCE_LIST_HEADER_ONE": "$1 件の参照",
  "REFERENCE_LIST_HEADER_FEW": "$1 件の参照",
  "REFERENCE_LIST_HEADER_MANY": "$1 件の参照",
  "REFERENCE_LIST_HEADER_OTHER": "$1 件の参照",
  "REFERENCE_LIST_HEADER_TWO": "$1 件の参照",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "空のメッセージは送信できません。",
  "SPEECH_INPUT_LISTENING": "聞いています…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "マイクが無効",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "マイクが有効",
  "SPEECH_INPUT_STARTING": "開始しています…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "次へ",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "前へ",
  "SUGGESTED_ACTIONS_LABEL_ALT": "推奨されるアクション",
  "TEXT_INPUT_ALT": "メッセージ入力ボックス",
  "TEXT_INPUT_PLACEHOLDER": "メッセージを入力してください",
  "TEXT_INPUT_SEND_BUTTON_ALT": "送信",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "話す",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "ファイルのアップロード",
  "TOAST_ACCORDION_FEW": "$1 件の通知: ここをクリックして詳細を確認する",
  "TOAST_ACCORDION_MANY": "$1 件の通知: ここをクリックして詳細を確認する",
  "TOAST_ACCORDION_OTHER": "$1 件の通知: ここをクリックして詳細を確認する",
  "TOAST_ACCORDION_TWO": "$1 件の通知: ここをクリックして詳細を確認する",
  "TOAST_ALT_ERROR": "エラー",
  "TOAST_ALT_INFO": "情報",
  "TOAST_ALT_SUCCESS": "成功",
  "TOAST_ALT_WARN": "警告",
  "TOAST_DISMISS_BUTTON": "解除",
  "TOAST_TITLE_ALT": "通知",
  "TRANSCRIPT_ARIA_LABEL_ALT": "チャット履歴。方向キーを押して移動します。",
  "TRANSCRIPT_ARIA_ROLE_ALT": "チャット履歴",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "メッセージは対話型です。Shift + Tab キーを 2 ～ 3 回押してチャット履歴に切り替えます。その後、メッセージをクリックして対話します。",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "メッセージ内に 1 つ以上のリンクがあります。Shift + Tab キーを 2 ～ 3 回押してチャット履歴に切り替えます。その後、メッセージをクリックして対話します。",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "メッセージに推奨されるアクションがあります。Shift + Tab を 2 ～ 3 回押して選択します。",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "メッセージに推奨されるアクションがあります。$1 を押して選択します。",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "メッセージを送信できませんでした。",
  "TRANSCRIPT_MORE_MESSAGES": "その他のメッセージ",
  "TRANSCRIPT_NEW_MESSAGES": "新しいメッセージ",
  "TRANSCRIPT_TERMINATOR_TEXT": "チャット履歴の終了",
  "TYPING_INDICATOR_ALT": "入力インジケーターを表示しています",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 が入力しています。",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 と他のユーザーが入力しています。",
  "VOTE_DISLIKE_ALT": "低評価",
  "VOTE_LIKE_ALT": "高評価"
}