import classNames from 'classnames';
import React, { Fragment } from 'react';

type Props = Readonly<{
  className?: string;
  fillColor?: string;
  hasLock?: boolean;
}>;

const ShieldIcon = ({ className, fillColor, hasLock }: Props) => (
  <svg className={classNames(className)} height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    {hasLock ? (
      <Fragment>
        {fillColor && (
          <path
            d="M8.35464 2.14678C8.15946 1.95123 7.84268 1.95105 7.64728 2.14638C6.40416 3.38902 5.02923 4 3.5 4C3.22386 4 3 4.22386 3 4.5V7.50126C3 10.4242 4.35378 12.4971 7 13.6523V10C7 9.06808 7.63739 8.28503 8.5 8.06301V8C8.5 6.34315 9.84315 5 11.5 5C12.0464 5 12.5587 5.14609 13 5.40135V4.5C13 4.22386 12.7761 4 12.5 4C10.9689 4 9.5947 3.38913 8.35464 2.14678ZM9.5 9V8C9.5 6.89543 10.3954 6 11.5 6C12.6046 6 13.5 6.89543 13.5 8V9H14C14.5523 9 15 9.44772 15 10V14C15 14.5523 14.5523 15 14 15H9C8.44771 15 8 14.5523 8 14V10C8 9.44772 8.44772 9 9 9H9.5ZM10.5 8V9H12.5V8C12.5 7.44772 12.0523 7 11.5 7C10.9477 7 10.5 7.44772 10.5 8ZM12.25 12C12.25 11.5858 11.9142 11.25 11.5 11.25C11.0858 11.25 10.75 11.5858 10.75 12C10.75 12.4142 11.0858 12.75 11.5 12.75C11.9142 12.75 12.25 12.4142 12.25 12Z"
            fill={fillColor}
          />
        )}
        <path
          d="M7.64728 2.14638C7.84268 1.95105 8.15946 1.95123 8.35464 2.14678C9.5947 3.38913 10.9689 4 12.5 4C12.7761 4 13 4.22386 13 4.5V5.40135C12.6963 5.22567 12.359 5.1017 12 5.04148V4.98121C10.5195 4.86895 9.18521 4.25528 8.00042 3.18917C6.81393 4.25491 5.47941 4.86879 4 4.98118V7.50126C4 8.9349 4.36269 10.0655 5.02127 10.9453C5.49089 11.5727 6.14168 12.1134 7 12.5478V13.6523C4.35378 12.4971 3 10.4242 3 7.50126V4.5C3 4.22386 3.22386 4 3.5 4C5.02923 4 6.40416 3.38902 7.64728 2.14638ZM9.5 8V9H9C8.44772 9 8 9.44772 8 10V14C8 14.5523 8.44771 15 9 15H14C14.5523 15 15 14.5523 15 14V10C15 9.44772 14.5523 9 14 9H13.5V8C13.5 6.89543 12.6046 6 11.5 6C10.3954 6 9.5 6.89543 9.5 8ZM10.5 9V8C10.5 7.44772 10.9477 7 11.5 7C12.0523 7 12.5 7.44772 12.5 8V9H10.5ZM11.5 11.25C11.9142 11.25 12.25 11.5858 12.25 12C12.25 12.4142 11.9142 12.75 11.5 12.75C11.0858 12.75 10.75 12.4142 10.75 12C10.75 11.5858 11.0858 11.25 11.5 11.25Z"
          fill="currentcolor"
        />
      </Fragment>
    ) : (
      <Fragment>
        {fillColor && (
          <path
            d="M7.64728 2.14638C7.84268 1.95105 8.15946 1.95123 8.35464 2.14678C9.5947 3.38913 10.9689 4 12.5 4C12.7761 4 13 4.22386 13 4.5V7.50126C13 10.7196 11.3587 12.9075 8.15811 13.9743C8.05548 14.0086 7.94452 14.0086 7.84189 13.9743C4.64126 12.9075 3 10.7196 3 7.50126V4.5C3 4.22386 3.22386 4 3.5 4C5.02923 4 6.40416 3.38902 7.64728 2.14638Z"
            fill={fillColor}
          />
        )}
        <path
          d="M7.64728 2.14638C7.84268 1.95105 8.15946 1.95123 8.35464 2.14678C9.5947 3.38913 10.9689 4 12.5 4C12.7761 4 13 4.22386 13 4.5V7.50126C13 10.7196 11.3587 12.9075 8.15811 13.9743C8.05548 14.0086 7.94452 14.0086 7.84189 13.9743C4.64126 12.9075 3 10.7196 3 7.50126V4.5C3 4.22386 3.22386 4 3.5 4C5.02923 4 6.40416 3.38902 7.64728 2.14638ZM8.00042 3.18917C6.81393 4.25491 5.47941 4.86879 4 4.98118V7.50126C4 8.9349 4.36269 10.0655 5.02127 10.9453C5.65858 11.7967 6.62953 12.4885 8 12.9715C9.37047 12.4885 10.3414 11.7967 10.9787 10.9453C11.6373 10.0655 12 8.9349 12 7.50126V4.98121C10.5195 4.86895 9.18521 4.25528 8.00042 3.18917Z"
          fill="currentcolor"
        />
      </Fragment>
    )}
  </svg>
);

export default ShieldIcon;
