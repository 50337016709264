{
  "_comment": "[CI-LOCKED] Съдържанието на този файл е заключено. Приносът е добре дошъл, но валидирането на промените може да се забави.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Бот $1 каза:",
  "ACTIVITY_YOU_SAID_ALT": "Вие казахте:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Прикачен бот:",
  "ACTIVITY_ERROR_BOX_TITLE": "Съобщение за грешка",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Щракнете за взаимодействие.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Съобщението е интерактивно.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Една или повече връзки в съобщението.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Изпращането е неуспешно.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Вие прикачихте:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 прикачен файл.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 прикачени файлове.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 прикачени файлове.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 прикачени файлове.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 прикачени файлове.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Изпращането е неуспешно. [Повторен опит][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Изпращане",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Изпратено в $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Преди малко",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Преди час",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Преди минута",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Днес",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Вчера",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Грешка при анализиране на адаптивна карта",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Грешка при рендиране на адаптивна карта",
  "ATTACHMENT_AUDIO": "Аудиоклип.",
  "ATTACHMENT_CARD": "Карта: $1 $2 $3",
  "ATTACHMENT_FILE": "Файл: $1",
  "ATTACHMENT_IMAGE": "Изображение",
  "ATTACHMENT_TEXT": "Текст: $1",
  "ATTACHMENT_VIDEO": "Видеоклип",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Следващо",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Предишно",
  "CITATION_MODEL_DIALOG_ALT": "Цитат",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Установена връзка",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Свързване...",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Свързването не е възможно.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Възникна прекъсване на мрежата. Повторно свързване...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Грешка при рендиране. Моля, проверете конзолата или се свържете с разработчика на бота.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Свързването отнема повече време от обикновено.",
  "CONNECTIVITY_STATUS_ALT": "Състояние на възможността за връзка: $1",
  "FILE_CONTENT_ALT": "„$1”",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Изтегляне на файл „$1”",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Изтегляне на файл „$1” с размер $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "„$1” с размер $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Клавиш Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Достъп до елементи в съобщението",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Хронология на чата",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Клавиш Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Съобщение за излизане",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Клавиш Tab\nShift + клавиш Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Придвижване между елементи в съобщение",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Клавиш за стрелка нагоре\nКлавиш за стрелка надолу",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Придвижване между съобщения",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Клавиш Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Извършване на действие",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Клавиш Tab\nShift + клавиш Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Придвижване между елементите в прозореца за чат",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Прозорец за чат",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Затваряне",
  "KEYBOARD_HELP_HEADER": "Контроли на клавиатурата",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Отваря се в нов прозорец; външен.",
  "RECEIPT_CARD_TAX": "Данък",
  "RECEIPT_CARD_TOTAL": "Обща сума",
  "RECEIPT_CARD_VAT": "ДДС",
  "REFERENCE_LIST_HEADER_ONE": "$1 препратка",
  "REFERENCE_LIST_HEADER_FEW": "$1 препратки",
  "REFERENCE_LIST_HEADER_MANY": "$1 препратки",
  "REFERENCE_LIST_HEADER_OTHER": "$1 препратки",
  "REFERENCE_LIST_HEADER_TWO": "$1 препратки",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Не може да се изпрати празно съобщение.",
  "SPEECH_INPUT_LISTENING": "Слушане...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Микрофонът е изключен",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Микрофонът е включен",
  "SPEECH_INPUT_STARTING": "Начало...",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Следващо",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Предишно",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Предложени действия",
  "TEXT_INPUT_ALT": "Поле за въвеждане на съобщение",
  "TEXT_INPUT_PLACEHOLDER": "Въведете съобщението си",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Изпрати",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Говорете",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Качване на файл",
  "TOAST_ACCORDION_FEW": "$1 Известия: Щракнете тук, за да видите подробности",
  "TOAST_ACCORDION_MANY": "$1 Известия: Щракнете тук, за да видите подробности",
  "TOAST_ACCORDION_OTHER": "$1 Известия: Щракнете тук, за да видите подробности",
  "TOAST_ACCORDION_TWO": "$1 Известия: Щракнете тук, за да видите подробности",
  "TOAST_ALT_ERROR": "Грешка",
  "TOAST_ALT_INFO": "Информация",
  "TOAST_ALT_SUCCESS": "Успех",
  "TOAST_ALT_WARN": "Предупреждение",
  "TOAST_DISMISS_BUTTON": "Отхвърляне",
  "TOAST_TITLE_ALT": "Известяване",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Хронология на чата, натиснете клавишите със стрелки, за да навигирате.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "хронология на чата",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Съобщението е интерактивно. Натиснете клавишите Shift и Tab 2 до 3 пъти, за да превключите към хронологията на чата. След това щракнете върху съобщението, за да взаимодействате.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Има една или повече връзки в съобщението. Натиснете клавишите Shift и Tab 2 до 3 пъти, за да превключите към хронологията на чата. След това щракнете върху съобщението, за да взаимодействате.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "В съобщението са предложени действия. Натиснете Shift Tab 2 до 3 пъти, за да ги изберете.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "В съобщението са предложени действия. Натиснете $1, за да ги изберете.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Неуспешно изпращане на съобщението.",
  "TRANSCRIPT_MORE_MESSAGES": "Още съобщения",
  "TRANSCRIPT_NEW_MESSAGES": "Нови съобщения",
  "TRANSCRIPT_TERMINATOR_TEXT": "Край на хронологията на чата",
  "TYPING_INDICATOR_ALT": "Показване на индикатор за въвеждане",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 пише.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 и други пишат.",
  "VOTE_DISLIKE_ALT": "Не харесвам",
  "VOTE_LIKE_ALT": "Харесвам"
}