{
  "_comment": "[CI-LOCKED] O contido deste ficheiro está bloqueado. As contribucións son benvidas, pero a validación da modificación pode demorarse.",
  "ACCESS_KEY_ALT": "ALT MAIÚS $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Opción $1",
  "ACTIVITY_BOT_SAID_ALT": "O bot $1 dixo:",
  "ACTIVITY_YOU_SAID_ALT": "Dixo:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Anexado ao bot:",
  "ACTIVITY_ERROR_BOX_TITLE": "Mensaxe de erro",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Prema para interactuar.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "A mensaxe é interactiva.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Unha ou varias ligazóns na mensaxe.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Erro ao enviar.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Anexou:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "Un anexo.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 anexos.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 anexos.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 anexos.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 anexos.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Erro no envío. [Tentar de novo][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Enviando",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Enviada ás $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Agora mesmo",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Hai unha hora",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Hai un minuto",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Hoxe",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Onte",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Erro de análise do cartón adaptable",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Erro de procesamento do cartón adaptable",
  "ATTACHMENT_AUDIO": "Un clip de audio.",
  "ATTACHMENT_CARD": "Un cartón: $1 $2 $3",
  "ATTACHMENT_FILE": "Un ficheiro: $1",
  "ATTACHMENT_IMAGE": "Unha imaxe",
  "ATTACHMENT_TEXT": "Un texto: $1",
  "ATTACHMENT_VIDEO": "Un clip de vídeo",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Seguinte",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Anterior",
  "CITATION_MODEL_DIALOG_ALT": "Cita",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Conectado",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Conectando…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Non é posible conectarse.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Produciuse unha interrupción da rede. Conectando de novo…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Erro de procesamento. Comprobe a consola ou póñase en contacto co programador do bot.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "A conexión está a tardar máis do normal.",
  "CONNECTIVITY_STATUS_ALT": "Estado da conectividade: $1",
  "FILE_CONTENT_ALT": "\"$1\"",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Descargar o ficheiro \"$1\"",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Descargar o ficheiro \"$1\" cun tamaño de $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "\"$1\" cun tamaño de $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Tecla INTRO",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Acceder aos elementos da mensaxe",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Historial de chat",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Tecla Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Saír da mensaxe",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tecla TAB\nTeclas MAIÚS + TAB",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Moverse entre os elementos dunha mensaxe",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Tecla Frecha cara a arriba\nTecla Frecha cara a abaixo",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Moverse entre as mensaxes",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Tecla INTRO",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Realizar acción",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tecla TAB\nTeclas MAIÚS + TAB",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Moverse entre os elementos da ventá de chat",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Ventá de chat",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Pechar",
  "KEYBOARD_HELP_HEADER": "Controis de teclado",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Ábrese nunha ventá nova; externa.",
  "RECEIPT_CARD_TAX": "Imposto",
  "RECEIPT_CARD_TOTAL": "Total",
  "RECEIPT_CARD_VAT": "IVE",
  "REFERENCE_LIST_HEADER_ONE": "Referencia de $1",
  "REFERENCE_LIST_HEADER_FEW": "Referencias de $1",
  "REFERENCE_LIST_HEADER_MANY": "Referencias de $1",
  "REFERENCE_LIST_HEADER_OTHER": "Referencias de $1",
  "REFERENCE_LIST_HEADER_TWO": "Referencias de $1",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Non se pode enviar unha mensaxe baleira.",
  "SPEECH_INPUT_LISTENING": "Escoitando…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Apagar Micrófono",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Acender micrófono",
  "SPEECH_INPUT_STARTING": "Iniciando…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Seguinte",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Anterior",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Accións suxeridas",
  "TEXT_INPUT_ALT": "Caixa de entrada de mensaxes",
  "TEXT_INPUT_PLACEHOLDER": "Escriba a mensaxe",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Enviar",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Falar",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Cargar ficheiro",
  "TOAST_ACCORDION_FEW": "$1 notificacións: prema aquí para ver os detalles",
  "TOAST_ACCORDION_MANY": "$1 notificacións: prema aquí para ver os detalles",
  "TOAST_ACCORDION_OTHER": "$1 notificacións: prema aquí para ver os detalles",
  "TOAST_ACCORDION_TWO": "$1 notificacións: prema aquí para ver os detalles",
  "TOAST_ALT_ERROR": "Erro",
  "TOAST_ALT_INFO": "Información",
  "TOAST_ALT_SUCCESS": "Correcto",
  "TOAST_ALT_WARN": "Aviso",
  "TOAST_DISMISS_BUTTON": "Rexeitar",
  "TOAST_TITLE_ALT": "Notificación",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Historial de chat, prema as teclas de frecha para navegar.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "historial de chat",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "A mensaxe é interactiva. Prema as teclas MAIÚS e TAB dúas ou tres veces para cambiar ao historial de chat. A continuación, prema na mensaxe para interactuar.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Unha ou varias ligazóns da mensaxe. Prema as teclas MAIÚS e TAB dúas ou tres veces para cambiar ao historial de chat. A continuación, prema na mensaxe para interactuar.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "A mensaxe ten accións suxeridas. Prema MAIÚS e TAB dúas ou tres veces para seleccionalas.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "A mensaxe ten accións suxeridas. Prema $1 para seleccionalas.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Non foi posible enviar a mensaxe.",
  "TRANSCRIPT_MORE_MESSAGES": "Máis mensaxes",
  "TRANSCRIPT_NEW_MESSAGES": "Mensaxes novas",
  "TRANSCRIPT_TERMINATOR_TEXT": "Fin do historial de chat",
  "TYPING_INDICATOR_ALT": "Mostrando o indicador de escritura",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 está a escribir.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 e outras persoas están a escribir.",
  "VOTE_DISLIKE_ALT": "Non me gusta",
  "VOTE_LIKE_ALT": "Gústame"
}