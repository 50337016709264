{
  "_comment": "[CI-LOCKED] Содержимое этого файла заблокировано. Вы можете вносить изменения, но их проверка может быть задержана.",
  "ACCESS_KEY_ALT": "ALT SHIFT $1",
  "ACCESS_KEY_FOR_MAC_ALT": "CONTROL OPTION $1",
  "ACTIVITY_BOT_SAID_ALT": "Бот $1 сказал:",
  "ACTIVITY_YOU_SAID_ALT": "Вы сказали:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Прикреплено ботом:",
  "ACTIVITY_ERROR_BOX_TITLE": "Сообщение об ошибке",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Щелкните, чтобы взаимодействовать с сообщением.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Сообщение является интерактивным.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "В сообщении есть одна или несколько ссылок.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Сбой отправки.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Прикреплено вами:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 вложение.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 вложений.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 вложений.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 вложения.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 вложения.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Сбой отправки. [Повторить][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Отправка",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Отправлено в $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Только что",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Час назад",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Минуту назад",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Сегодня",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Вчера",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Ошибка анализа адаптивной карточки",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Ошибка преобразования для просмотра адаптивной карточки",
  "ATTACHMENT_AUDIO": "Аудиоклип.",
  "ATTACHMENT_CARD": "Карточка: $1 $2 $3",
  "ATTACHMENT_FILE": "Файл: $1",
  "ATTACHMENT_IMAGE": "Изображение",
  "ATTACHMENT_TEXT": "Текст: $1",
  "ATTACHMENT_VIDEO": "Видеоклип",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Далее",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Назад",
  "CITATION_MODEL_DIALOG_ALT": "Цитата",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Подключено",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Подключение…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Не удается подключиться.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Произошло прерывание соединения с сетью. Выполняется восстановление связи…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Ошибка преобразования для просмотра. Проверьте консоль или обратитесь к разработчику бота.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Для подключения требуется больше времени, чем обычно.",
  "CONNECTIVITY_STATUS_ALT": "Состояние подключения: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Загрузить файл '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Загрузить файл '$1' размера $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' размера $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Клавиша ВВОД",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Доступ к элементами в сообщении",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "История чата",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Клавиша ESC",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Сообщение при выходе",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Клавиша TAB\nКлавиши SHIFT + TAB",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Перемещаться между элементами в сообщении",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Стрелка вверх\nСтрелка вниз",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Перемещаться между сообщениями",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Клавиша ВВОД",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Выполнить действие",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Клавиша TAB\nКлавиши SHIFT + TAB",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Перемещаться между элементами в окне чата",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Окно чата",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Закрыть",
  "KEYBOARD_HELP_HEADER": "Элементы управления клавиатурой",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Открывает в новом окне; внешняя.",
  "RECEIPT_CARD_TAX": "Налог",
  "RECEIPT_CARD_TOTAL": "Итого",
  "RECEIPT_CARD_VAT": "НДС",
  "REFERENCE_LIST_HEADER_ONE": "$1 ссылка",
  "REFERENCE_LIST_HEADER_FEW": "$1 ссылок",
  "REFERENCE_LIST_HEADER_MANY": "$1 ссылок",
  "REFERENCE_LIST_HEADER_OTHER": "$1 ссылки",
  "REFERENCE_LIST_HEADER_TWO": "$1 ссылки",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Невозможно отправить пустое сообщение.",
  "SPEECH_INPUT_LISTENING": "Слушаю…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Микрофон выкл.",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Микрофон вкл.",
  "SPEECH_INPUT_STARTING": "Начало…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Далее",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Назад",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Предлагаемые действия",
  "TEXT_INPUT_ALT": "Поле ввода сообщения",
  "TEXT_INPUT_PLACEHOLDER": "Введите сообщение",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Отправить",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Говорить",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Отправить файл",
  "TOAST_ACCORDION_FEW": "Уведомлений: $1. Щелкните здесь для просмотра сведений",
  "TOAST_ACCORDION_MANY": "Уведомлений: $1. Щелкните здесь для просмотра сведений",
  "TOAST_ACCORDION_OTHER": "Уведомлений: $1. Щелкните здесь для просмотра сведений",
  "TOAST_ACCORDION_TWO": "Уведомлений: $1. Щелкните здесь для просмотра сведений",
  "TOAST_ALT_ERROR": "Ошибка",
  "TOAST_ALT_INFO": "Сведения",
  "TOAST_ALT_SUCCESS": "Успешно завершено",
  "TOAST_ALT_WARN": "Предупреждение",
  "TOAST_DISMISS_BUTTON": "Закрыть",
  "TOAST_TITLE_ALT": "Уведомление",
  "TRANSCRIPT_ARIA_LABEL_ALT": "История чата, используйте клавиши со стрелками для перемещения.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "история чата",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Сообщение интерактивно. Для перехода к истории чата нажмите клавиши SHIFT + TAB 2-3 раза. Затем выберите сообщение для взаимодействия.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "В сообщении есть одна или несколько ссылок. Для перехода к истории чата нажмите клавиши SHIFT + TAB 2-3 раза. Затем выберите сообщение для взаимодействия.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Для сообщения есть предлагаемые действия. Нажмите SHIFT TAB 2 или 3 раза, чтобы выбрать нужное.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Для сообщения есть предлагаемые действия. Нажмите $1, чтобы выбрать нужное.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Не удалось отправить сообщение.",
  "TRANSCRIPT_MORE_MESSAGES": "Другие сообщения",
  "TRANSCRIPT_NEW_MESSAGES": "Новые сообщения",
  "TRANSCRIPT_TERMINATOR_TEXT": "Конец истории чата",
  "TYPING_INDICATOR_ALT": "Отображение индикатора ввода",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 вводит текст.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 и другие вводят текст.",
  "VOTE_DISLIKE_ALT": "Не нравится",
  "VOTE_LIKE_ALT": "Нравится"
}