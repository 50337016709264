{
  "_comment": "[CI-LOCKED] Konten file ini terkunci. Kontribusi diterima, namun validasi perubahan mungkin tertunda.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Bot $1 berkata:",
  "ACTIVITY_YOU_SAID_ALT": "Anda berkata:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot melampirkan:",
  "ACTIVITY_ERROR_BOX_TITLE": "Pesan kesalahan",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Klik untuk berinteraksi.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Pesan ini interaktif.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Ada satu atau beberapa tautan dalam pesan ini.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Gagal terkirim.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Anda melampirkan:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 lampiran.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 lampiran.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 lampiran.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 lampiran.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 lampiran.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Gagal mengirim. [Coba lagi][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Mengirim",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Dikirim pada $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Baru saja",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Satu jam yang lalu",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Satu menit yang lalu",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Hari Ini",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Kemarin",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Kesalahan penguraian Kartu Adaptif",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Kesalahan render Kartu Adaptif",
  "ATTACHMENT_AUDIO": "Klip audio.",
  "ATTACHMENT_CARD": "Kartu: $1 $2 $3",
  "ATTACHMENT_FILE": "File: $1",
  "ATTACHMENT_IMAGE": "Gambar",
  "ATTACHMENT_TEXT": "Teks: $1",
  "ATTACHMENT_VIDEO": "Klip video",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Berikutnya",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Sebelumnya",
  "CITATION_MODEL_DIALOG_ALT": "Kutipan",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Tersambung",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Menyambungkan…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Tidak dapat menyambungkan.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Terjadi gangguan jaringan. Menyambungkan kembali…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Kesalahan render. Periksa konsol atau hubungi pengembang bot.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Memakan waktu lebih lama dari biasanya untuk menyambungkan.",
  "CONNECTIVITY_STATUS_ALT": "Status Konektivitas: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Unduh file '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Unduh file '$1' dengan ukuran $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "'$1' dengan ukuran $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Tombol Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Akses item dalam pesan",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Riwayat obrolan",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Tombol Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Pesan keluar",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tombol Tab\nTombol Shift + Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Pindahkan di antara item dalam pesan",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Tombol panah atas\nTombol panah bawah",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Pindahkan antarpesan",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Tombol Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Lakukan tindakan",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tombol Tab\nTombol Shift + Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Pindahkan di antara item dalam jendela obrolan",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Jendela obrolan",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Tutup",
  "KEYBOARD_HELP_HEADER": "Kontrol keyboard",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Membuka di jendela baru; eksternal.",
  "RECEIPT_CARD_TAX": "Pajak",
  "RECEIPT_CARD_TOTAL": "Total",
  "RECEIPT_CARD_VAT": "PPN",
  "REFERENCE_LIST_HEADER_ONE": "$1 referensi",
  "REFERENCE_LIST_HEADER_FEW": "$1 referensi",
  "REFERENCE_LIST_HEADER_MANY": "$1 referensi",
  "REFERENCE_LIST_HEADER_OTHER": "$1 referensi",
  "REFERENCE_LIST_HEADER_TWO": "$1 referensi",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Tidak dapat mengirim pesan kosong.",
  "SPEECH_INPUT_LISTENING": "Mendengarkan…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Mikrofon tidak aktif",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Mikrofon aktif",
  "SPEECH_INPUT_STARTING": "Memulai…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Berikutnya",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Sebelumnya",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Tindakan yang disarankan",
  "TEXT_INPUT_ALT": "Kotak input pesan",
  "TEXT_INPUT_PLACEHOLDER": "Ketik pesan Anda",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Kirim",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Ucapkan",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Unggah file",
  "TOAST_ACCORDION_FEW": "$1 Pemberitahuan: Klik di sini untuk melihat detail",
  "TOAST_ACCORDION_MANY": "$1 Pemberitahuan: Klik di sini untuk melihat detail",
  "TOAST_ACCORDION_OTHER": "$1 Pemberitahuan: Klik di sini untuk melihat detail",
  "TOAST_ACCORDION_TWO": "$1 Pemberitahuan: Klik di sini untuk melihat detail",
  "TOAST_ALT_ERROR": "Kesalahan",
  "TOAST_ALT_INFO": "Informasi",
  "TOAST_ALT_SUCCESS": "Berhasil",
  "TOAST_ALT_WARN": "Peringatan",
  "TOAST_DISMISS_BUTTON": "Tutup",
  "TOAST_TITLE_ALT": "Pemberitahuan",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Riwayat obrolan, tekan tombol panah untuk menavigasi.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "riwayat obrolan",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Pesan interaktif. Tekan tombol tab shift 2 hingga 3 kali untuk beralih ke riwayat obrolan. Setelah itu, klik pesan untuk berinteraksi.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Satu atau beberapa klik dalam pesan. Tekan tombol tab shift 2 hingga 3 kali untuk beralih ke riwayat obrolan. Setelah itu, klik pesan untuk berinteraksi.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Pesan memiliki tindakan yang disarankan. Tekan tab shift sebanyak 2 hingga 3 kali untuk memilihnya.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Pesan memiliki tindakan yang disarankan. Tekan $1 untuk memilihnya.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Pesan gagal terkirim.",
  "TRANSCRIPT_MORE_MESSAGES": "Pesan lainnya",
  "TRANSCRIPT_NEW_MESSAGES": "Pesan baru",
  "TRANSCRIPT_TERMINATOR_TEXT": "Akhir riwayat obrolan",
  "TYPING_INDICATOR_ALT": "Menampilkan indikator pengetikan",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 sedang mengetik.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 dan lainnya sedang mengetik.",
  "VOTE_DISLIKE_ALT": "Tidak suka",
  "VOTE_LIKE_ALT": "Suka"
}