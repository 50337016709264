import React from 'react';

const MicrophoneIcon = ({ className }) => (
  <svg className={className} focusable={false} height={28} role="presentation" viewBox="0 0 34.75 46" width={28}>
    <path
      className="a"
      d="M29.75,23v6.36a7,7,0,0,1-.56,2.78,7.16,7.16,0,0,1-3.8,3.8,7,7,0,0,1-2.78.56H18.5v2.25H23V41H11.75v-2.25h4.5V36.5h-4.11a7,7,0,0,1-2.78-.56,7.16,7.16,0,0,1-3.8-3.8,7,7,0,0,1-.56-2.78V23h2.25v6.36a4.72,4.72,0,0,0,.39,1.9,4.78,4.78,0,0,0,2.6,2.6,4.72,4.72,0,0,0,1.9.39h10.47a4.72,4.72,0,0,0,1.9-.39,4.78,4.78,0,0,0,2.6-2.6,4.72,4.72,0,0,0,.39-1.9V23Zm-18,5.62a1.13,1.13,0,0,0,1.13,1.13h9a1.13,1.13,0,0,0,1.12-1.13V8.38a1.13,1.13,0,0,0-1.12-1.13h-9a1.13,1.13,0,0,0-1.13,1.13Zm1.13,3.38a3.41,3.41,0,0,1-1.32-.26,3.31,3.31,0,0,1-1.8-1.8,3.41,3.41,0,0,1-.26-1.32V8.38a3.41,3.41,0,0,1,.26-1.32,3.31,3.31,0,0,1,1.8-1.8,3.41,3.41,0,0,1,1.32-.26h9a3.4,3.4,0,0,1,1.31.26,3.31,3.31,0,0,1,1.8,1.8,3.41,3.41,0,0,1,.26,1.32v20.24a3.41,3.41,0,0,1-.26,1.32,3.31,3.31,0,0,1-1.8,1.8,3.4,3.4,0,0,1-1.31.26Z"
    />
  </svg>
);

export default MicrophoneIcon;
