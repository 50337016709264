{
  "_comment": "[CI-LOCKED] Садржај ове датотеке је закључан. Доприноси су добродошли, али потврда промене може бити одложена.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Робот $1 је рекао:",
  "ACTIVITY_YOU_SAID_ALT": "Ви сте рекли:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Робот је приложио:",
  "ACTIVITY_ERROR_BOX_TITLE": "Порука о грешци",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Кликните за интеракцију.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Поруке су неактивне.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Порука садржи везе.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Слање није успело.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Приложили сте:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 прилог.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 прилога.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 прилога.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 прилога.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 прилога.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Слање није успело. [Поново покушај] [RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Слање",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Послато у $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Малопре",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Пре сат времена",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Пре једног минута",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Данас",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Јуче",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Грешка у рашчлањивању прилагодљиве картице",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Грешка у приказивању прилагодљиве картице",
  "ATTACHMENT_AUDIO": "Аудио клип.",
  "ATTACHMENT_CARD": "Картица: $1 $2 $3",
  "ATTACHMENT_FILE": "Датотека: $1",
  "ATTACHMENT_IMAGE": "Слика",
  "ATTACHMENT_TEXT": "Текст: $1",
  "ATTACHMENT_VIDEO": "Видео клип",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Даље",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Претходно",
  "CITATION_MODEL_DIALOG_ALT": "Цитат",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Повезано",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Повезивање…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Повезивање није могуће.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Дошло је до прекида мреже. Поновно повезивање…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Грешка у приказивању. Проверите конзолу или се обратите програмеру робота.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Повезивање траје дуже него иначе.",
  "CONNECTIVITY_STATUS_ALT": "Статус повезивања: $1",
  "FILE_CONTENT_ALT": "„$1“",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Преузимање датотеке „$1“",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Преузмите датотеку „$1“ величине $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "„$1“ величине $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Тастер Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Приступ ставкама у поруци",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Историја ћаскања",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Тастер Escape",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Порука на излазу",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Тастер Tab\nShift + тастер Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Прелазак са једне ставке на другу у поруци",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Тастер са стрелицом нагоре\nТастер са стрелицом надоле",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Прелазак са једне поруке на другу",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Тастер Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Обављање радње",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Тастер Tab\nShift + тастер Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Прелазак са једне ставке на другу у прозору за ћаскање",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Прозор за ћаскање",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Затвори",
  "KEYBOARD_HELP_HEADER": "Тастерске контроле",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Отвара се у новом прозору; спољно.",
  "RECEIPT_CARD_TAX": "Порез",
  "RECEIPT_CARD_TOTAL": "Укупно",
  "RECEIPT_CARD_VAT": "ПДВ",
  "REFERENCE_LIST_HEADER_ONE": "$1 референца",
  "REFERENCE_LIST_HEADER_FEW": "$1 референци",
  "REFERENCE_LIST_HEADER_MANY": "$1 референци",
  "REFERENCE_LIST_HEADER_OTHER": "$1 референци",
  "REFERENCE_LIST_HEADER_TWO": "$1 референце",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Није могуће послати празну поруку.",
  "SPEECH_INPUT_LISTENING": "Слушамо...",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Микрофон је искључен",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Микрофон је укључен",
  "SPEECH_INPUT_STARTING": "Почиње…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Даље",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Претходно",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Предложене радње",
  "TEXT_INPUT_ALT": "Поље за унос поруке",
  "TEXT_INPUT_PLACEHOLDER": "Откуцајте поруку",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Пошаљи",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Изговорите",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Отпреми датотеку",
  "TOAST_ACCORDION_FEW": "$1 обавештења: Кликните овде да бисте видели детаље",
  "TOAST_ACCORDION_MANY": "$1 обавештења: Кликните овде да бисте видели детаље",
  "TOAST_ACCORDION_OTHER": "$1 обавештења: Кликните овде да бисте видели детаље",
  "TOAST_ACCORDION_TWO": "$1 обавештења: Кликните овде да бисте видели детаље",
  "TOAST_ALT_ERROR": "Грешка",
  "TOAST_ALT_INFO": "Информације",
  "TOAST_ALT_SUCCESS": "Успешно",
  "TOAST_ALT_WARN": "Упозорење",
  "TOAST_DISMISS_BUTTON": "Одбаци",
  "TOAST_TITLE_ALT": "Обавештење",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Историја ћаскања, користите тастере са стрелицом да бисте се кретали.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "историја ћаскања",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Порука је интерактивна. Притисните тастере Shift и Тab 2 до 3 пута да бисте се пребацили на историју ћаскања. Затим кликните на поруку да бисте остварили интеракцију.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Порука садржи везе. Притисните тастере Shift и Тab 2 до 3 пута да бисте се пребацили на историју ћаскања. Затим кликните на поруку да бисте остварили интеракцију.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Порука има предложене радње. Притисните тастере shift tab 2 до 3 пута како бисте их изабрали.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Порука има предложене радње. Притисните $1 како бисте их изабрали.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Слање поруке није успело.",
  "TRANSCRIPT_MORE_MESSAGES": "Још порука",
  "TRANSCRIPT_NEW_MESSAGES": "Нове поруке",
  "TRANSCRIPT_TERMINATOR_TEXT": "Крај историје ћаскања",
  "TYPING_INDICATOR_ALT": "Приказује се индикатор куцања",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 куца.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 и други куцају.",
  "VOTE_DISLIKE_ALT": "Не свиђа ми се",
  "VOTE_LIKE_ALT": "Свиђа ми се"
}