{
  "_comment": "[CI-LOCKED] Conținutul acestui fișier este blocat. Contribuțiile sunt binevenite, dar validarea schimbării poate fi întârziată.",
  "ACCESS_KEY_ALT": "Alt Shift $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Option $1",
  "ACTIVITY_BOT_SAID_ALT": "Robotul $1 a spus:",
  "ACTIVITY_YOU_SAID_ALT": "Ați spus:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Robot atașat:",
  "ACTIVITY_ERROR_BOX_TITLE": "Mesaj de eroare",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Faceți clic pentru a interacționa.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Mesajul este interactiv.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "În mesaj există unul sau mai multe linkuri.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Trimitere nereușită.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Ați atașat:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 atașare.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 atașări.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 atașări.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 de atașări.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 atașări.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Trimiterea nu a reușit. [Reîncercați][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Se trimite",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Trimis la $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Chiar acum",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Acum o oră",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Acum un minut",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Azi",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Ieri",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Eroare de analiză a cardului adaptiv",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Eroare de redare a cardului adaptiv",
  "ATTACHMENT_AUDIO": "Un clip audio.",
  "ATTACHMENT_CARD": "Un card: $1 $2 $3",
  "ATTACHMENT_FILE": "Un fișier: $1",
  "ATTACHMENT_IMAGE": "O imagine",
  "ATTACHMENT_TEXT": "Un text: $1",
  "ATTACHMENT_VIDEO": "Un videoclip",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Înainte",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Înapoi",
  "CITATION_MODEL_DIALOG_ALT": "Mențiune",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Conectat",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Se conectează…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Imposibil de conectat.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "A avut loc o întrerupere în rețea. Se reconectează…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Eroare de randare. Verificați consola sau contactați dezvoltatorul robotului.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Conectarea durează mai mult decât de obicei.",
  "CONNECTIVITY_STATUS_ALT": "Stare de conectivitate: $1",
  "FILE_CONTENT_ALT": "„$1”",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Descărcați fișierul „$1”",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Descărcați fișierul „$1” de dimensiune $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "„$1” de dimensiune $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Tasta Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Accesați elementele din mesaj",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Istoric chat",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Tasta Esc",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Ieșiți din mesaj",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tasta Tab\nTastele Shift + Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Deplasați-vă între elementele dintr-un mesaj",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Tasta Săgeată în sus\nTasta Săgeată în jos",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Deplasați-vă între mesaje",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Tasta Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Efectuați acțiunea",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tasta Tab\nTastele Shift + Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Deplasați-vă între elementele din fereastra de chat",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Fereastră de chat",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Închideți",
  "KEYBOARD_HELP_HEADER": "Comenzi de la tastatură",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Se deschide într-o fereastră nouă; extern.",
  "RECEIPT_CARD_TAX": "Impozit",
  "RECEIPT_CARD_TOTAL": "Total",
  "RECEIPT_CARD_VAT": "TVA",
  "REFERENCE_LIST_HEADER_ONE": "$1 referință",
  "REFERENCE_LIST_HEADER_FEW": "$1 referințe",
  "REFERENCE_LIST_HEADER_MANY": "$1 referințe",
  "REFERENCE_LIST_HEADER_OTHER": "$1 referințe",
  "REFERENCE_LIST_HEADER_TWO": "$1 referințe",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Nu se poate trimite un mesaj gol.",
  "SPEECH_INPUT_LISTENING": "Se ascultă…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Microfon dezactivat",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Microfon activat",
  "SPEECH_INPUT_STARTING": "Începe…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Înainte",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Înapoi",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Acțiuni sugerate",
  "TEXT_INPUT_ALT": "Caseta de introducere a mesajelor",
  "TEXT_INPUT_PLACEHOLDER": "Tastați mesajul",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Trimitere",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Vorbire",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Încărcare fișier",
  "TOAST_ACCORDION_FEW": "$1 notificări: Faceți clic aici pentru a vedea detaliile",
  "TOAST_ACCORDION_MANY": "$1 notificări: Faceți clic aici pentru a vedea detaliile",
  "TOAST_ACCORDION_OTHER": "$1 notificări: Faceți clic aici pentru a vedea detaliile",
  "TOAST_ACCORDION_TWO": "$1 notificări: Faceți clic aici pentru a vedea detaliile",
  "TOAST_ALT_ERROR": "Eroare",
  "TOAST_ALT_INFO": "Informații",
  "TOAST_ALT_SUCCESS": "Succes",
  "TOAST_ALT_WARN": "Avertisment",
  "TOAST_DISMISS_BUTTON": "Îndepărtare",
  "TOAST_TITLE_ALT": "Notificare",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Istoric chat, apăsați tastele săgeți pentru a naviga.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "istoric chat",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Mesajul este interactiv. Apăsați tastele Shift și Tab de 2 până la 3 ori, pentru a comuta la istoricul chatului. Apoi faceți clic pe mesaj pentru a interacționa.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "În mesaj există unul sau mai multe linkuri. Apăsați tastele Shift și Tab de 2 până la 3 ori, pentru a comuta la istoricul chatului. Apoi faceți clic pe mesaj pentru a interacționa.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Mesajul are acțiuni sugerate. Apăsați tastele Shift și Tab de 2 până la 3 ori pentru a le selecta.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Mesajul are acțiuni sugerate. Apăsați $1 pentru a le selecta.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Trimiterea mesajului nu a reușit.",
  "TRANSCRIPT_MORE_MESSAGES": "Mai multe mesaje",
  "TRANSCRIPT_NEW_MESSAGES": "Mesaje noi",
  "TRANSCRIPT_TERMINATOR_TEXT": "Sfârșit istoric chat",
  "TYPING_INDICATOR_ALT": "Se afișează indicatorul de tastare",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 tastează.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 și alții tastează.",
  "VOTE_DISLIKE_ALT": "Nu apreciez",
  "VOTE_LIKE_ALT": "Apreciez"
}