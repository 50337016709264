/* eslint-disable */
import Globalize from "globalize/dist/globalize-runtime";

import "globalize/dist/globalize-runtime/number";
import "globalize/dist/globalize-runtime/plural";
import "globalize/dist/globalize-runtime/date";
import "globalize/dist/globalize-runtime/relative-time";
import "globalize/dist/globalize-runtime/unit";

var numberRound = Globalize._numberRound;
var numberToPartsFormatterFn = Globalize._numberToPartsFormatterFn;
var numberFormatterFn = Globalize._numberFormatterFn;
var pluralGeneratorFn = Globalize._pluralGeneratorFn;
var dateToPartsFormatterFn = Globalize._dateToPartsFormatterFn;
var dateFormatterFn = Globalize._dateFormatterFn;
var relativeTimeFormatterFn = Globalize._relativeTimeFormatterFn;
var unitFormatterFn = Globalize._unitFormatterFn;

Globalize.a1941952454 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "ليس رقم",
  { ".": "٫", ",": "٬", "%": "٪؜", "+": "؜+", "-": "؜-", E: "اس", "‰": "؉" },
  "٠١٢٣٤٥٦٧٨٩",
]);
Globalize.a71314638 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "ليس رقم",
  { ".": "٫", ",": "٬", "%": "٪؜", "+": "؜+", "-": "؜-", E: "اس", "‰": "؉" },
  "٠١٢٣٤٥٦٧٨٩",
]);
Globalize.b1800711952 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "ليس رقم",
  { ".": "٫", ",": "٬", "%": "٪؜", "+": "؜+", "-": "؜-", E: "اس", "‰": "؉" },
  "٠١٢٣٤٥٦٧٨٩",
]);
Globalize.a311225754 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "ليس رقم",
  { ".": "٫", ",": "٬", "%": "٪؜", "+": "؜+", "-": "؜-", E: "اس", "‰": "؉" },
  "٠١٢٣٤٥٦٧٨٩",
]);
Globalize.a1058394490 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "ليس رقم",
  { ".": "٫", ",": "٬", "%": "٪؜", "+": "؜+", "-": "؜-", E: "اس", "‰": "؉" },
  "٠١٢٣٤٥٦٧٨٩",
]);
Globalize.b1405444964 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "ليس رقم",
  { ".": "٫", ",": "٬", "%": "٪؜", "+": "؜+", "-": "؜-", E: "اس", "‰": "؉" },
  "٠١٢٣٤٥٦٧٨٩",
]);
Globalize.b162621629 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b745972495 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1541980371 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a83314634 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1711882934 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1565068396 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b426806024 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b345754148 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1581691774 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1590866443 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a2072550185 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1593697547 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b431368057 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b487177171 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1597391631 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1832240673 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "e", "‰": "‰" },
]);
Globalize.a965216723 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "e", "‰": "‰" },
]);
Globalize.a1632485429 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "e", "‰": "‰" },
]);
Globalize.a821123423 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b314646955 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1634332471 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1706669702 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1366822502 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1638950076 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a2082738969 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "×10^", "‰": "‰" },
]);
Globalize.a140729307 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "×10^", "‰": "‰" },
]);
Globalize.a1639873597 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "×10^", "‰": "‰" },
]);
Globalize.a1577180344 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "−", E: "E", "‰": "‰" },
]);
Globalize.a1648281116 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "−", E: "E", "‰": "‰" },
]);
Globalize.a1640797118 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "−", E: "E", "‰": "‰" },
]);
Globalize.a561501061 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "epäluku",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "E", "‰": "‰" },
]);
Globalize.a226994415 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "epäluku",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "E", "‰": "‰" },
]);
Globalize.a1658344017 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "epäluku",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "E", "‰": "‰" },
]);
Globalize.a306440732 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a910058808 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1666655706 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a552376995 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b55851631 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1689743731 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1303871883 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "‎+", "-": "‎-", E: "E", "‰": "‰" },
]);
Globalize.a1765653417 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "‎+", "-": "‎-", E: "E", "‰": "‰" },
]);
Globalize.a1711908235 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "‎+", "-": "‎-", E: "E", "‰": "‰" },
]);
Globalize.b718362617 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b794073939 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1715602319 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  2,
  "",
  "#,##,##0.###",
  "-#,##,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b973422946 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b111009546 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1723914008 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1804868475 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a116678585 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1726684571 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b977984979 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b252432569 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1739613865 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b476988387 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1901407401 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1754390201 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a2046242705 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b990654877 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1765472453 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1501791736 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "сан емес",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a689427148 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "сан емес",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1803336814 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "сан емес",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a770941060 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1870300208 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1807030898 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b249300256 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "×10^", "‰": "‰" },
]);
Globalize.a861957364 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "×10^", "‰": "‰" },
]);
Globalize.a1840277654 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "×10^", "‰": "‰" },
]);
Globalize.b1260417506 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NS",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b417906314 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NS",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1842124696 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NS",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1763810178 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1156128622 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1867983284 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1019043276 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "E", "‰": "‰" },
]);
Globalize.b1525239776 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "E", "‰": "‰" },
]);
Globalize.a1880912578 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "E", "‰": "‰" },
]);
Globalize.b1779662230 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a665376426 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1890147788 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1235441388 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b355691928 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1947406090 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1485939684 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1180179344 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1954794258 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b296705673 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b607610563 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a669821983 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1561098165 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1149072151 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a2007434955 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b299482619 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "не число",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b693695889 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "не число",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a2012976081 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "не число",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1968688144 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "e", "‰": "‰" },
]);
Globalize.a900121028 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "e", "‰": "‰" },
]);
Globalize.a2032370022 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "e", "‰": "‰" },
]);
Globalize.a1463129519 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "−", E: "e", "‰": "‰" },
]);
Globalize.b1887294459 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "−", E: "e", "‰": "‰" },
]);
Globalize.a2033293543 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "−", E: "e", "‰": "‰" },
]);
Globalize.a1435181062 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1541270670 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a985776304 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a2101971175 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a736927693 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b274946385 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a702510565 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "×10^", "‰": "‰" },
]);
Globalize.a303321743 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "×10^", "‰": "‰" },
]);
Globalize.a2042528753 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "−", E: "×10^", "‰": "‰" },
]);
Globalize.a697948532 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a161898720 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a2058228610 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b62670422 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1942452374 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a2067463820 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a688824466 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "Е", "‰": "‰" },
]);
Globalize.b120947326 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "Е", "‰": "‰" },
]);
Globalize.a2089628324 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": " ", "%": "%", "+": "+", "-": "-", E: "Е", "‰": "‰" },
]);
Globalize.b1087473771 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a648382175 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a2116410433 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ",", ",": ".", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1418994715 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "非數值",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1039493913 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "非數值",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b96411397 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "非數值",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b711487865 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b580956627 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b847000305 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1759276636 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1297329840 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a876307794 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "NaN",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1217046490 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "非數值",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a926595182 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "非數值",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b846076784 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "非數值",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1831402154 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "非數值",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b938561218 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "非數值",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1987075744 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "非數值",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b610844905 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "0",
  "-0",
  "-",
  "",
  numberRound(),
  "∞",
  "非數值",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1755992163 = numberToPartsFormatterFn([
  "",
  ,
  2,
  0,
  0,
  ,
  ,
  ,
  ,
  ,
  "",
  "00",
  "-00",
  "-",
  "",
  numberRound(),
  "∞",
  "非數值",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.b1840235905 = numberToPartsFormatterFn([
  "",
  ,
  1,
  0,
  3,
  ,
  ,
  ,
  3,
  ,
  "",
  "#,##0.###",
  "-#,##0.###",
  "-",
  "",
  numberRound(),
  "∞",
  "非數值",
  { ".": ".", ",": ",", "%": "%", "+": "+", "-": "-", E: "E", "‰": "‰" },
]);
Globalize.a1038316025 = numberFormatterFn(
  Globalize("ar-EG").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a2123356411 = numberFormatterFn(
  Globalize("ar-EG").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b1798381219 = numberFormatterFn(
  Globalize("ar-EG").numberToPartsFormatter({}),
);
Globalize.b592410675 = numberFormatterFn(
  Globalize("ar-SA").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b1184531033 = numberFormatterFn(
  Globalize("ar-SA").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b1403114231 = numberFormatterFn(
  Globalize("ar-SA").numberToPartsFormatter({}),
);
Globalize.a395141616 = numberFormatterFn(
  Globalize("bg").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b635181084 = numberFormatterFn(
  Globalize("bg").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b560738426 = numberFormatterFn(
  Globalize("bg").numberToPartsFormatter({}),
);
Globalize.a641077879 = numberFormatterFn(
  Globalize("ca").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b1601091523 = numberFormatterFn(
  Globalize("ca").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b537650401 = numberFormatterFn(
  Globalize("ca").numberToPartsFormatter({}),
);
Globalize.a130957221 = numberFormatterFn(
  Globalize("cs").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b234962737 = numberFormatterFn(
  Globalize("cs").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b521027023 = numberFormatterFn(
  Globalize("cs").numberToPartsFormatter({}),
);
Globalize.b2146337608 = numberFormatterFn(
  Globalize("da").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b2111625700 = numberFormatterFn(
  Globalize("da").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b509021250 = numberFormatterFn(
  Globalize("da").numberToPartsFormatter({}),
);
Globalize.a126395188 = numberFormatterFn(
  Globalize("de").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b376385760 = numberFormatterFn(
  Globalize("de").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b505327166 = numberFormatterFn(
  Globalize("de").numberToPartsFormatter({}),
);
Globalize.b1904963378 = numberFormatterFn(
  Globalize("el").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a1076008134 = numberFormatterFn(
  Globalize("el").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b470233368 = numberFormatterFn(
  Globalize("el").numberToPartsFormatter({}),
);
Globalize.a1378886668 = numberFormatterFn(
  Globalize("en").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b203855544 = numberFormatterFn(
  Globalize("en").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b468386326 = numberFormatterFn(
  Globalize("en").numberToPartsFormatter({}),
);
Globalize.b1148906457 = numberFormatterFn(
  Globalize("es").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b1256031091 = numberFormatterFn(
  Globalize("es").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b463768721 = numberFormatterFn(
  Globalize("es").numberToPartsFormatter({}),
);
Globalize.b1654465082 = numberFormatterFn(
  Globalize("et").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a251520718 = numberFormatterFn(
  Globalize("et").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b462845200 = numberFormatterFn(
  Globalize("et").numberToPartsFormatter({}),
);
Globalize.a2134943589 = numberFormatterFn(
  Globalize("eu").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a1759072527 = numberFormatterFn(
  Globalize("eu").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b461921679 = numberFormatterFn(
  Globalize("eu").numberToPartsFormatter({}),
);
Globalize.a1119264306 = numberFormatterFn(
  Globalize("fi").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a337785826 = numberFormatterFn(
  Globalize("fi").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b444374780 = numberFormatterFn(
  Globalize("fi").numberToPartsFormatter({}),
);
Globalize.a864203977 = numberFormatterFn(
  Globalize("fr").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a1020850219 = numberFormatterFn(
  Globalize("fr").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b436063091 = numberFormatterFn(
  Globalize("fr").numberToPartsFormatter({}),
);
Globalize.a1110140240 = numberFormatterFn(
  Globalize("gl").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a54939780 = numberFormatterFn(
  Globalize("gl").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b412975066 = numberFormatterFn(
  Globalize("gl").numberToPartsFormatter({}),
);
Globalize.a1861635128 = numberFormatterFn(
  Globalize("he").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a1876444828 = numberFormatterFn(
  Globalize("he").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b390810562 = numberFormatterFn(
  Globalize("he").numberToPartsFormatter({}),
);
Globalize.b160599372 = numberFormatterFn(
  Globalize("hi").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b683282528 = numberFormatterFn(
  Globalize("hi").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b387116478 = numberFormatterFn(
  Globalize("hi").numberToPartsFormatter({}),
);
Globalize.b415659701 = numberFormatterFn(
  Globalize("hr").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b218135 = numberFormatterFn(
  Globalize("hr").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b378804789 = numberFormatterFn(
  Globalize("hr").numberToPartsFormatter({}),
);
Globalize.b1932335576 = numberFormatterFn(
  Globalize("hu").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a227469996 = numberFormatterFn(
  Globalize("hu").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b376034226 = numberFormatterFn(
  Globalize("hu").numberToPartsFormatter({}),
);
Globalize.b420221734 = numberFormatterFn(
  Globalize("id").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b141641158 = numberFormatterFn(
  Globalize("id").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b363104932 = numberFormatterFn(
  Globalize("id").numberToPartsFormatter({}),
);
Globalize.a80774858 = numberFormatterFn(
  Globalize("it").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b1790615990 = numberFormatterFn(
  Globalize("it").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b348328596 = numberFormatterFn(
  Globalize("it").numberToPartsFormatter({}),
);
Globalize.b1690961346 = numberFormatterFn(
  Globalize("ja").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b879863466 = numberFormatterFn(
  Globalize("ja").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b337246344 = numberFormatterFn(
  Globalize("ja").numberToPartsFormatter({}),
);
Globalize.b944028491 = numberFormatterFn(
  Globalize("kk").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a800218559 = numberFormatterFn(
  Globalize("kk").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b299381983 = numberFormatterFn(
  Globalize("kk").numberToPartsFormatter({}),
);
Globalize.a1328704305 = numberFormatterFn(
  Globalize("ko").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b1759508797 = numberFormatterFn(
  Globalize("ko").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b295687899 = numberFormatterFn(
  Globalize("ko").numberToPartsFormatter({}),
);
Globalize.a308462989 = numberFormatterFn(
  Globalize("lt").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a972748775 = numberFormatterFn(
  Globalize("lt").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b262441143 = numberFormatterFn(
  Globalize("lt").numberToPartsFormatter({}),
);
Globalize.b702654261 = numberFormatterFn(
  Globalize("lv").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b307114903 = numberFormatterFn(
  Globalize("lv").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b260594101 = numberFormatterFn(
  Globalize("lv").numberToPartsFormatter({}),
);
Globalize.b1973393873 = numberFormatterFn(
  Globalize("ms").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b1045337211 = numberFormatterFn(
  Globalize("ms").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b234735513 = numberFormatterFn(
  Globalize("ms").numberToPartsFormatter({}),
);
Globalize.b461280031 = numberFormatterFn(
  Globalize("nb").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b1414448365 = numberFormatterFn(
  Globalize("nb").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b221806219 = numberFormatterFn(
  Globalize("nb").numberToPartsFormatter({}),
);
Globalize.b1221898985 = numberFormatterFn(
  Globalize("nl").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a776167837 = numberFormatterFn(
  Globalize("nl").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b212571009 = numberFormatterFn(
  Globalize("nl").numberToPartsFormatter({}),
);
Globalize.a1793204633 = numberFormatterFn(
  Globalize("pl").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b244900517 = numberFormatterFn(
  Globalize("pl").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b155312707 = numberFormatterFn(
  Globalize("pl").numberToPartsFormatter({}),
);
Globalize.a2043702929 = numberFormatterFn(
  Globalize("pt").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b1069387933 = numberFormatterFn(
  Globalize("pt").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b147924539 = numberFormatterFn(
  Globalize("pt").numberToPartsFormatter({}),
);
Globalize.b1200342102 = numberFormatterFn(
  Globalize("pt-PT").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a1444431210 = numberFormatterFn(
  Globalize("pt-PT").numberToPartsFormatter({ raw: "00" }),
);
Globalize.a672152716 = numberFormatterFn(
  Globalize("pt-PT").numberToPartsFormatter({}),
);
Globalize.b1003334920 = numberFormatterFn(
  Globalize("ro").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b1038280740 = numberFormatterFn(
  Globalize("ro").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b95283842 = numberFormatterFn(
  Globalize("ro").numberToPartsFormatter({}),
);
Globalize.a258280626 = numberFormatterFn(
  Globalize("ru").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b582904478 = numberFormatterFn(
  Globalize("ru").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b89742716 = numberFormatterFn(
  Globalize("ru").numberToPartsFormatter({}),
);
Globalize.b1768515907 = numberFormatterFn(
  Globalize("sk").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a1010912439 = numberFormatterFn(
  Globalize("sk").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b70348775 = numberFormatterFn(
  Globalize("sk").numberToPartsFormatter({}),
);
Globalize.a2020892764 = numberFormatterFn(
  Globalize("sl").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b1776503048 = numberFormatterFn(
  Globalize("sl").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b69425254 = numberFormatterFn(
  Globalize("sl").numberToPartsFormatter({}),
);
Globalize.a623966585 = numberFormatterFn(
  Globalize("sr-Cyrl").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b2131541637 = numberFormatterFn(
  Globalize("sr-Cyrl").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b1069356579 = numberFormatterFn(
  Globalize("sr-Cyrl").numberToPartsFormatter({}),
);
Globalize.a1290756698 = numberFormatterFn(
  Globalize("sr-Latn").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a1359082682 = numberFormatterFn(
  Globalize("sr-Latn").numberToPartsFormatter({ raw: "00" }),
);
Globalize.a1964888028 = numberFormatterFn(
  Globalize("sr-Latn").numberToPartsFormatter({}),
);
Globalize.a1260273810 = numberFormatterFn(
  Globalize("sv").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a414113154 = numberFormatterFn(
  Globalize("sv").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b60190044 = numberFormatterFn(
  Globalize("sv").numberToPartsFormatter({}),
);
Globalize.a1255711777 = numberFormatterFn(
  Globalize("th").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a272690131 = numberFormatterFn(
  Globalize("th").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b44490187 = numberFormatterFn(
  Globalize("th").numberToPartsFormatter({}),
);
Globalize.a495092823 = numberFormatterFn(
  Globalize("tr").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b1831660963 = numberFormatterFn(
  Globalize("tr").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b35254977 = numberFormatterFn(
  Globalize("tr").numberToPartsFormatter({}),
);
Globalize.a1246587711 = numberFormatterFn(
  Globalize("uk").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b10155915 = numberFormatterFn(
  Globalize("uk").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b13090473 = numberFormatterFn(
  Globalize("uk").numberToPartsFormatter({}),
);
Globalize.b529710526 = numberFormatterFn(
  Globalize("vi").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a759173586 = numberFormatterFn(
  Globalize("vi").numberToPartsFormatter({ raw: "00" }),
);
Globalize.a13691636 = numberFormatterFn(
  Globalize("vi").numberToPartsFormatter({}),
);
Globalize.a1529786126 = numberFormatterFn(
  Globalize("yue").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a179060358 = numberFormatterFn(
  Globalize("yue").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b856184664 = numberFormatterFn(
  Globalize("yue").numberToPartsFormatter({}),
);
Globalize.b1522702342 = numberFormatterFn(
  Globalize("zh-Hans").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a41198362 = numberFormatterFn(
  Globalize("zh-Hans").numberToPartsFormatter({ raw: "00" }),
);
Globalize.a1392834108 = numberFormatterFn(
  Globalize("zh-Hans").numberToPartsFormatter({}),
);
Globalize.b868786351 = numberFormatterFn(
  Globalize("zh-Hans-SG").numberToPartsFormatter({ raw: "0" }),
);
Globalize.b1162242397 = numberFormatterFn(
  Globalize("zh-Hans-SG").numberToPartsFormatter({ raw: "00" }),
);
Globalize.a1171394821 = numberFormatterFn(
  Globalize("zh-Hans-SG").numberToPartsFormatter({}),
);
Globalize.b2028260967 = numberFormatterFn(
  Globalize("zh-Hant").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a1548750171 = numberFormatterFn(
  Globalize("zh-Hant").numberToPartsFormatter({ raw: "00" }),
);
Globalize.a1393757629 = numberFormatterFn(
  Globalize("zh-Hant").numberToPartsFormatter({}),
);
Globalize.b940911869 = numberFormatterFn(
  Globalize("zh-Hant-HK").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a896833841 = numberFormatterFn(
  Globalize("zh-Hant-HK").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b1691988717 = numberFormatterFn(
  Globalize("zh-Hant-HK").numberToPartsFormatter({}),
);
Globalize.a279645380 = numberFormatterFn(
  Globalize("zh-Hant-MO").numberToPartsFormatter({ raw: "0" }),
);
Globalize.a79402896 = numberFormatterFn(
  Globalize("zh-Hant-MO").numberToPartsFormatter({ raw: "00" }),
);
Globalize.b1545148878 = numberFormatterFn(
  Globalize("zh-Hant-MO").numberToPartsFormatter({}),
);
Globalize.b469273361 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    t0 = Number(s[0]) == n,
    n100 = t0 && s[0].slice(-2);
  return n == 0
    ? "zero"
    : n == 1
      ? "one"
      : n == 2
        ? "two"
        : n100 >= 3 && n100 <= 10
          ? "few"
          : n100 >= 11 && n100 <= 99
            ? "many"
            : "other";
});
Globalize.b74006373 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    t0 = Number(s[0]) == n,
    n100 = t0 && s[0].slice(-2);
  return n == 0
    ? "zero"
    : n == 1
      ? "one"
      : n == 2
        ? "two"
        : n100 >= 3 && n100 <= 10
          ? "few"
          : n100 >= 11 && n100 <= 99
            ? "many"
            : "other";
});
Globalize.a1569994036 = pluralGeneratorFn(function (n) {
  return n == 1 ? "one" : "other";
});
Globalize.a1593082061 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    v0 = !s[1];
  return n == 1 && v0 ? "one" : "other";
});
Globalize.a1609705439 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    i = s[0],
    v0 = !s[1];
  return n == 1 && v0
    ? "one"
    : i >= 2 && i <= 4 && v0
      ? "few"
      : !v0
        ? "many"
        : "other";
});
Globalize.a1621711212 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    i = s[0],
    t0 = Number(s[0]) == n;
  return n == 1 || (!t0 && (i == 0 || i == 1)) ? "one" : "other";
});
Globalize.a1625405296 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    v0 = !s[1];
  return n == 1 && v0 ? "one" : "other";
});
Globalize.a1660499094 = pluralGeneratorFn(function (n) {
  return n == 1 ? "one" : "other";
});
Globalize.a1662346136 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    v0 = !s[1];
  return n == 1 && v0 ? "one" : "other";
});
Globalize.a1666963741 = pluralGeneratorFn(function (n) {
  return n == 1 ? "one" : "other";
});
Globalize.a1667887262 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    v0 = !s[1];
  return n == 1 && v0 ? "one" : "other";
});
Globalize.a1668810783 = pluralGeneratorFn(function (n) {
  return n == 1 ? "one" : "other";
});
Globalize.a1686357682 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    v0 = !s[1];
  return n == 1 && v0 ? "one" : "other";
});
Globalize.a1694669371 = pluralGeneratorFn(function (n) {
  return n >= 0 && n < 2 ? "one" : "other";
});
Globalize.a1717757396 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    v0 = !s[1];
  return n == 1 && v0 ? "one" : "other";
});
Globalize.a1739921900 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    i = s[0],
    v0 = !s[1],
    t0 = Number(s[0]) == n,
    n10 = t0 && s[0].slice(-1);
  return n == 1 && v0
    ? "one"
    : i == 2 && v0
      ? "two"
      : v0 && (n < 0 || n > 10) && t0 && n10 == 0
        ? "many"
        : "other";
});
Globalize.a1743615984 = pluralGeneratorFn(function (n) {
  return n >= 0 && n <= 1 ? "one" : "other";
});
Globalize.a1751927673 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    i = s[0],
    f = s[1] || "",
    v0 = !s[1],
    i10 = i.slice(-1),
    i100 = i.slice(-2),
    f10 = f.slice(-1),
    f100 = f.slice(-2);
  return (v0 && i10 == 1 && i100 != 11) || (f10 == 1 && f100 != 11)
    ? "one"
    : (v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14)) ||
        (f10 >= 2 && f10 <= 4 && (f100 < 12 || f100 > 14))
      ? "few"
      : "other";
});
Globalize.a1754698236 = pluralGeneratorFn(function (n) {
  return n == 1 ? "one" : "other";
});
Globalize.a1767627530 = pluralGeneratorFn(function (n) {
  return "other";
});
Globalize.a1782403866 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    v0 = !s[1];
  return n == 1 && v0 ? "one" : "other";
});
Globalize.a1793486118 = pluralGeneratorFn(function (n) {
  return "other";
});
Globalize.a1831350479 = pluralGeneratorFn(function (n) {
  return n == 1 ? "one" : "other";
});
Globalize.a1835044563 = pluralGeneratorFn(function (n) {
  return "other";
});
Globalize.a1868291319 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    f = s[1] || "",
    t0 = Number(s[0]) == n,
    n10 = t0 && s[0].slice(-1),
    n100 = t0 && s[0].slice(-2);
  return n10 == 1 && (n100 < 11 || n100 > 19)
    ? "one"
    : n10 >= 2 && n10 <= 9 && (n100 < 11 || n100 > 19)
      ? "few"
      : f != 0
        ? "many"
        : "other";
});
Globalize.a1870138361 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    f = s[1] || "",
    v = f.length,
    t0 = Number(s[0]) == n,
    n10 = t0 && s[0].slice(-1),
    n100 = t0 && s[0].slice(-2),
    f100 = f.slice(-2),
    f10 = f.slice(-1);
  return (t0 && n10 == 0) ||
    (n100 >= 11 && n100 <= 19) ||
    (v == 2 && f100 >= 11 && f100 <= 19)
    ? "zero"
    : (n10 == 1 && n100 != 11) ||
        (v == 2 && f10 == 1 && f100 != 11) ||
        (v != 2 && f10 == 1)
      ? "one"
      : "other";
});
Globalize.a1895996949 = pluralGeneratorFn(function (n) {
  return "other";
});
Globalize.a1908926243 = pluralGeneratorFn(function (n) {
  return n == 1 ? "one" : "other";
});
Globalize.a1918161453 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    v0 = !s[1];
  return n == 1 && v0 ? "one" : "other";
});
Globalize.a1975419755 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    i = s[0],
    v0 = !s[1],
    i10 = i.slice(-1),
    i100 = i.slice(-2);
  return n == 1 && v0
    ? "one"
    : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14)
      ? "few"
      : (v0 && i != 1 && (i10 == 0 || i10 == 1)) ||
          (v0 && i10 >= 5 && i10 <= 9) ||
          (v0 && i100 >= 12 && i100 <= 14)
        ? "many"
        : "other";
});
Globalize.a1982807923 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    i = s[0];
  return i == 0 || i == 1 ? "one" : "other";
});
Globalize.a2001260574 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    i = s[0];
  return i == 0 || i == 1 ? "one" : "other";
});
Globalize.a2035448620 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    v0 = !s[1],
    t0 = Number(s[0]) == n,
    n100 = t0 && s[0].slice(-2);
  return n == 1 && v0
    ? "one"
    : !v0 || n == 0 || (n100 >= 2 && n100 <= 19)
      ? "few"
      : "other";
});
Globalize.a2040989746 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    i = s[0],
    v0 = !s[1],
    i10 = i.slice(-1),
    i100 = i.slice(-2);
  return v0 && i10 == 1 && i100 != 11
    ? "one"
    : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14)
      ? "few"
      : (v0 && i10 == 0) ||
          (v0 && i10 >= 5 && i10 <= 9) ||
          (v0 && i100 >= 11 && i100 <= 14)
        ? "many"
        : "other";
});
Globalize.a2060383687 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    i = s[0],
    v0 = !s[1];
  return n == 1 && v0
    ? "one"
    : i >= 2 && i <= 4 && v0
      ? "few"
      : !v0
        ? "many"
        : "other";
});
Globalize.a2061307208 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    i = s[0],
    v0 = !s[1],
    i100 = i.slice(-2);
  return v0 && i100 == 1
    ? "one"
    : v0 && i100 == 2
      ? "two"
      : (v0 && (i100 == 3 || i100 == 4)) || !v0
        ? "few"
        : "other";
});
Globalize.a598008047 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    i = s[0],
    f = s[1] || "",
    v0 = !s[1],
    i10 = i.slice(-1),
    i100 = i.slice(-2),
    f10 = f.slice(-1),
    f100 = f.slice(-2);
  return (v0 && i10 == 1 && i100 != 11) || (f10 == 1 && f100 != 11)
    ? "one"
    : (v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14)) ||
        (f10 >= 2 && f10 <= 4 && (f100 < 12 || f100 > 14))
      ? "few"
      : "other";
});
Globalize.b662714642 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    i = s[0],
    f = s[1] || "",
    v0 = !s[1],
    i10 = i.slice(-1),
    i100 = i.slice(-2),
    f10 = f.slice(-1),
    f100 = f.slice(-2);
  return (v0 && i10 == 1 && i100 != 11) || (f10 == 1 && f100 != 11)
    ? "one"
    : (v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14)) ||
        (f10 >= 2 && f10 <= 4 && (f100 < 12 || f100 > 14))
      ? "few"
      : "other";
});
Globalize.a2070542418 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    v0 = !s[1];
  return n == 1 && v0 ? "one" : "other";
});
Globalize.a2086242275 = pluralGeneratorFn(function (n) {
  return "other";
});
Globalize.a2095477485 = pluralGeneratorFn(function (n) {
  return n == 1 ? "one" : "other";
});
Globalize.a2117641989 = pluralGeneratorFn(function (n) {
  var s = String(n).split("."),
    i = s[0],
    v0 = !s[1],
    i10 = i.slice(-1),
    i100 = i.slice(-2);
  return v0 && i10 == 1 && i100 != 11
    ? "one"
    : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14)
      ? "few"
      : (v0 && i10 == 0) ||
          (v0 && i10 >= 5 && i10 <= 9) ||
          (v0 && i100 >= 11 && i100 <= 14)
        ? "many"
        : "other";
});
Globalize.a2144424098 = pluralGeneratorFn(function (n) {
  return "other";
});
Globalize.a772012218 = pluralGeneratorFn(function (n) {
  return "other";
});
Globalize.b1234768562 = pluralGeneratorFn(function (n) {
  return "other";
});
Globalize.b1960777549 = pluralGeneratorFn(function (n) {
  return "other";
});
Globalize.b1233845041 = pluralGeneratorFn(function (n) {
  return "other";
});
Globalize.b529193791 = pluralGeneratorFn(function (n) {
  return "other";
});
Globalize.b382353952 = pluralGeneratorFn(function (n) {
  return "other";
});
Globalize.a1178402495 = dateToPartsFormatterFn(
  {
    1: Globalize("ar-EG").numberFormatter({ raw: "0" }),
    2: Globalize("ar-EG").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "يناير",
          2: "فبراير",
          3: "مارس",
          4: "أبريل",
          5: "مايو",
          6: "يونيو",
          7: "يوليو",
          8: "أغسطس",
          9: "سبتمبر",
          10: "أكتوبر",
          11: "نوفمبر",
          12: "ديسمبر",
        },
      },
    },
    dayPeriods: { am: "ص", pm: "م" },
  },
);
Globalize.a1191033963 = dateToPartsFormatterFn(
  {
    1: Globalize("ar-SA").numberFormatter({ raw: "0" }),
    2: Globalize("ar-SA").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "يناير",
          2: "فبراير",
          3: "مارس",
          4: "أبريل",
          5: "مايو",
          6: "يونيو",
          7: "يوليو",
          8: "أغسطس",
          9: "سبتمبر",
          10: "أكتوبر",
          11: "نوفمبر",
          12: "ديسمبر",
        },
      },
    },
    dayPeriods: { am: "ص", pm: "م" },
  },
);
Globalize.b474539636 = dateToPartsFormatterFn(
  {
    1: Globalize("bg").numberFormatter({ raw: "0" }),
    2: Globalize("bg").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM, h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "януари",
          2: "февруари",
          3: "март",
          4: "април",
          5: "май",
          6: "юни",
          7: "юли",
          8: "август",
          9: "септември",
          10: "октомври",
          11: "ноември",
          12: "декември",
        },
      },
    },
    dayPeriods: { am: "пр.об.", pm: "сл.об." },
  },
);
Globalize.b865165659 = dateToPartsFormatterFn(
  {
    1: Globalize("ca").numberFormatter({ raw: "0" }),
    2: Globalize("ca").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM 'a' 'les' h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "de gener",
          2: "de febrer",
          3: "de març",
          4: "d’abril",
          5: "de maig",
          6: "de juny",
          7: "de juliol",
          8: "d’agost",
          9: "de setembre",
          10: "d’octubre",
          11: "de novembre",
          12: "de desembre",
        },
      },
    },
    dayPeriods: { am: "a. m.", pm: "p. m." },
  },
);
Globalize.a399771831 = dateToPartsFormatterFn(
  {
    1: Globalize("cs").numberFormatter({ raw: "0" }),
    2: Globalize("cs").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d. MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "ledna",
          2: "února",
          3: "března",
          4: "dubna",
          5: "května",
          6: "června",
          7: "července",
          8: "srpna",
          9: "září",
          10: "října",
          11: "listopadu",
          12: "prosince",
        },
      },
    },
    dayPeriods: { am: "dop.", pm: "odp." },
  },
);
Globalize.b834145852 = dateToPartsFormatterFn(
  {
    1: Globalize("da").numberFormatter({ raw: "0" }),
    2: Globalize("da").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d. MMMM 'kl'. h.mm a",
    timeSeparator: ".",
    months: {
      M: {
        4: {
          1: "januar",
          2: "februar",
          3: "marts",
          4: "april",
          5: "maj",
          6: "juni",
          7: "juli",
          8: "august",
          9: "september",
          10: "oktober",
          11: "november",
          12: "december",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.b553048632 = dateToPartsFormatterFn(
  {
    1: Globalize("de").numberFormatter({ raw: "0" }),
    2: Globalize("de").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d. MMMM 'um' h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "Januar",
          2: "Februar",
          3: "März",
          4: "April",
          5: "Mai",
          6: "Juni",
          7: "Juli",
          8: "August",
          9: "September",
          10: "Oktober",
          11: "November",
          12: "Dezember",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.a2117374958 = dateToPartsFormatterFn(
  {
    1: Globalize("el").numberFormatter({ raw: "0" }),
    2: Globalize("el").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM - h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "Ιανουαρίου",
          2: "Φεβρουαρίου",
          3: "Μαρτίου",
          4: "Απριλίου",
          5: "Μαΐου",
          6: "Ιουνίου",
          7: "Ιουλίου",
          8: "Αυγούστου",
          9: "Σεπτεμβρίου",
          10: "Οκτωβρίου",
          11: "Νοεμβρίου",
          12: "Δεκεμβρίου",
        },
      },
    },
    dayPeriods: { am: "π.μ.", pm: "μ.μ." },
  },
);
Globalize.b2037043728 = dateToPartsFormatterFn(
  {
    1: Globalize("en").numberFormatter({ raw: "0" }),
    2: Globalize("en").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "MMMM d 'at' h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "January",
          2: "February",
          3: "March",
          4: "April",
          5: "May",
          6: "June",
          7: "July",
          8: "August",
          9: "September",
          10: "October",
          11: "November",
          12: "December",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.a461811445 = dateToPartsFormatterFn(
  {
    1: Globalize("es").numberFormatter({ raw: "0" }),
    2: Globalize("es").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d 'de' MMMM, h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "enero",
          2: "febrero",
          3: "marzo",
          4: "abril",
          5: "mayo",
          6: "junio",
          7: "julio",
          8: "agosto",
          9: "septiembre",
          10: "octubre",
          11: "noviembre",
          12: "diciembre",
        },
      },
    },
    dayPeriods: { am: "a. m.", pm: "p. m." },
  },
);
Globalize.b1615397898 = dateToPartsFormatterFn(
  {
    1: Globalize("et").numberFormatter({ raw: "0" }),
    2: Globalize("et").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d. MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "jaanuar",
          2: "veebruar",
          3: "märts",
          4: "aprill",
          5: "mai",
          6: "juuni",
          7: "juuli",
          8: "august",
          9: "september",
          10: "oktoober",
          11: "november",
          12: "detsember",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.a602360055 = dateToPartsFormatterFn(
  {
    1: Globalize("eu").numberFormatter({ raw: "0" }),
    2: Globalize("eu").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "MMMM d h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "urtarrila",
          2: "otsaila",
          3: "martxoa",
          4: "apirila",
          5: "maiatza",
          6: "ekaina",
          7: "uztaila",
          8: "abuztua",
          9: "iraila",
          10: "urria",
          11: "azaroa",
          12: "abendua",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.b209911798 = dateToPartsFormatterFn(
  {
    1: Globalize("fi").numberFormatter({ raw: "0" }),
    2: Globalize("fi").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d. MMMM 'klo' h.mm a",
    timeSeparator: ".",
    months: {
      M: {
        4: {
          1: "tammikuuta",
          2: "helmikuuta",
          3: "maaliskuuta",
          4: "huhtikuuta",
          5: "toukokuuta",
          6: "kesäkuuta",
          7: "heinäkuuta",
          8: "elokuuta",
          9: "syyskuuta",
          10: "lokakuuta",
          11: "marraskuuta",
          12: "joulukuuta",
        },
      },
    },
    dayPeriods: { am: "ap.", pm: "ip." },
  },
);
Globalize.b1724926701 = dateToPartsFormatterFn(
  {
    1: Globalize("fr").numberFormatter({ raw: "0" }),
    2: Globalize("fr").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM 'à' h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "janvier",
          2: "février",
          3: "mars",
          4: "avril",
          5: "mai",
          6: "juin",
          7: "juillet",
          8: "août",
          9: "septembre",
          10: "octobre",
          11: "novembre",
          12: "décembre",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.b2115552724 = dateToPartsFormatterFn(
  {
    1: Globalize("gl").numberFormatter({ raw: "0" }),
    2: Globalize("gl").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "h:mm a 'do' d 'de' MMMM",
    timeSeparator: ":",
    dayPeriods: { am: "a.m.", pm: "p.m." },
    months: {
      M: {
        4: {
          1: "xaneiro",
          2: "febreiro",
          3: "marzo",
          4: "abril",
          5: "maio",
          6: "xuño",
          7: "xullo",
          8: "agosto",
          9: "setembro",
          10: "outubro",
          11: "novembro",
          12: "decembro",
        },
      },
    },
  },
);
Globalize.b428969404 = dateToPartsFormatterFn(
  {
    1: Globalize("he").numberFormatter({ raw: "0" }),
    2: Globalize("he").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d בMMMM בשעה h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "ינואר",
          2: "פברואר",
          3: "מרץ",
          4: "אפריל",
          5: "מאי",
          6: "יוני",
          7: "יולי",
          8: "אוגוסט",
          9: "ספטמבר",
          10: "אוקטובר",
          11: "נובמבר",
          12: "דצמבר",
        },
      },
    },
    dayPeriods: { am: "לפנה״צ", pm: "אחה״צ" },
  },
);
Globalize.b147872184 = dateToPartsFormatterFn(
  {
    1: Globalize("hi").numberFormatter({ raw: "0" }),
    2: Globalize("hi").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM को h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "जनवरी",
          2: "फ़रवरी",
          3: "मार्च",
          4: "अप्रैल",
          5: "मई",
          6: "जून",
          7: "जुलाई",
          8: "अगस्त",
          9: "सितंबर",
          10: "अक्तूबर",
          11: "नवंबर",
          12: "दिसंबर",
        },
      },
    },
    dayPeriods: { am: "am", pm: "pm" },
  },
);
Globalize.b1662887087 = dateToPartsFormatterFn(
  {
    1: Globalize("hr").numberFormatter({ raw: "0" }),
    2: Globalize("hr").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d. MMMM 'u' hh:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "siječnja",
          2: "veljače",
          3: "ožujka",
          4: "travnja",
          5: "svibnja",
          6: "lipnja",
          7: "srpnja",
          8: "kolovoza",
          9: "rujna",
          10: "listopada",
          11: "studenoga",
          12: "prosinca",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.a695419476 = dateToPartsFormatterFn(
  {
    1: Globalize("hu").numberFormatter({ raw: "0" }),
    2: Globalize("hu").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "MMMM d. a h:mm",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "január",
          2: "február",
          3: "március",
          4: "április",
          5: "május",
          6: "június",
          7: "július",
          8: "augusztus",
          9: "szeptember",
          10: "október",
          11: "november",
          12: "december",
        },
      },
    },
    dayPeriods: { am: "de.", pm: "du." },
  },
);
Globalize.a1679259746 = dateToPartsFormatterFn(
  {
    1: Globalize("id").numberFormatter({ raw: "0" }),
    2: Globalize("id").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM h.mm a",
    timeSeparator: ".",
    months: {
      M: {
        4: {
          1: "Januari",
          2: "Februari",
          3: "Maret",
          4: "April",
          5: "Mei",
          6: "Juni",
          7: "Juli",
          8: "Agustus",
          9: "September",
          10: "Oktober",
          11: "November",
          12: "Desember",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.b1491318670 = dateToPartsFormatterFn(
  {
    1: Globalize("it").numberFormatter({ raw: "0" }),
    2: Globalize("it").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "gennaio",
          2: "febbraio",
          3: "marzo",
          4: "aprile",
          5: "maggio",
          6: "giugno",
          7: "luglio",
          8: "agosto",
          9: "settembre",
          10: "ottobre",
          11: "novembre",
          12: "dicembre",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.b648027010 = dateToPartsFormatterFn(
  {
    1: Globalize("ja").numberFormatter({ raw: "0" }),
    2: Globalize("ja").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "M月d日 aK:mm",
    timeSeparator: ":",
    dayPeriods: { am: "午前", pm: "午後" },
  },
);
Globalize.a85735847 = dateToPartsFormatterFn(
  {
    1: Globalize("kk").numberFormatter({ raw: "0" }),
    2: Globalize("kk").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM, h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "қаңтар",
          2: "ақпан",
          3: "наурыз",
          4: "сәуір",
          5: "мамыр",
          6: "маусым",
          7: "шілде",
          8: "тамыз",
          9: "қыркүйек",
          10: "қазан",
          11: "қараша",
          12: "желтоқсан",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.a366833067 = dateToPartsFormatterFn(
  {
    1: Globalize("ko").numberFormatter({ raw: "0" }),
    2: Globalize("ko").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "MMMM d일 a h:mm",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "1월",
          2: "2월",
          3: "3월",
          4: "4월",
          5: "5월",
          6: "6월",
          7: "7월",
          8: "8월",
          9: "9월",
          10: "10월",
          11: "11월",
          12: "12월",
        },
      },
    },
    dayPeriods: { am: "오전", pm: "오후" },
  },
);
Globalize.b1398259249 = dateToPartsFormatterFn(
  {
    1: Globalize("lt").numberFormatter({ raw: "0" }),
    2: Globalize("lt").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "MMMM d 'd'. hh:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "sausio",
          2: "vasario",
          3: "kovo",
          4: "balandžio",
          5: "gegužės",
          6: "birželio",
          7: "liepos",
          8: "rugpjūčio",
          9: "rugsėjo",
          10: "spalio",
          11: "lapkričio",
          12: "gruodžio",
        },
      },
    },
    dayPeriods: { am: "priešpiet", pm: "popiet" },
  },
);
Globalize.b1257710639 = dateToPartsFormatterFn(
  {
    1: Globalize("lv").numberFormatter({ raw: "0" }),
    2: Globalize("lv").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d. MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "janvāris",
          2: "februāris",
          3: "marts",
          4: "aprīlis",
          5: "maijs",
          6: "jūnijs",
          7: "jūlijs",
          8: "augusts",
          9: "septembris",
          10: "oktobris",
          11: "novembris",
          12: "decembris",
        },
      },
    },
    dayPeriods: { am: "priekšpusdienā", pm: "pēcpusdienā" },
  },
);
Globalize.a709969901 = dateToPartsFormatterFn(
  {
    1: Globalize("ms").numberFormatter({ raw: "0" }),
    2: Globalize("ms").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "Januari",
          2: "Februari",
          3: "Mac",
          4: "April",
          5: "Mei",
          6: "Jun",
          7: "Julai",
          8: "Ogos",
          9: "September",
          10: "Oktober",
          11: "November",
          12: "Disember",
        },
      },
    },
    dayPeriods: { am: "PG", pm: "PTG" },
  },
);
Globalize.a1693810171 = dateToPartsFormatterFn(
  {
    1: Globalize("nb").numberFormatter({ raw: "0" }),
    2: Globalize("nb").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d. MMMM 'kl'. h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "januar",
          2: "februar",
          3: "mars",
          4: "april",
          5: "mai",
          6: "juni",
          7: "juli",
          8: "august",
          9: "september",
          10: "oktober",
          11: "november",
          12: "desember",
        },
      },
    },
    dayPeriods: { am: "a.m.", pm: "p.m." },
  },
);
Globalize.b1898414075 = dateToPartsFormatterFn(
  {
    1: Globalize("nl").numberFormatter({ raw: "0" }),
    2: Globalize("nl").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM 'om' h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "januari",
          2: "februari",
          3: "maart",
          4: "april",
          5: "mei",
          6: "juni",
          7: "juli",
          8: "augustus",
          9: "september",
          10: "oktober",
          11: "november",
          12: "december",
        },
      },
    },
    dayPeriods: { am: "a.m.", pm: "p.m." },
  },
);
Globalize.b1836374461 = dateToPartsFormatterFn(
  {
    1: Globalize("pl").numberFormatter({ raw: "0" }),
    2: Globalize("pl").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "stycznia",
          2: "lutego",
          3: "marca",
          4: "kwietnia",
          5: "maja",
          6: "czerwca",
          7: "lipca",
          8: "sierpnia",
          9: "września",
          10: "października",
          11: "listopada",
          12: "grudnia",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.b1274180021 = dateToPartsFormatterFn(
  {
    1: Globalize("pt").numberFormatter({ raw: "0" }),
    2: Globalize("pt").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d 'de' MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "janeiro",
          2: "fevereiro",
          3: "março",
          4: "abril",
          5: "maio",
          6: "junho",
          7: "julho",
          8: "agosto",
          9: "setembro",
          10: "outubro",
          11: "novembro",
          12: "dezembro",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.a1582820462 = dateToPartsFormatterFn(
  {
    1: Globalize("pt-PT").numberFormatter({ raw: "0" }),
    2: Globalize("pt-PT").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d 'de' MMMM 'às' h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "janeiro",
          2: "fevereiro",
          3: "março",
          4: "abril",
          5: "maio",
          6: "junho",
          7: "julho",
          8: "agosto",
          9: "setembro",
          10: "outubro",
          11: "novembro",
          12: "dezembro",
        },
      },
    },
    dayPeriods: { am: "da manhã", pm: "da tarde" },
  },
);
Globalize.a583971716 = dateToPartsFormatterFn(
  {
    1: Globalize("ro").numberFormatter({ raw: "0" }),
    2: Globalize("ro").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM, h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "ianuarie",
          2: "februarie",
          3: "martie",
          4: "aprilie",
          5: "mai",
          6: "iunie",
          7: "iulie",
          8: "august",
          9: "septembrie",
          10: "octombrie",
          11: "noiembrie",
          12: "decembrie",
        },
      },
    },
    dayPeriods: { am: "a.m.", pm: "p.m." },
  },
);
Globalize.a1005617546 = dateToPartsFormatterFn(
  {
    1: Globalize("ru").numberFormatter({ raw: "0" }),
    2: Globalize("ru").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM, h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "января",
          2: "февраля",
          3: "марта",
          4: "апреля",
          5: "мая",
          6: "июня",
          7: "июля",
          8: "августа",
          9: "сентября",
          10: "октября",
          11: "ноября",
          12: "декабря",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.a333894303 = dateToPartsFormatterFn(
  {
    1: Globalize("sk").numberFormatter({ raw: "0" }),
    2: Globalize("sk").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d. MMMM, h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "januára",
          2: "februára",
          3: "marca",
          4: "apríla",
          5: "mája",
          6: "júna",
          7: "júla",
          8: "augusta",
          9: "septembra",
          10: "októbra",
          11: "novembra",
          12: "decembra",
        },
      },
    },
    dayPeriods: { am: "AM", pm: "PM" },
  },
);
Globalize.b1743315040 = dateToPartsFormatterFn(
  {
    1: Globalize("sl").numberFormatter({ raw: "0" }),
    2: Globalize("sl").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d. MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "januar",
          2: "februar",
          3: "marec",
          4: "april",
          5: "maj",
          6: "junij",
          7: "julij",
          8: "avgust",
          9: "september",
          10: "oktober",
          11: "november",
          12: "december",
        },
      },
    },
    dayPeriods: { am: "dop.", pm: "pop." },
  },
);
Globalize.a374999999 = dateToPartsFormatterFn(
  {
    1: Globalize("sr-Cyrl").numberFormatter({ raw: "0" }),
    2: Globalize("sr-Cyrl").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d. MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "јануар",
          2: "фебруар",
          3: "март",
          4: "април",
          5: "мај",
          6: "јун",
          7: "јул",
          8: "август",
          9: "септембар",
          10: "октобар",
          11: "новембар",
          12: "децембар",
        },
      },
    },
    dayPeriods: { am: "пре подне", pm: "по подне" },
  },
);
Globalize.a976026686 = dateToPartsFormatterFn(
  {
    1: Globalize("sr-Latn").numberFormatter({ raw: "0" }),
    2: Globalize("sr-Latn").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d. MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "januar",
          2: "februar",
          3: "mart",
          4: "april",
          5: "maj",
          6: "jun",
          7: "jul",
          8: "avgust",
          9: "septembar",
          10: "oktobar",
          11: "novembar",
          12: "decembar",
        },
      },
    },
    dayPeriods: { am: "pre podne", pm: "po podne" },
  },
);
Globalize.b1040571990 = dateToPartsFormatterFn(
  {
    1: Globalize("sv").numberFormatter({ raw: "0" }),
    2: Globalize("sv").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "januari",
          2: "februari",
          3: "mars",
          4: "april",
          5: "maj",
          6: "juni",
          7: "juli",
          8: "augusti",
          9: "september",
          10: "oktober",
          11: "november",
          12: "december",
        },
      },
    },
    dayPeriods: { am: "fm", pm: "em" },
  },
);
Globalize.b1993392453 = dateToPartsFormatterFn(
  {
    1: Globalize("th").numberFormatter({ raw: "0" }),
    2: Globalize("th").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "มกราคม",
          2: "กุมภาพันธ์",
          3: "มีนาคม",
          4: "เมษายน",
          5: "พฤษภาคม",
          6: "มิถุนายน",
          7: "กรกฎาคม",
          8: "สิงหาคม",
          9: "กันยายน",
          10: "ตุลาคม",
          11: "พฤศจิกายน",
          12: "ธันวาคม",
        },
      },
    },
    dayPeriods: { am: "ก่อนเที่ยง", pm: "หลังเที่ยง" },
  },
);
Globalize.b1290649403 = dateToPartsFormatterFn(
  {
    1: Globalize("tr").numberFormatter({ raw: "0" }),
    2: Globalize("tr").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM a h:mm",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "Ocak",
          2: "Şubat",
          3: "Mart",
          4: "Nisan",
          5: "Mayıs",
          6: "Haziran",
          7: "Temmuz",
          8: "Ağustos",
          9: "Eylül",
          10: "Ekim",
          11: "Kasım",
          12: "Aralık",
        },
      },
    },
    dayPeriods: { am: "ÖÖ", pm: "ÖS" },
  },
);
Globalize.a395933917 = dateToPartsFormatterFn(
  {
    1: Globalize("uk").numberFormatter({ raw: "0" }),
    2: Globalize("uk").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "d MMMM 'о' h:mm a",
    timeSeparator: ":",
    months: {
      M: {
        4: {
          1: "січня",
          2: "лютого",
          3: "березня",
          4: "квітня",
          5: "травня",
          6: "червня",
          7: "липня",
          8: "серпня",
          9: "вересня",
          10: "жовтня",
          11: "листопада",
          12: "грудня",
        },
      },
    },
    dayPeriods: { am: "дп", pm: "пп" },
  },
);
Globalize.a286405114 = dateToPartsFormatterFn(
  {
    1: Globalize("vi").numberFormatter({ raw: "0" }),
    2: Globalize("vi").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "h:mm a d MMMM",
    timeSeparator: ":",
    dayPeriods: { am: "SA", pm: "CH" },
    months: {
      M: {
        4: {
          1: "tháng 1",
          2: "tháng 2",
          3: "tháng 3",
          4: "tháng 4",
          5: "tháng 5",
          6: "tháng 6",
          7: "tháng 7",
          8: "tháng 8",
          9: "tháng 9",
          10: "tháng 10",
          11: "tháng 11",
          12: "tháng 12",
        },
      },
    },
  },
);
Globalize.b222114294 = dateToPartsFormatterFn(
  {
    1: Globalize("yue").numberFormatter({ raw: "0" }),
    2: Globalize("yue").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "M月d日 ah:mm",
    timeSeparator: ":",
    dayPeriods: { am: "上午", pm: "下午" },
  },
);
Globalize.b1939787106 = dateToPartsFormatterFn(
  {
    1: Globalize("zh-Hans").numberFormatter({ raw: "0" }),
    2: Globalize("zh-Hans").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "M月d日 ah:mm",
    timeSeparator: ":",
    dayPeriods: { am: "上午", pm: "下午" },
  },
);
Globalize.b621833333 = dateToPartsFormatterFn(
  {
    1: Globalize("zh-Hans-SG").numberFormatter({ raw: "0" }),
    2: Globalize("zh-Hans-SG").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "M月d日 ah:mm",
    timeSeparator: ":",
    dayPeriods: { am: "上午", pm: "下午" },
  },
);
Globalize.a277970847 = dateToPartsFormatterFn(
  {
    1: Globalize("zh-Hant").numberFormatter({ raw: "0" }),
    2: Globalize("zh-Hant").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "M月d日 ah:mm",
    timeSeparator: ":",
    dayPeriods: { am: "上午", pm: "下午" },
  },
);
Globalize.b936690535 = dateToPartsFormatterFn(
  {
    1: Globalize("zh-Hant-HK").numberFormatter({ raw: "0" }),
    2: Globalize("zh-Hant-HK").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "M月d日 ah:mm",
    timeSeparator: ":",
    dayPeriods: { am: "上午", pm: "下午" },
  },
);
Globalize.b500494280 = dateToPartsFormatterFn(
  {
    1: Globalize("zh-Hant-MO").numberFormatter({ raw: "0" }),
    2: Globalize("zh-Hant-MO").numberFormatter({ raw: "00" }),
  },
  {
    pattern: "M月d日 ah:mm",
    timeSeparator: ":",
    dayPeriods: { am: "上午", pm: "下午" },
  },
);
Globalize.a50097046 = dateFormatterFn(
  Globalize("ar-EG").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a62728514 = dateFormatterFn(
  Globalize("ar-SA").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b46681067 = dateFormatterFn(
  Globalize("bg").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b437307090 = dateFormatterFn(
  Globalize("ca").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a827630400 = dateFormatterFn(
  Globalize("cs").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b406287283 = dateFormatterFn(
  Globalize("da").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b125190063 = dateFormatterFn(
  Globalize("de").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1749733769 = dateFormatterFn(
  Globalize("el").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1609185159 = dateFormatterFn(
  Globalize("en").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a889670014 = dateFormatterFn(
  Globalize("es").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1187539329 = dateFormatterFn(
  Globalize("et").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a1030218624 = dateFormatterFn(
  Globalize("eu").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a217946771 = dateFormatterFn(
  Globalize("fi").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1297068132 = dateFormatterFn(
  Globalize("fr").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1687694155 = dateFormatterFn(
  Globalize("gl").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1110835 = dateFormatterFn(
  Globalize("he").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a279986385 = dateFormatterFn(
  Globalize("hi").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1235028518 = dateFormatterFn(
  Globalize("hr").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a1123278045 = dateFormatterFn(
  Globalize("hu").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a2107118315 = dateFormatterFn(
  Globalize("id").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1063460101 = dateFormatterFn(
  Globalize("it").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b220168441 = dateFormatterFn(
  Globalize("ja").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a513594416 = dateFormatterFn(
  Globalize("kk").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a794691636 = dateFormatterFn(
  Globalize("ko").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b970400680 = dateFormatterFn(
  Globalize("lt").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b829852070 = dateFormatterFn(
  Globalize("lv").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a1137828470 = dateFormatterFn(
  Globalize("ms").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a2121668740 = dateFormatterFn(
  Globalize("nb").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1470555506 = dateFormatterFn(
  Globalize("nl").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1408515892 = dateFormatterFn(
  Globalize("pl").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b846321452 = dateFormatterFn(
  Globalize("pt").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a454515013 = dateFormatterFn(
  Globalize("pt-PT").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a1011830285 = dateFormatterFn(
  Globalize("ro").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a1433476115 = dateFormatterFn(
  Globalize("ru").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a761752872 = dateFormatterFn(
  Globalize("sk").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1315456471 = dateFormatterFn(
  Globalize("sl").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1594777898 = dateFormatterFn(
  Globalize("sr-Cyrl").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b993751211 = dateFormatterFn(
  Globalize("sr-Latn").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b612713421 = dateFormatterFn(
  Globalize("sv").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1565533884 = dateFormatterFn(
  Globalize("th").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b862790834 = dateFormatterFn(
  Globalize("tr").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a823792486 = dateFormatterFn(
  Globalize("uk").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a714263683 = dateFormatterFn(
  Globalize("vi").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a156599457 = dateFormatterFn(
  Globalize("yue").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.a385402293 = dateFormatterFn(
  Globalize("zh-Hans").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b136997612 = dateFormatterFn(
  Globalize("zh-Hans-SG").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1691807050 = dateFormatterFn(
  Globalize("zh-Hant").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b451854814 = dateFormatterFn(
  Globalize("zh-Hant-HK").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b15658559 = dateFormatterFn(
  Globalize("zh-Hant-MO").dateToPartsFormatter({ skeleton: "MMMMdhm" }),
);
Globalize.b1129493863 = relativeTimeFormatterFn(
  Globalize("ar-EG").numberFormatter({}),
  Globalize("ar-EG").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-zero": "خلال {0} ساعة",
      "relativeTimePattern-count-one": "خلال ساعة واحدة",
      "relativeTimePattern-count-two": "خلال ساعتين",
      "relativeTimePattern-count-few": "خلال {0} ساعات",
      "relativeTimePattern-count-many": "خلال {0} ساعة",
      "relativeTimePattern-count-other": "خلال {0} ساعة",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-zero": "قبل {0} ساعة",
      "relativeTimePattern-count-one": "قبل ساعة واحدة",
      "relativeTimePattern-count-two": "قبل ساعتين",
      "relativeTimePattern-count-few": "قبل {0} ساعات",
      "relativeTimePattern-count-many": "قبل {0} ساعة",
      "relativeTimePattern-count-other": "قبل {0} ساعة",
    },
    "relative-type-0": "الساعة الحالية",
  },
);
Globalize.b318696087 = relativeTimeFormatterFn(
  Globalize("ar-EG").numberFormatter({}),
  Globalize("ar-EG").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-zero": "خلال {0} دقيقة",
      "relativeTimePattern-count-one": "خلال دقيقة واحدة",
      "relativeTimePattern-count-two": "خلال دقيقتين",
      "relativeTimePattern-count-few": "خلال {0} دقائق",
      "relativeTimePattern-count-many": "خلال {0} دقيقة",
      "relativeTimePattern-count-other": "خلال {0} دقيقة",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-zero": "قبل {0} دقيقة",
      "relativeTimePattern-count-one": "قبل دقيقة واحدة",
      "relativeTimePattern-count-two": "قبل دقيقتين",
      "relativeTimePattern-count-few": "قبل {0} دقائق",
      "relativeTimePattern-count-many": "قبل {0} دقيقة",
      "relativeTimePattern-count-other": "قبل {0} دقيقة",
    },
    "relative-type-0": "هذه الدقيقة",
  },
);
Globalize.b1716664979 = relativeTimeFormatterFn(
  Globalize("ar-SA").numberFormatter({}),
  Globalize("ar-SA").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-zero": "خلال {0} ساعة",
      "relativeTimePattern-count-one": "خلال ساعة واحدة",
      "relativeTimePattern-count-two": "خلال ساعتين",
      "relativeTimePattern-count-few": "خلال {0} ساعات",
      "relativeTimePattern-count-many": "خلال {0} ساعة",
      "relativeTimePattern-count-other": "خلال {0} ساعة",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-zero": "قبل {0} ساعة",
      "relativeTimePattern-count-one": "قبل ساعة واحدة",
      "relativeTimePattern-count-two": "قبل ساعتين",
      "relativeTimePattern-count-few": "قبل {0} ساعات",
      "relativeTimePattern-count-many": "قبل {0} ساعة",
      "relativeTimePattern-count-other": "قبل {0} ساعة",
    },
    "relative-type-0": "الساعة الحالية",
  },
);
Globalize.b1949422787 = relativeTimeFormatterFn(
  Globalize("ar-SA").numberFormatter({}),
  Globalize("ar-SA").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-zero": "خلال {0} دقيقة",
      "relativeTimePattern-count-one": "خلال دقيقة واحدة",
      "relativeTimePattern-count-two": "خلال دقيقتين",
      "relativeTimePattern-count-few": "خلال {0} دقائق",
      "relativeTimePattern-count-many": "خلال {0} دقيقة",
      "relativeTimePattern-count-other": "خلال {0} دقيقة",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-zero": "قبل {0} دقيقة",
      "relativeTimePattern-count-one": "قبل دقيقة واحدة",
      "relativeTimePattern-count-two": "قبل دقيقتين",
      "relativeTimePattern-count-few": "قبل {0} دقائق",
      "relativeTimePattern-count-many": "قبل {0} دقيقة",
      "relativeTimePattern-count-other": "قبل {0} دقيقة",
    },
    "relative-type-0": "هذه الدقيقة",
  },
);
Globalize.b1132152016 = relativeTimeFormatterFn(
  Globalize("bg").numberFormatter({}),
  Globalize("bg").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "след {0} час",
      "relativeTimePattern-count-other": "след {0} часа",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "преди {0} час",
      "relativeTimePattern-count-other": "преди {0} часа",
    },
    "relative-type-0": "в този час",
  },
);
Globalize.a1421786176 = relativeTimeFormatterFn(
  Globalize("bg").numberFormatter({}),
  Globalize("bg").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "след {0} минута",
      "relativeTimePattern-count-other": "след {0} минути",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "преди {0} минута",
      "relativeTimePattern-count-other": "преди {0} минути",
    },
    "relative-type-0": "в тази минута",
  },
);
Globalize.a2094915959 = relativeTimeFormatterFn(
  Globalize("ca").numberFormatter({}),
  Globalize("ca").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "d’aquí a {0} hora",
      "relativeTimePattern-count-other": "d’aquí a {0} hores",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "fa {0} hora",
      "relativeTimePattern-count-other": "fa {0} hores",
    },
    "relative-type-0": "aquesta hora",
  },
);
Globalize.a1667722439 = relativeTimeFormatterFn(
  Globalize("ca").numberFormatter({}),
  Globalize("ca").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "d’aquí a {0} minut",
      "relativeTimePattern-count-other": "d’aquí a {0} minuts",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "fa {0} minut",
      "relativeTimePattern-count-other": "fa {0} minuts",
    },
    "relative-type-0": "aquest minut",
  },
);
Globalize.a123437605 = relativeTimeFormatterFn(
  Globalize("cs").numberFormatter({}),
  Globalize("cs").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "za {0} hodinu",
      "relativeTimePattern-count-few": "za {0} hodiny",
      "relativeTimePattern-count-many": "za {0} hodiny",
      "relativeTimePattern-count-other": "za {0} hodin",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "před {0} hodinou",
      "relativeTimePattern-count-few": "před {0} hodinami",
      "relativeTimePattern-count-many": "před {0} hodiny",
      "relativeTimePattern-count-other": "před {0} hodinami",
    },
    "relative-type-0": "tuto hodinu",
  },
);
Globalize.a1157601781 = relativeTimeFormatterFn(
  Globalize("cs").numberFormatter({}),
  Globalize("cs").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "za {0} minutu",
      "relativeTimePattern-count-few": "za {0} minuty",
      "relativeTimePattern-count-many": "za {0} minuty",
      "relativeTimePattern-count-other": "za {0} minut",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "před {0} minutou",
      "relativeTimePattern-count-few": "před {0} minutami",
      "relativeTimePattern-count-many": "před {0} minuty",
      "relativeTimePattern-count-other": "před {0} minutami",
    },
    "relative-type-0": "tuto minutu",
  },
);
Globalize.a1801512952 = relativeTimeFormatterFn(
  Globalize("da").numberFormatter({}),
  Globalize("da").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "om {0} time",
      "relativeTimePattern-count-other": "om {0} timer",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "for {0} time siden",
      "relativeTimePattern-count-other": "for {0} timer siden",
    },
    "relative-type-0": "i den kommende time",
  },
);
Globalize.b1119693048 = relativeTimeFormatterFn(
  Globalize("da").numberFormatter({}),
  Globalize("da").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "om {0} minut",
      "relativeTimePattern-count-other": "om {0} minutter",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "for {0} minut siden",
      "relativeTimePattern-count-other": "for {0} minutter siden",
    },
    "relative-type-0": "i det kommende minut",
  },
);
Globalize.b1977123468 = relativeTimeFormatterFn(
  Globalize("de").numberFormatter({}),
  Globalize("de").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "in {0} Stunde",
      "relativeTimePattern-count-other": "in {0} Stunden",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "vor {0} Stunde",
      "relativeTimePattern-count-other": "vor {0} Stunden",
    },
    "relative-type-0": "in dieser Stunde",
  },
);
Globalize.a1153039748 = relativeTimeFormatterFn(
  Globalize("de").numberFormatter({}),
  Globalize("de").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "in {0} Minute",
      "relativeTimePattern-count-other": "in {0} Minuten",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "vor {0} Minute",
      "relativeTimePattern-count-other": "vor {0} Minuten",
    },
    "relative-type-0": "in dieser Minute",
  },
);
Globalize.b1366947442 = relativeTimeFormatterFn(
  Globalize("el").numberFormatter({}),
  Globalize("el").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "σε {0} ώρα",
      "relativeTimePattern-count-other": "σε {0} ώρες",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "πριν από {0} ώρα",
      "relativeTimePattern-count-other": "πριν από {0} ώρες",
    },
    "relative-type-0": "τρέχουσα ώρα",
  },
);
Globalize.b878318818 = relativeTimeFormatterFn(
  Globalize("el").numberFormatter({}),
  Globalize("el").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "σε {0} λεπτό",
      "relativeTimePattern-count-other": "σε {0} λεπτά",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "πριν από {0} λεπτό",
      "relativeTimePattern-count-other": "πριν από {0} λεπτά",
    },
    "relative-type-0": "τρέχον λεπτό",
  },
);
Globalize.b1108782004 = relativeTimeFormatterFn(
  Globalize("en").numberFormatter({}),
  Globalize("en").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "in {0} hour",
      "relativeTimePattern-count-other": "in {0} hours",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} hour ago",
      "relativeTimePattern-count-other": "{0} hours ago",
    },
    "relative-type-0": "this hour",
  },
);
Globalize.b1889436068 = relativeTimeFormatterFn(
  Globalize("en").numberFormatter({}),
  Globalize("en").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "in {0} minute",
      "relativeTimePattern-count-other": "in {0} minutes",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} minute ago",
      "relativeTimePattern-count-other": "{0} minutes ago",
    },
    "relative-type-0": "this minute",
  },
);
Globalize.b463368409 = relativeTimeFormatterFn(
  Globalize("es").numberFormatter({}),
  Globalize("es").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "dentro de {0} hora",
      "relativeTimePattern-count-other": "dentro de {0} horas",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "hace {0} hora",
      "relativeTimePattern-count-other": "hace {0} horas",
    },
    "relative-type-0": "esta hora",
  },
);
Globalize.b122261897 = relativeTimeFormatterFn(
  Globalize("es").numberFormatter({}),
  Globalize("es").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "dentro de {0} minuto",
      "relativeTimePattern-count-other": "dentro de {0} minutos",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "hace {0} minuto",
      "relativeTimePattern-count-other": "hace {0} minutos",
    },
    "relative-type-0": "este minuto",
  },
);
Globalize.b334285690 = relativeTimeFormatterFn(
  Globalize("et").numberFormatter({}),
  Globalize("et").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} tunni pärast",
      "relativeTimePattern-count-other": "{0} tunni pärast",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} tunni eest",
      "relativeTimePattern-count-other": "{0} tunni eest",
    },
    "relative-type-0": "praegusel tunnil",
  },
);
Globalize.b627820522 = relativeTimeFormatterFn(
  Globalize("et").numberFormatter({}),
  Globalize("et").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} minuti pärast",
      "relativeTimePattern-count-other": "{0} minuti pärast",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} minuti eest",
      "relativeTimePattern-count-other": "{0} minuti eest",
    },
    "relative-type-0": "praegusel minutil",
  },
);
Globalize.b205202971 = relativeTimeFormatterFn(
  Globalize("eu").numberFormatter({}),
  Globalize("eu").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} ordu barru",
      "relativeTimePattern-count-other": "{0} ordu barru",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "Duela {0} ordu",
      "relativeTimePattern-count-other": "Duela {0} ordu",
    },
    "relative-type-0": "ordu honetan",
  },
);
Globalize.b1133379147 = relativeTimeFormatterFn(
  Globalize("eu").numberFormatter({}),
  Globalize("eu").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} minutu barru",
      "relativeTimePattern-count-other": "{0} minutu barru",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "Duela {0} minutu",
      "relativeTimePattern-count-other": "Duela {0} minutu",
    },
    "relative-type-0": "minutu honetan",
  },
);
Globalize.b2047598606 = relativeTimeFormatterFn(
  Globalize("fi").numberFormatter({}),
  Globalize("fi").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} tunnin päästä",
      "relativeTimePattern-count-other": "{0} tunnin päästä",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} tunti sitten",
      "relativeTimePattern-count-other": "{0} tuntia sitten",
    },
    "relative-type-0": "tämän tunnin aikana",
  },
);
Globalize.a2145908866 = relativeTimeFormatterFn(
  Globalize("fi").numberFormatter({}),
  Globalize("fi").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} minuutin päästä",
      "relativeTimePattern-count-other": "{0} minuutin päästä",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} minuutti sitten",
      "relativeTimePattern-count-other": "{0} minuuttia sitten",
    },
    "relative-type-0": "tämän minuutin aikana",
  },
);
Globalize.b885854135 = relativeTimeFormatterFn(
  Globalize("fr").numberFormatter({}),
  Globalize("fr").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "dans {0} heure",
      "relativeTimePattern-count-other": "dans {0} heures",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "il y a {0} heure",
      "relativeTimePattern-count-other": "il y a {0} heures",
    },
    "relative-type-0": "cette heure-ci",
  },
);
Globalize.a1890848537 = relativeTimeFormatterFn(
  Globalize("fr").numberFormatter({}),
  Globalize("fr").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "dans {0} minute",
      "relativeTimePattern-count-other": "dans {0} minutes",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "il y a {0} minute",
      "relativeTimePattern-count-other": "il y a {0} minutes",
    },
    "relative-type-0": "cette minute-ci",
  },
);
Globalize.b1953753456 = relativeTimeFormatterFn(
  Globalize("gl").numberFormatter({}),
  Globalize("gl").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "en {0} hora",
      "relativeTimePattern-count-other": "en {0} horas",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "hai {0} hora",
      "relativeTimePattern-count-other": "hai {0} horas",
    },
    "relative-type-0": "esta hora",
  },
);
Globalize.a2136784800 = relativeTimeFormatterFn(
  Globalize("gl").numberFormatter({}),
  Globalize("gl").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "en {0} minuto",
      "relativeTimePattern-count-other": "en {0} minutos",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "hai {0} minuto",
      "relativeTimePattern-count-other": "hai {0} minutos",
    },
    "relative-type-0": "este minuto",
  },
);
Globalize.a1144231800 = relativeTimeFormatterFn(
  Globalize("he").numberFormatter({}),
  Globalize("he").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "בעוד שעה",
      "relativeTimePattern-count-two": "בעוד שעתיים",
      "relativeTimePattern-count-many": "בעוד {0} שעות",
      "relativeTimePattern-count-other": "בעוד {0} שעות",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "לפני שעה",
      "relativeTimePattern-count-two": "לפני שעתיים",
      "relativeTimePattern-count-many": "לפני {0} שעות",
      "relativeTimePattern-count-other": "לפני {0} שעות",
    },
    "relative-type-0": "בשעה זו",
  },
);
Globalize.b1406687608 = relativeTimeFormatterFn(
  Globalize("he").numberFormatter({}),
  Globalize("he").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "בעוד דקה",
      "relativeTimePattern-count-two": "בעוד שתי דקות",
      "relativeTimePattern-count-many": "בעוד {0} דקות",
      "relativeTimePattern-count-other": "בעוד {0} דקות",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "לפני דקה",
      "relativeTimePattern-count-two": "לפני שתי דקות",
      "relativeTimePattern-count-many": "לפני {0} דקות",
      "relativeTimePattern-count-other": "לפני {0} דקות",
    },
    "relative-type-0": "בדקה זו",
  },
);
Globalize.a1660562676 = relativeTimeFormatterFn(
  Globalize("hi").numberFormatter({}),
  Globalize("hi").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} घंटे में",
      "relativeTimePattern-count-other": "{0} घंटे में",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} घंटे पहले",
      "relativeTimePattern-count-other": "{0} घंटे पहले",
    },
    "relative-type-0": "यह घंटा",
  },
);
Globalize.a866045188 = relativeTimeFormatterFn(
  Globalize("hi").numberFormatter({}),
  Globalize("hi").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} मिनट में",
      "relativeTimePattern-count-other": "{0} मिनट में",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} मिनट पहले",
      "relativeTimePattern-count-other": "{0} मिनट पहले",
    },
    "relative-type-0": "यह मिनट",
  },
);
Globalize.b1472660149 = relativeTimeFormatterFn(
  Globalize("hr").numberFormatter({}),
  Globalize("hr").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "za {0} sat",
      "relativeTimePattern-count-few": "za {0} sata",
      "relativeTimePattern-count-other": "za {0} sati",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "prije {0} sat",
      "relativeTimePattern-count-few": "prije {0} sata",
      "relativeTimePattern-count-other": "prije {0} sati",
    },
    "relative-type-0": "ovaj sat",
  },
);
Globalize.a610984859 = relativeTimeFormatterFn(
  Globalize("hr").numberFormatter({}),
  Globalize("hr").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "za {0} minutu",
      "relativeTimePattern-count-few": "za {0} minute",
      "relativeTimePattern-count-other": "za {0} minuta",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "prije {0} minutu",
      "relativeTimePattern-count-few": "prije {0} minute",
      "relativeTimePattern-count-other": "prije {0} minuta",
    },
    "relative-type-0": "ova minuta",
  },
);
Globalize.b1085411992 = relativeTimeFormatterFn(
  Globalize("hu").numberFormatter({}),
  Globalize("hu").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} óra múlva",
      "relativeTimePattern-count-other": "{0} óra múlva",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} órával ezelőtt",
      "relativeTimePattern-count-other": "{0} órával ezelőtt",
    },
    "relative-type-0": "ebben az órában",
  },
);
Globalize.b905691016 = relativeTimeFormatterFn(
  Globalize("hu").numberFormatter({}),
  Globalize("hu").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} perc múlva",
      "relativeTimePattern-count-other": "{0} perc múlva",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} perccel ezelőtt",
      "relativeTimePattern-count-other": "{0} perccel ezelőtt",
    },
    "relative-type-0": "ebben a percben",
  },
);
Globalize.a721746074 = relativeTimeFormatterFn(
  Globalize("id").numberFormatter({}),
  Globalize("id").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "dalam {0} jam",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} jam yang lalu",
    },
    "relative-type-0": "jam ini",
  },
);
Globalize.a606422826 = relativeTimeFormatterFn(
  Globalize("id").numberFormatter({}),
  Globalize("id").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "dalam {0} menit",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} menit yang lalu",
    },
    "relative-type-0": "menit ini",
  },
);
Globalize.b1507897718 = relativeTimeFormatterFn(
  Globalize("it").numberFormatter({}),
  Globalize("it").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "tra {0} ora",
      "relativeTimePattern-count-other": "tra {0} ore",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} ora fa",
      "relativeTimePattern-count-other": "{0} ore fa",
    },
    "relative-type-0": "quest’ora",
  },
);
Globalize.a1107419418 = relativeTimeFormatterFn(
  Globalize("it").numberFormatter({}),
  Globalize("it").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "tra {0} minuto",
      "relativeTimePattern-count-other": "tra {0} minuti",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} minuto fa",
      "relativeTimePattern-count-other": "{0} minuti fa",
    },
    "relative-type-0": "questo minuto",
  },
);
Globalize.a41094910 = relativeTimeFormatterFn(
  Globalize("ja").numberFormatter({}),
  Globalize("ja").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0} 時間後",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} 時間前",
    },
    "relative-type-0": "1 時間以内",
  },
);
Globalize.b664316786 = relativeTimeFormatterFn(
  Globalize("ja").numberFormatter({}),
  Globalize("ja").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0} 分後",
    },
    "relativeTime-type-past": { "relativeTimePattern-count-other": "{0} 分前" },
    "relative-type-0": "1 分以内",
  },
);
Globalize.a1038519093 = relativeTimeFormatterFn(
  Globalize("kk").numberFormatter({}),
  Globalize("kk").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} сағаттан кейін",
      "relativeTimePattern-count-other": "{0} сағаттан кейін",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} сағат бұрын",
      "relativeTimePattern-count-other": "{0} сағат бұрын",
    },
    "relative-type-0": "осы сағат",
  },
);
Globalize.a82616069 = relativeTimeFormatterFn(
  Globalize("kk").numberFormatter({}),
  Globalize("kk").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} минуттан кейін",
      "relativeTimePattern-count-other": "{0} минуттан кейін",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} минут бұрын",
      "relativeTimePattern-count-other": "{0} минут бұрын",
    },
    "relative-type-0": "осы минут",
  },
);
Globalize.a1554849969 = relativeTimeFormatterFn(
  Globalize("ko").numberFormatter({}),
  Globalize("ko").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0}시간 후",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0}시간 전",
    },
    "relative-type-0": "현재 시간",
  },
);
Globalize.b1939618431 = relativeTimeFormatterFn(
  Globalize("ko").numberFormatter({}),
  Globalize("ko").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0}분 후",
    },
    "relativeTime-type-past": { "relativeTimePattern-count-other": "{0}분 전" },
    "relative-type-0": "현재 분",
  },
);
Globalize.a1906860557 = relativeTimeFormatterFn(
  Globalize("lt").numberFormatter({}),
  Globalize("lt").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "po {0} valandos",
      "relativeTimePattern-count-few": "po {0} valandų",
      "relativeTimePattern-count-many": "po {0} valandos",
      "relativeTimePattern-count-other": "po {0} valandų",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "prieš {0} valandą",
      "relativeTimePattern-count-few": "prieš {0} valandas",
      "relativeTimePattern-count-many": "prieš {0} valandos",
      "relativeTimePattern-count-other": "prieš {0} valandų",
    },
    "relative-type-0": "šią valandą",
  },
);
Globalize.a1335107549 = relativeTimeFormatterFn(
  Globalize("lt").numberFormatter({}),
  Globalize("lt").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "po {0} minutės",
      "relativeTimePattern-count-few": "po {0} minučių",
      "relativeTimePattern-count-many": "po {0} minutės",
      "relativeTimePattern-count-other": "po {0} minučių",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "prieš {0} minutę",
      "relativeTimePattern-count-few": "prieš {0} minutes",
      "relativeTimePattern-count-many": "prieš {0} minutės",
      "relativeTimePattern-count-other": "prieš {0} minučių",
    },
    "relative-type-0": "šią minutę",
  },
);
Globalize.b2129941301 = relativeTimeFormatterFn(
  Globalize("lv").numberFormatter({}),
  Globalize("lv").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-zero": "pēc {0} stundām",
      "relativeTimePattern-count-one": "pēc {0} stundas",
      "relativeTimePattern-count-other": "pēc {0} stundām",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-zero": "pirms {0} stundām",
      "relativeTimePattern-count-one": "pirms {0} stundas",
      "relativeTimePattern-count-other": "pirms {0} stundām",
    },
    "relative-type-0": "šajā stundā",
  },
);
Globalize.a323990299 = relativeTimeFormatterFn(
  Globalize("lv").numberFormatter({}),
  Globalize("lv").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-zero": "pēc {0} minūtēm",
      "relativeTimePattern-count-one": "pēc {0} minūtes",
      "relativeTimePattern-count-other": "pēc {0} minūtēm",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-zero": "pirms {0} minūtēm",
      "relativeTimePattern-count-one": "pirms {0} minūtes",
      "relativeTimePattern-count-other": "pirms {0} minūtēm",
    },
    "relative-type-0": "šajā minūtē",
  },
);
Globalize.a1484374831 = relativeTimeFormatterFn(
  Globalize("ms").numberFormatter({}),
  Globalize("ms").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "dalam {0} jam",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} jam lalu",
    },
    "relative-type-0": "jam ini",
  },
);
Globalize.b946749313 = relativeTimeFormatterFn(
  Globalize("ms").numberFormatter({}),
  Globalize("ms").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "dalam {0} minit",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} minit lalu",
    },
    "relative-type-0": "pada minit ini",
  },
);
Globalize.b1003434399 = relativeTimeFormatterFn(
  Globalize("nb").numberFormatter({}),
  Globalize("nb").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "om {0} time",
      "relativeTimePattern-count-other": "om {0} timer",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "for {0} time siden",
      "relativeTimePattern-count-other": "for {0} timer siden",
    },
    "relative-type-0": "denne timen",
  },
);
Globalize.a565364529 = relativeTimeFormatterFn(
  Globalize("nb").numberFormatter({}),
  Globalize("nb").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "om {0} minutt",
      "relativeTimePattern-count-other": "om {0} minutter",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "for {0} minutt siden",
      "relativeTimePattern-count-other": "for {0} minutter siden",
    },
    "relative-type-0": "dette minuttet",
  },
);
Globalize.a287392791 = relativeTimeFormatterFn(
  Globalize("nl").numberFormatter({}),
  Globalize("nl").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "over {0} uur",
      "relativeTimePattern-count-other": "over {0} uur",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} uur geleden",
      "relativeTimePattern-count-other": "{0} uur geleden",
    },
    "relative-type-0": "binnen een uur",
  },
);
Globalize.b195254425 = relativeTimeFormatterFn(
  Globalize("nl").numberFormatter({}),
  Globalize("nl").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "over {0} minuut",
      "relativeTimePattern-count-other": "over {0} minuten",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} minuut geleden",
      "relativeTimePattern-count-other": "{0} minuten geleden",
    },
    "relative-type-0": "binnen een minuut",
  },
);
Globalize.b299413223 = relativeTimeFormatterFn(
  Globalize("pl").numberFormatter({}),
  Globalize("pl").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "za {0} godzinę",
      "relativeTimePattern-count-few": "za {0} godziny",
      "relativeTimePattern-count-many": "za {0} godzin",
      "relativeTimePattern-count-other": "za {0} godziny",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} godzinę temu",
      "relativeTimePattern-count-few": "{0} godziny temu",
      "relativeTimePattern-count-many": "{0} godzin temu",
      "relativeTimePattern-count-other": "{0} godziny temu",
    },
    "relative-type-0": "ta godzina",
  },
);
Globalize.b1475118103 = relativeTimeFormatterFn(
  Globalize("pl").numberFormatter({}),
  Globalize("pl").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "za {0} minutę",
      "relativeTimePattern-count-few": "za {0} minuty",
      "relativeTimePattern-count-many": "za {0} minut",
      "relativeTimePattern-count-other": "za {0} minuty",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} minutę temu",
      "relativeTimePattern-count-few": "{0} minuty temu",
      "relativeTimePattern-count-many": "{0} minut temu",
      "relativeTimePattern-count-other": "{0} minuty temu",
    },
    "relative-type-0": "ta minuta",
  },
);
Globalize.a733248529 = relativeTimeFormatterFn(
  Globalize("pt").numberFormatter({}),
  Globalize("pt").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "em {0} hora",
      "relativeTimePattern-count-other": "em {0} horas",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "há {0} hora",
      "relativeTimePattern-count-other": "há {0} horas",
    },
    "relative-type-0": "esta hora",
  },
);
Globalize.b1224619807 = relativeTimeFormatterFn(
  Globalize("pt").numberFormatter({}),
  Globalize("pt").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "em {0} minuto",
      "relativeTimePattern-count-other": "em {0} minutos",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "há {0} minuto",
      "relativeTimePattern-count-other": "há {0} minutos",
    },
    "relative-type-0": "este minuto",
  },
);
Globalize.a1268173962 = relativeTimeFormatterFn(
  Globalize("pt-PT").numberFormatter({}),
  Globalize("pt-PT").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "dentro de {0} hora",
      "relativeTimePattern-count-other": "dentro de {0} horas",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "há {0} hora",
      "relativeTimePattern-count-other": "há {0} horas",
    },
    "relative-type-0": "esta hora",
  },
);
Globalize.a1737613082 = relativeTimeFormatterFn(
  Globalize("pt-PT").numberFormatter({}),
  Globalize("pt-PT").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "dentro de {0} minuto",
      "relativeTimePattern-count-other": "dentro de {0} minutos",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "há {0} minuto",
      "relativeTimePattern-count-other": "há {0} minutos",
    },
    "relative-type-0": "este minuto",
  },
);
Globalize.b498971080 = relativeTimeFormatterFn(
  Globalize("ro").numberFormatter({}),
  Globalize("ro").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "peste {0} oră",
      "relativeTimePattern-count-few": "peste {0} ore",
      "relativeTimePattern-count-other": "peste {0} de ore",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "acum {0} oră",
      "relativeTimePattern-count-few": "acum {0} ore",
      "relativeTimePattern-count-other": "acum {0} de ore",
    },
    "relative-type-0": "ora aceasta",
  },
);
Globalize.a23309640 = relativeTimeFormatterFn(
  Globalize("ro").numberFormatter({}),
  Globalize("ro").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "peste {0} minut",
      "relativeTimePattern-count-few": "peste {0} minute",
      "relativeTimePattern-count-other": "peste {0} de minute",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "acum {0} minut",
      "relativeTimePattern-count-few": "acum {0} minute",
      "relativeTimePattern-count-other": "acum {0} de minute",
    },
    "relative-type-0": "minutul acesta",
  },
);
Globalize.a275525234 = relativeTimeFormatterFn(
  Globalize("ru").numberFormatter({}),
  Globalize("ru").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "через {0} час",
      "relativeTimePattern-count-few": "через {0} часа",
      "relativeTimePattern-count-many": "через {0} часов",
      "relativeTimePattern-count-other": "через {0} часа",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} час назад",
      "relativeTimePattern-count-few": "{0} часа назад",
      "relativeTimePattern-count-many": "{0} часов назад",
      "relativeTimePattern-count-other": "{0} часа назад",
    },
    "relative-type-0": "в этот час",
  },
);
Globalize.a1284925186 = relativeTimeFormatterFn(
  Globalize("ru").numberFormatter({}),
  Globalize("ru").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "через {0} минуту",
      "relativeTimePattern-count-few": "через {0} минуты",
      "relativeTimePattern-count-many": "через {0} минут",
      "relativeTimePattern-count-other": "через {0} минуты",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} минуту назад",
      "relativeTimePattern-count-few": "{0} минуты назад",
      "relativeTimePattern-count-many": "{0} минут назад",
      "relativeTimePattern-count-other": "{0} минуты назад",
    },
    "relative-type-0": "в эту минуту",
  },
);
Globalize.b1308704963 = relativeTimeFormatterFn(
  Globalize("sk").numberFormatter({}),
  Globalize("sk").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "o {0} hodinu",
      "relativeTimePattern-count-few": "o {0} hodiny",
      "relativeTimePattern-count-many": "o {0} hodiny",
      "relativeTimePattern-count-other": "o {0} hodín",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "pred {0} hodinou",
      "relativeTimePattern-count-few": "pred {0} hodinami",
      "relativeTimePattern-count-many": "pred {0} hodinou",
      "relativeTimePattern-count-other": "pred {0} hodinami",
    },
    "relative-type-0": "v tejto hodine",
  },
);
Globalize.b741871347 = relativeTimeFormatterFn(
  Globalize("sk").numberFormatter({}),
  Globalize("sk").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "o {0} minútu",
      "relativeTimePattern-count-few": "o {0} minúty",
      "relativeTimePattern-count-many": "o {0} minúty",
      "relativeTimePattern-count-other": "o {0} minút",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "pred {0} minútou",
      "relativeTimePattern-count-few": "pred {0} minútami",
      "relativeTimePattern-count-many": "pred {0} minúty",
      "relativeTimePattern-count-other": "pred {0} minútami",
    },
    "relative-type-0": "v tejto minúte",
  },
);
Globalize.b1179622244 = relativeTimeFormatterFn(
  Globalize("sl").numberFormatter({}),
  Globalize("sl").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "čez {0} uro",
      "relativeTimePattern-count-two": "čez {0} uri",
      "relativeTimePattern-count-few": "čez {0} ure",
      "relativeTimePattern-count-other": "čez {0} ur",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "pred {0} uro",
      "relativeTimePattern-count-two": "pred {0} urama",
      "relativeTimePattern-count-few": "pred {0} urami",
      "relativeTimePattern-count-other": "pred {0} urami",
    },
    "relative-type-0": "v tej uri",
  },
);
Globalize.b1247429972 = relativeTimeFormatterFn(
  Globalize("sl").numberFormatter({}),
  Globalize("sl").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "čez {0} minuto",
      "relativeTimePattern-count-two": "čez {0} minuti",
      "relativeTimePattern-count-few": "čez {0} minute",
      "relativeTimePattern-count-other": "čez {0} minut",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "pred {0} minuto",
      "relativeTimePattern-count-two": "pred {0} minutama",
      "relativeTimePattern-count-few": "pred {0} minutami",
      "relativeTimePattern-count-other": "pred {0} minutami",
    },
    "relative-type-0": "to minuto",
  },
);
Globalize.b105017831 = relativeTimeFormatterFn(
  Globalize("sr-Cyrl").numberFormatter({}),
  Globalize("sr-Cyrl").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "за {0} сат",
      "relativeTimePattern-count-few": "за {0} сата",
      "relativeTimePattern-count-other": "за {0} сати",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "пре {0} сата",
      "relativeTimePattern-count-few": "пре {0} сата",
      "relativeTimePattern-count-other": "пре {0} сати",
    },
    "relative-type-0": "овог сата",
  },
);
Globalize.a655259881 = relativeTimeFormatterFn(
  Globalize("sr-Cyrl").numberFormatter({}),
  Globalize("sr-Cyrl").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "за {0} минут",
      "relativeTimePattern-count-few": "за {0} минута",
      "relativeTimePattern-count-other": "за {0} минута",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "пре {0} минута",
      "relativeTimePattern-count-few": "пре {0} минута",
      "relativeTimePattern-count-other": "пре {0} минута",
    },
    "relative-type-0": "овог минута",
  },
);
Globalize.b502088902 = relativeTimeFormatterFn(
  Globalize("sr-Latn").numberFormatter({}),
  Globalize("sr-Latn").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "za {0} sat",
      "relativeTimePattern-count-few": "za {0} sata",
      "relativeTimePattern-count-other": "za {0} sati",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "pre {0} sata",
      "relativeTimePattern-count-few": "pre {0} sata",
      "relativeTimePattern-count-other": "pre {0} sati",
    },
    "relative-type-0": "ovog sata",
  },
);
Globalize.a1322049994 = relativeTimeFormatterFn(
  Globalize("sr-Latn").numberFormatter({}),
  Globalize("sr-Latn").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "za {0} minut",
      "relativeTimePattern-count-few": "za {0} minuta",
      "relativeTimePattern-count-other": "za {0} minuta",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "pre {0} minuta",
      "relativeTimePattern-count-few": "pre {0} minuta",
      "relativeTimePattern-count-other": "pre {0} minuta",
    },
    "relative-type-0": "ovog minuta",
  },
);
Globalize.a111204946 = relativeTimeFormatterFn(
  Globalize("sv").numberFormatter({}),
  Globalize("sv").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "om {0} timme",
      "relativeTimePattern-count-other": "om {0} timmar",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "för {0} timme sedan",
      "relativeTimePattern-count-other": "för {0} timmar sedan",
    },
    "relative-type-0": "denna timme",
  },
);
Globalize.b2008048926 = relativeTimeFormatterFn(
  Globalize("sv").numberFormatter({}),
  Globalize("sv").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "om {0} minut",
      "relativeTimePattern-count-other": "om {0} minuter",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "för {0} minut sedan",
      "relativeTimePattern-count-other": "för {0} minuter sedan",
    },
    "relative-type-0": "denna minut",
  },
);
Globalize.b1989356127 = relativeTimeFormatterFn(
  Globalize("th").numberFormatter({}),
  Globalize("th").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "ในอีก {0} ชั่วโมง",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} ชั่วโมงที่ผ่านมา",
    },
    "relative-type-0": "ชั่วโมงนี้",
  },
);
Globalize.b2012610959 = relativeTimeFormatterFn(
  Globalize("th").numberFormatter({}),
  Globalize("th").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "ในอีก {0} นาที",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} นาทีที่ผ่านมา",
    },
    "relative-type-0": "นาทีนี้",
  },
);
Globalize.b698528937 = relativeTimeFormatterFn(
  Globalize("tr").numberFormatter({}),
  Globalize("tr").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} saat sonra",
      "relativeTimePattern-count-other": "{0} saat sonra",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} saat önce",
      "relativeTimePattern-count-other": "{0} saat önce",
    },
    "relative-type-0": "bu saat",
  },
);
Globalize.a1521737383 = relativeTimeFormatterFn(
  Globalize("tr").numberFormatter({}),
  Globalize("tr").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "{0} dakika sonra",
      "relativeTimePattern-count-other": "{0} dakika sonra",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} dakika önce",
      "relativeTimePattern-count-other": "{0} dakika önce",
    },
    "relative-type-0": "bu dakika",
  },
);
Globalize.b1895510977 = relativeTimeFormatterFn(
  Globalize("uk").numberFormatter({}),
  Globalize("uk").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "через {0} годину",
      "relativeTimePattern-count-few": "через {0} години",
      "relativeTimePattern-count-many": "через {0} годин",
      "relativeTimePattern-count-other": "через {0} години",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} годину тому",
      "relativeTimePattern-count-few": "{0} години тому",
      "relativeTimePattern-count-many": "{0} годин тому",
      "relativeTimePattern-count-other": "{0} години тому",
    },
    "relative-type-0": "цієї години",
  },
);
Globalize.b2021735025 = relativeTimeFormatterFn(
  Globalize("uk").numberFormatter({}),
  Globalize("uk").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-one": "через {0} хвилину",
      "relativeTimePattern-count-few": "через {0} хвилини",
      "relativeTimePattern-count-many": "через {0} хвилин",
      "relativeTimePattern-count-other": "через {0} хвилини",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-one": "{0} хвилину тому",
      "relativeTimePattern-count-few": "{0} хвилини тому",
      "relativeTimePattern-count-many": "{0} хвилин тому",
      "relativeTimePattern-count-other": "{0} хвилини тому",
    },
    "relative-type-0": "цієї хвилини",
  },
);
Globalize.a1847887874 = relativeTimeFormatterFn(
  Globalize("vi").numberFormatter({}),
  Globalize("vi").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "sau {0} giờ nữa",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} giờ trước",
    },
    "relative-type-0": "giờ này",
  },
);
Globalize.a496934034 = relativeTimeFormatterFn(
  Globalize("vi").numberFormatter({}),
  Globalize("vi").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "sau {0} phút nữa",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} phút trước",
    },
    "relative-type-0": "phút này",
  },
);
Globalize.a2068901102 = relativeTimeFormatterFn(
  Globalize("yue").numberFormatter({}),
  Globalize("yue").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0} 小時後",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} 小時前",
    },
    "relative-type-0": "呢個小時",
  },
);
Globalize.b1857718658 = relativeTimeFormatterFn(
  Globalize("yue").numberFormatter({}),
  Globalize("yue").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0} 分鐘後",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} 分鐘前",
    },
    "relative-type-0": "呢分鐘",
  },
);
Globalize.a1130735834 = relativeTimeFormatterFn(
  Globalize("zh-Hans").numberFormatter({}),
  Globalize("zh-Hans").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0}小时后",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0}小时前",
    },
    "relative-type-0": "这一时间 / 此时",
  },
);
Globalize.b1491409046 = relativeTimeFormatterFn(
  Globalize("zh-Hans").numberFormatter({}),
  Globalize("zh-Hans").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0}分钟后",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0}分钟前",
    },
    "relative-type-0": "此刻",
  },
);
Globalize.b970642223 = relativeTimeFormatterFn(
  Globalize("zh-Hans-SG").numberFormatter({}),
  Globalize("zh-Hans-SG").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0}小时后",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0}小时前",
    },
    "relative-type-0": "这一时间 / 此时",
  },
);
Globalize.a2013874593 = relativeTimeFormatterFn(
  Globalize("zh-Hans-SG").numberFormatter({}),
  Globalize("zh-Hans-SG").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0}分钟后",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0}分钟前",
    },
    "relative-type-0": "此刻",
  },
);
Globalize.a1259818553 = relativeTimeFormatterFn(
  Globalize("zh-Hant").numberFormatter({}),
  Globalize("zh-Hant").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0} 小時後",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} 小時前",
    },
    "relative-type-0": "這一小時",
  },
);
Globalize.b1996967671 = relativeTimeFormatterFn(
  Globalize("zh-Hant").numberFormatter({}),
  Globalize("zh-Hant").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0} 分鐘後",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} 分鐘前",
    },
    "relative-type-0": "這一分鐘",
  },
);
Globalize.b14293757 = relativeTimeFormatterFn(
  Globalize("zh-Hant-HK").numberFormatter({}),
  Globalize("zh-Hant-HK").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0} 小時後",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} 小時前",
    },
    "relative-type-0": "這個小時",
  },
);
Globalize.a1941749075 = relativeTimeFormatterFn(
  Globalize("zh-Hant-HK").numberFormatter({}),
  Globalize("zh-Hant-HK").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0} 分鐘後",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} 分鐘前",
    },
    "relative-type-0": "這分鐘",
  },
);
Globalize.b964977916 = relativeTimeFormatterFn(
  Globalize("zh-Hant-MO").numberFormatter({}),
  Globalize("zh-Hant-MO").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0} 小時後",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} 小時前",
    },
    "relative-type-0": "這個小時",
  },
);
Globalize.b1132660972 = relativeTimeFormatterFn(
  Globalize("zh-Hant-MO").numberFormatter({}),
  Globalize("zh-Hant-MO").pluralGenerator({}),
  {
    "relativeTime-type-future": {
      "relativeTimePattern-count-other": "{0} 分鐘後",
    },
    "relativeTime-type-past": {
      "relativeTimePattern-count-other": "{0} 分鐘前",
    },
    "relative-type-0": "這分鐘",
  },
);
Globalize.a1260780216 = unitFormatterFn(
  Globalize("ar-EG").numberFormatter({}),
  Globalize("ar-EG").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} لكل {1}",
    unitProperties: {
      displayName: "بايت",
      zero: "{0} بايت",
      one: "{0} بايت",
      two: "{0} بايت",
      few: "{0} بايت",
      many: "{0} بايت",
      other: "{0} بايت",
    },
  },
);
Globalize.b852797741 = unitFormatterFn(
  Globalize("ar-EG").numberFormatter({}),
  Globalize("ar-EG").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "كيلوبايت",
      zero: "{0} كيلوبايت",
      one: "{0} كيلوبايت",
      two: "{0} كيلوبايت",
      few: "{0} كيلوبايت",
      many: "{0} كيلوبايت",
      other: "{0} كيلوبايت",
    },
  },
);
Globalize.b614409566 = unitFormatterFn(
  Globalize("ar-EG").numberFormatter({}),
  Globalize("ar-EG").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "ميغابايت",
      zero: "{0} ميغابايت",
      one: "{0} ميغابايت",
      two: "{0} ميغابايت",
      few: "{0} ميغابايت",
      many: "{0} ميغابايت",
      other: "{0} ميغابايت",
    },
  },
);
Globalize.b1965047144 = unitFormatterFn(
  Globalize("ar-EG").numberFormatter({}),
  Globalize("ar-EG").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "غيغابايت",
      zero: "{0} غيغابايت",
      one: "{0} غيغابايت",
      two: "{0} غيغابايت",
      few: "{0} غيغابايت",
      many: "{0} غيغابايت",
      other: "{0} غيغابايت",
    },
  },
);
Globalize.a1273411684 = unitFormatterFn(
  Globalize("ar-SA").numberFormatter({}),
  Globalize("ar-SA").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} لكل {1}",
    unitProperties: {
      displayName: "بايت",
      zero: "{0} بايت",
      one: "{0} بايت",
      two: "{0} بايت",
      few: "{0} بايت",
      many: "{0} بايت",
      other: "{0} بايت",
    },
  },
);
Globalize.b304462681 = unitFormatterFn(
  Globalize("ar-SA").numberFormatter({}),
  Globalize("ar-SA").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "كيلوبايت",
      zero: "{0} كيلوبايت",
      one: "{0} كيلوبايت",
      two: "{0} كيلوبايت",
      few: "{0} كيلوبايت",
      many: "{0} كيلوبايت",
      other: "{0} كيلوبايت",
    },
  },
);
Globalize.b66074506 = unitFormatterFn(
  Globalize("ar-SA").numberFormatter({}),
  Globalize("ar-SA").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "ميغابايت",
      zero: "{0} ميغابايت",
      one: "{0} ميغابايت",
      two: "{0} ميغابايت",
      few: "{0} ميغابايت",
      many: "{0} ميغابايت",
      other: "{0} ميغابايت",
    },
  },
);
Globalize.b1416712084 = unitFormatterFn(
  Globalize("ar-SA").numberFormatter({}),
  Globalize("ar-SA").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "غيغابايت",
      zero: "{0} غيغابايت",
      one: "{0} غيغابايت",
      two: "{0} غيغابايت",
      few: "{0} غيغابايت",
      many: "{0} غيغابايت",
      other: "{0} غيغابايت",
    },
  },
);
Globalize.b595460021 = unitFormatterFn(
  Globalize("bg").numberFormatter({}),
  Globalize("bg").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} на {1}",
    unitProperties: {
      displayName: "байтове",
      one: "{0} байт",
      other: "{0} байта",
    },
  },
);
Globalize.a946001184 = unitFormatterFn(
  Globalize("bg").numberFormatter({}),
  Globalize("bg").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.a1184389359 = unitFormatterFn(
  Globalize("bg").numberFormatter({}),
  Globalize("bg").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.b166248219 = unitFormatterFn(
  Globalize("bg").numberFormatter({}),
  Globalize("bg").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.b986086044 = unitFormatterFn(
  Globalize("ca").numberFormatter({}),
  Globalize("ca").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} per {1}",
    unitProperties: {
      displayName: "bytes",
      one: "{0} byte",
      other: "{0} bytes",
    },
  },
);
Globalize.a1228904359 = unitFormatterFn(
  Globalize("ca").numberFormatter({}),
  Globalize("ca").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.a1467292534 = unitFormatterFn(
  Globalize("ca").numberFormatter({}),
  Globalize("ca").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a116654956 = unitFormatterFn(
  Globalize("ca").numberFormatter({}),
  Globalize("ca").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.a278851446 = unitFormatterFn(
  Globalize("cs").numberFormatter({}),
  Globalize("cs").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "bajty",
      one: "{0} bajt",
      few: "{0} bajty",
      many: "{0} bajtu",
      other: "{0} bajtů",
    },
  },
);
Globalize.a1432594645 = unitFormatterFn(
  Globalize("cs").numberFormatter({}),
  Globalize("cs").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "kB",
      one: "{0} kB",
      few: "{0} kB",
      many: "{0} kB",
      other: "{0} kB",
    },
  },
);
Globalize.a1670982820 = unitFormatterFn(
  Globalize("cs").numberFormatter({}),
  Globalize("cs").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "MB",
      one: "{0} MB",
      few: "{0} MB",
      many: "{0} MB",
      other: "{0} MB",
    },
  },
);
Globalize.a320345242 = unitFormatterFn(
  Globalize("cs").numberFormatter({}),
  Globalize("cs").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "GB",
      one: "{0} GB",
      few: "{0} GB",
      many: "{0} GB",
      other: "{0} GB",
    },
  },
);
Globalize.b955066237 = unitFormatterFn(
  Globalize("da").numberFormatter({}),
  Globalize("da").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} pr. {1}",
    unitProperties: {
      displayName: "bytes",
      one: "{0} byte",
      other: "{0} bytes",
    },
  },
);
Globalize.a1579704296 = unitFormatterFn(
  Globalize("da").numberFormatter({}),
  Globalize("da").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.a1818092471 = unitFormatterFn(
  Globalize("da").numberFormatter({}),
  Globalize("da").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a467454893 = unitFormatterFn(
  Globalize("da").numberFormatter({}),
  Globalize("da").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.b673969017 = unitFormatterFn(
  Globalize("de").numberFormatter({}),
  Globalize("de").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} pro {1}",
    unitProperties: {
      displayName: "Bytes",
      one: "{0} Byte",
      other: "{0} Bytes",
    },
  },
);
Globalize.a1624968804 = unitFormatterFn(
  Globalize("de").numberFormatter({}),
  Globalize("de").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kbyte", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.a1863356979 = unitFormatterFn(
  Globalize("de").numberFormatter({}),
  Globalize("de").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "Mbyte", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a512719401 = unitFormatterFn(
  Globalize("de").numberFormatter({}),
  Globalize("de").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "Gigabyte", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.a1996454573 = unitFormatterFn(
  Globalize("el").numberFormatter({}),
  Globalize("el").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} ανά {1}",
    unitProperties: { displayName: "byte", one: "{0} byte", other: "{0} byte" },
  },
);
Globalize.a2054981630 = unitFormatterFn(
  Globalize("el").numberFormatter({}),
  Globalize("el").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.b2001597491 = unitFormatterFn(
  Globalize("el").numberFormatter({}),
  Globalize("el").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a942732227 = unitFormatterFn(
  Globalize("el").numberFormatter({}),
  Globalize("el").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.a2137003183 = unitFormatterFn(
  Globalize("en").numberFormatter({}),
  Globalize("en").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} per {1}",
    unitProperties: {
      displayName: "bytes",
      one: "{0} byte",
      other: "{0} bytes",
    },
  },
);
Globalize.a2077613884 = unitFormatterFn(
  Globalize("en").numberFormatter({}),
  Globalize("en").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kByte", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.b1978965237 = unitFormatterFn(
  Globalize("en").numberFormatter({}),
  Globalize("en").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MByte", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a965364481 = unitFormatterFn(
  Globalize("en").numberFormatter({}),
  Globalize("en").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GByte", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.a340891060 = unitFormatterFn(
  Globalize("es").numberFormatter({}),
  Globalize("es").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} por {1}",
    unitProperties: {
      displayName: "bytes",
      one: "{0} byte",
      other: "{0} bytes",
    },
  },
);
Globalize.a2134194519 = unitFormatterFn(
  Globalize("es").numberFormatter({}),
  Globalize("es").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.b1922384602 = unitFormatterFn(
  Globalize("es").numberFormatter({}),
  Globalize("es").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a1021945116 = unitFormatterFn(
  Globalize("es").numberFormatter({}),
  Globalize("es").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.b1736318283 = unitFormatterFn(
  Globalize("et").numberFormatter({}),
  Globalize("et").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} {1} kohta",
    unitProperties: {
      displayName: "baidid",
      one: "{0} bait",
      other: "{0} baiti",
    },
  },
);
Globalize.a2145510646 = unitFormatterFn(
  Globalize("et").numberFormatter({}),
  Globalize("et").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.b1911068475 = unitFormatterFn(
  Globalize("et").numberFormatter({}),
  Globalize("et").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a1033261243 = unitFormatterFn(
  Globalize("et").numberFormatter({}),
  Globalize("et").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.a481439670 = unitFormatterFn(
  Globalize("eu").numberFormatter({}),
  Globalize("eu").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "byte-ak",
      one: "{0} byte",
      other: "{0} byte",
    },
  },
);
Globalize.b2138140523 = unitFormatterFn(
  Globalize("eu").numberFormatter({}),
  Globalize("eu").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.b1899752348 = unitFormatterFn(
  Globalize("eu").numberFormatter({}),
  Globalize("eu").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a1044577370 = unitFormatterFn(
  Globalize("eu").numberFormatter({}),
  Globalize("eu").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.b330832183 = unitFormatterFn(
  Globalize("fi").numberFormatter({}),
  Globalize("fi").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "tavut",
      one: "{0} tavu",
      other: "{0} tavua",
    },
  },
);
Globalize.b1923134110 = unitFormatterFn(
  Globalize("fi").numberFormatter({}),
  Globalize("fi").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kt", one: "{0} kt", other: "{0} kt" },
  },
);
Globalize.b1684745935 = unitFormatterFn(
  Globalize("fi").numberFormatter({}),
  Globalize("fi").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "Mt", one: "{0} Mt", other: "{0} Mt" },
  },
);
Globalize.a1259583783 = unitFormatterFn(
  Globalize("fi").numberFormatter({}),
  Globalize("fi").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "Gt", one: "{0} Gt", other: "{0} Gt" },
  },
);
Globalize.b1845847086 = unitFormatterFn(
  Globalize("fr").numberFormatter({}),
  Globalize("fr").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} par {1}",
    unitProperties: {
      displayName: "octets",
      one: "{0} octet",
      other: "{0} octets",
    },
  },
);
Globalize.b1821288967 = unitFormatterFn(
  Globalize("fr").numberFormatter({}),
  Globalize("fr").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "ko", one: "{0} ko", other: "{0} ko" },
  },
);
Globalize.b1582900792 = unitFormatterFn(
  Globalize("fr").numberFormatter({}),
  Globalize("fr").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "Mo", one: "{0} Mo", other: "{0} Mo" },
  },
);
Globalize.a1361428926 = unitFormatterFn(
  Globalize("fr").numberFormatter({}),
  Globalize("fr").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "Go", one: "{0} Go", other: "{0} Go" },
  },
);
Globalize.a2058494187 = unitFormatterFn(
  Globalize("gl").numberFormatter({}),
  Globalize("gl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} por {1}",
    unitProperties: {
      displayName: "bytes",
      one: "{0} byte",
      other: "{0} bytes",
    },
  },
);
Globalize.b1538385792 = unitFormatterFn(
  Globalize("gl").numberFormatter({}),
  Globalize("gl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.b1299997617 = unitFormatterFn(
  Globalize("gl").numberFormatter({}),
  Globalize("gl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a1644332101 = unitFormatterFn(
  Globalize("gl").numberFormatter({}),
  Globalize("gl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.b549889789 = unitFormatterFn(
  Globalize("he").numberFormatter({}),
  Globalize("he").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} ל{1}",
    unitProperties: {
      displayName: "בייט",
      one: "{0} בייט",
      two: "{0} בייט",
      many: "{0} בייט",
      other: "{0} בייט",
    },
  },
);
Globalize.b1266798744 = unitFormatterFn(
  Globalize("he").numberFormatter({}),
  Globalize("he").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "kB",
      one: "{0} kB",
      two: "{0} kB",
      many: "{0} kB",
      other: "{0} kB",
    },
  },
);
Globalize.b1028410569 = unitFormatterFn(
  Globalize("he").numberFormatter({}),
  Globalize("he").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "MB",
      one: "{0} MB",
      two: "{0} MB",
      many: "{0} MB",
      other: "{0} MB",
    },
  },
);
Globalize.a1915919149 = unitFormatterFn(
  Globalize("he").numberFormatter({}),
  Globalize("he").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "GB",
      one: "{0} GB",
      two: "{0} GB",
      many: "{0} GB",
      other: "{0} GB",
    },
  },
);
Globalize.b268792569 = unitFormatterFn(
  Globalize("hi").numberFormatter({}),
  Globalize("hi").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} प्रति {1}",
    unitProperties: { displayName: "बाइट", one: "{0} बाइट", other: "{0} बाइट" },
  },
);
Globalize.b1221534236 = unitFormatterFn(
  Globalize("hi").numberFormatter({}),
  Globalize("hi").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.b983146061 = unitFormatterFn(
  Globalize("hi").numberFormatter({}),
  Globalize("hi").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a1961183657 = unitFormatterFn(
  Globalize("hi").numberFormatter({}),
  Globalize("hi").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.b1783807472 = unitFormatterFn(
  Globalize("hr").numberFormatter({}),
  Globalize("hr").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "bajtovi",
      one: "{0} bajt",
      few: "{0} bajta",
      other: "{0} bajtova",
    },
  },
);
Globalize.b1119689093 = unitFormatterFn(
  Globalize("hr").numberFormatter({}),
  Globalize("hr").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "kB",
      one: "{0} kB",
      few: "{0} kB",
      other: "{0} kB",
    },
  },
);
Globalize.b881300918 = unitFormatterFn(
  Globalize("hr").numberFormatter({}),
  Globalize("hr").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "MB",
      one: "{0} MB",
      few: "{0} MB",
      other: "{0} MB",
    },
  },
);
Globalize.a2063028800 = unitFormatterFn(
  Globalize("hr").numberFormatter({}),
  Globalize("hr").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "GB",
      one: "{0} GB",
      few: "{0} GB",
      other: "{0} GB",
    },
  },
);
Globalize.a574499091 = unitFormatterFn(
  Globalize("hu").numberFormatter({}),
  Globalize("hu").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} per {1}",
    unitProperties: { displayName: "bájt", one: "{0} bájt", other: "{0} bájt" },
  },
);
Globalize.b1085740712 = unitFormatterFn(
  Globalize("hu").numberFormatter({}),
  Globalize("hu").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.b847352537 = unitFormatterFn(
  Globalize("hu").numberFormatter({}),
  Globalize("hu").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a2096977181 = unitFormatterFn(
  Globalize("hu").numberFormatter({}),
  Globalize("hu").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.a1558339361 = unitFormatterFn(
  Globalize("id").numberFormatter({}),
  Globalize("id").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} per {1}",
    unitProperties: { displayName: "byte", other: "{0} byte" },
  },
);
Globalize.b927314934 = unitFormatterFn(
  Globalize("id").numberFormatter({}),
  Globalize("id").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kByte", other: "{0} kB" },
  },
);
Globalize.b688926759 = unitFormatterFn(
  Globalize("id").numberFormatter({}),
  Globalize("id").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MByte", other: "{0} MB" },
  },
);
Globalize.b2039564337 = unitFormatterFn(
  Globalize("id").numberFormatter({}),
  Globalize("id").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GByte", other: "{0} GB" },
  },
);
Globalize.b1612239055 = unitFormatterFn(
  Globalize("it").numberFormatter({}),
  Globalize("it").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} al {1}",
    unitProperties: { displayName: "byte", one: "{0} byte", other: "{0} byte" },
  },
);
Globalize.b746256902 = unitFormatterFn(
  Globalize("it").numberFormatter({}),
  Globalize("it").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.b507868727 = unitFormatterFn(
  Globalize("it").numberFormatter({}),
  Globalize("it").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.b1858506305 = unitFormatterFn(
  Globalize("it").numberFormatter({}),
  Globalize("it").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.b768947395 = unitFormatterFn(
  Globalize("ja").numberFormatter({}),
  Globalize("ja").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}毎{1}",
    unitProperties: { displayName: "バイト", other: "{0} バイト" },
  },
);
Globalize.b610463378 = unitFormatterFn(
  Globalize("ja").numberFormatter({}),
  Globalize("ja").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "KB", other: "{0} KB" },
  },
);
Globalize.b372075203 = unitFormatterFn(
  Globalize("ja").numberFormatter({}),
  Globalize("ja").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", other: "{0} MB" },
  },
);
Globalize.b1722712781 = unitFormatterFn(
  Globalize("ja").numberFormatter({}),
  Globalize("ja").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", other: "{0} GB" },
  },
);
Globalize.b35184538 = unitFormatterFn(
  Globalize("kk").numberFormatter({}),
  Globalize("kk").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "байт", one: "{0} байт", other: "{0} байт" },
  },
);
Globalize.b146502171 = unitFormatterFn(
  Globalize("kk").numberFormatter({}),
  Globalize("kk").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "кбайт", one: "{0} кБ", other: "{0} кБ" },
  },
);
Globalize.a91886004 = unitFormatterFn(
  Globalize("kk").numberFormatter({}),
  Globalize("kk").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "Мбайт", one: "{0} MБ", other: "{0} MБ" },
  },
);
Globalize.b1258751574 = unitFormatterFn(
  Globalize("kk").numberFormatter({}),
  Globalize("kk").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "Гбайт", one: "{0} ГБ", other: "{0} ГБ" },
  },
);
Globalize.a245912682 = unitFormatterFn(
  Globalize("ko").numberFormatter({}),
  Globalize("ko").pluralGenerator({}),
  {
    compoundUnitPattern: "{1}당 {0}",
    unitProperties: { displayName: "바이트", other: "{0}바이트" },
  },
);
Globalize.b101237663 = unitFormatterFn(
  Globalize("ko").numberFormatter({}),
  Globalize("ko").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", other: "{0}kB" },
  },
);
Globalize.a137150512 = unitFormatterFn(
  Globalize("ko").numberFormatter({}),
  Globalize("ko").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", other: "{0}MB" },
  },
);
Globalize.b1213487066 = unitFormatterFn(
  Globalize("ko").numberFormatter({}),
  Globalize("ko").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", other: "{0}GB" },
  },
);
Globalize.b1519179634 = unitFormatterFn(
  Globalize("lt").numberFormatter({}),
  Globalize("lt").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "baitai",
      one: "{0} baitas",
      few: "{0} baitai",
      many: "{0} baito",
      other: "{0} baitų",
    },
  },
);
Globalize.a306142909 = unitFormatterFn(
  Globalize("lt").numberFormatter({}),
  Globalize("lt").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "kB",
      one: "{0} kB",
      few: "{0} kB",
      many: "{0} kB",
      other: "{0} kB",
    },
  },
);
Globalize.a544531084 = unitFormatterFn(
  Globalize("lt").numberFormatter({}),
  Globalize("lt").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "MB",
      one: "{0} MB",
      few: "{0} MB",
      many: "{0} MB",
      other: "{0} MB",
    },
  },
);
Globalize.b806106494 = unitFormatterFn(
  Globalize("lt").numberFormatter({}),
  Globalize("lt").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "GB",
      one: "{0} GB",
      few: "{0} GB",
      many: "{0} GB",
      other: "{0} GB",
    },
  },
);
Globalize.b1378631024 = unitFormatterFn(
  Globalize("lv").numberFormatter({}),
  Globalize("lv").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "baiti",
      zero: "{0} baitu",
      one: "{0} baits",
      other: "{0} baiti",
    },
  },
);
Globalize.a328775163 = unitFormatterFn(
  Globalize("lv").numberFormatter({}),
  Globalize("lv").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "kB",
      zero: "{0} kB",
      one: "{0} kB",
      other: "{0} kB",
    },
  },
);
Globalize.a567163338 = unitFormatterFn(
  Globalize("lv").numberFormatter({}),
  Globalize("lv").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "MB",
      zero: "{0} MB",
      one: "{0} MB",
      other: "{0} MB",
    },
  },
);
Globalize.b783474240 = unitFormatterFn(
  Globalize("lv").numberFormatter({}),
  Globalize("lv").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "GB",
      zero: "{0} GB",
      one: "{0} GB",
      other: "{0} GB",
    },
  },
);
Globalize.a589049516 = unitFormatterFn(
  Globalize("ms").numberFormatter({}),
  Globalize("ms").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} per {1}",
    unitProperties: { displayName: "bait", other: "{0} bait" },
  },
);
Globalize.a645626719 = unitFormatterFn(
  Globalize("ms").numberFormatter({}),
  Globalize("ms").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kBait", other: "{0} kB" },
  },
);
Globalize.a884014894 = unitFormatterFn(
  Globalize("ms").numberFormatter({}),
  Globalize("ms").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MBait", other: "{0} MB" },
  },
);
Globalize.b466622684 = unitFormatterFn(
  Globalize("ms").numberFormatter({}),
  Globalize("ms").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GBait", other: "{0} GB" },
  },
);
Globalize.a1572889786 = unitFormatterFn(
  Globalize("nb").numberFormatter({}),
  Globalize("nb").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} per {1}",
    unitProperties: { displayName: "byte", one: "{0} byte", other: "{0} byte" },
  },
);
Globalize.a804052497 = unitFormatterFn(
  Globalize("nb").numberFormatter({}),
  Globalize("nb").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.a1042440672 = unitFormatterFn(
  Globalize("nb").numberFormatter({}),
  Globalize("nb").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.b308196906 = unitFormatterFn(
  Globalize("nb").numberFormatter({}),
  Globalize("nb").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.b2019334460 = unitFormatterFn(
  Globalize("nl").numberFormatter({}),
  Globalize("nl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} per {1}",
    unitProperties: { displayName: "byte", one: "{0} byte", other: "{0} byte" },
  },
);
Globalize.a917213767 = unitFormatterFn(
  Globalize("nl").numberFormatter({}),
  Globalize("nl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.a1155601942 = unitFormatterFn(
  Globalize("nl").numberFormatter({}),
  Globalize("nl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.b195035636 = unitFormatterFn(
  Globalize("nl").numberFormatter({}),
  Globalize("nl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.b1957294846 = unitFormatterFn(
  Globalize("pl").numberFormatter({}),
  Globalize("pl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} na {1}",
    unitProperties: {
      displayName: "bajty",
      one: "{0} bajt",
      few: "{0} bajty",
      many: "{0} bajtów",
      other: "{0} bajta",
    },
  },
);
Globalize.a1618813641 = unitFormatterFn(
  Globalize("pl").numberFormatter({}),
  Globalize("pl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "kB",
      other: "{0} kB",
      one: "{0} kB",
      few: "{0} kB",
      many: "{0} kB",
    },
  },
);
Globalize.a1857201816 = unitFormatterFn(
  Globalize("pl").numberFormatter({}),
  Globalize("pl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "MB",
      other: "{0} MB",
      one: "{0} MB",
      few: "{0} MB",
      many: "{0} MB",
    },
  },
);
Globalize.a506564238 = unitFormatterFn(
  Globalize("pl").numberFormatter({}),
  Globalize("pl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "GB",
      other: "{0} GB",
      one: "{0} GB",
      few: "{0} GB",
      many: "{0} GB",
    },
  },
);
Globalize.b1395100406 = unitFormatterFn(
  Globalize("pt").numberFormatter({}),
  Globalize("pt").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} por {1}",
    unitProperties: {
      displayName: "bytes",
      one: "{0} bytes",
      other: "{0} bytes",
    },
  },
);
Globalize.a1709342657 = unitFormatterFn(
  Globalize("pt").numberFormatter({}),
  Globalize("pt").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kByte", other: "{0} kB", one: "{0} kB" },
  },
);
Globalize.a1947730832 = unitFormatterFn(
  Globalize("pt").numberFormatter({}),
  Globalize("pt").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MByte", other: "{0} MB", one: "{0} MB" },
  },
);
Globalize.a597093254 = unitFormatterFn(
  Globalize("pt").numberFormatter({}),
  Globalize("pt").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GByte", other: "{0} GB", one: "{0} GB" },
  },
);
Globalize.a1665198183 = unitFormatterFn(
  Globalize("pt-PT").numberFormatter({}),
  Globalize("pt-PT").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} por {1}",
    unitProperties: {
      displayName: "bytes",
      one: "{0} byte",
      other: "{0} bytes",
    },
  },
);
Globalize.a1283235460 = unitFormatterFn(
  Globalize("pt-PT").numberFormatter({}),
  Globalize("pt-PT").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kByte", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.a1521623635 = unitFormatterFn(
  Globalize("pt-PT").numberFormatter({}),
  Globalize("pt-PT").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MByte", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a170986057 = unitFormatterFn(
  Globalize("pt-PT").numberFormatter({}),
  Globalize("pt-PT").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GByte", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.a463051331 = unitFormatterFn(
  Globalize("ro").numberFormatter({}),
  Globalize("ro").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} pe {1}",
    unitProperties: {
      displayName: "byți",
      one: "{0} byte",
      few: "{0} byți",
      other: "{0} de byți",
    },
  },
);
Globalize.b1940605400 = unitFormatterFn(
  Globalize("ro").numberFormatter({}),
  Globalize("ro").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "kB",
      one: "{0} kB",
      few: "{0} kB",
      other: "{0} kB",
    },
  },
);
Globalize.b1702217225 = unitFormatterFn(
  Globalize("ro").numberFormatter({}),
  Globalize("ro").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "MB",
      one: "{0} MB",
      few: "{0} MB",
      other: "{0} MB",
    },
  },
);
Globalize.a1242112493 = unitFormatterFn(
  Globalize("ro").numberFormatter({}),
  Globalize("ro").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "GB",
      one: "{0} GB",
      few: "{0} GB",
      other: "{0} GB",
    },
  },
);
Globalize.a884697161 = unitFormatterFn(
  Globalize("ru").numberFormatter({}),
  Globalize("ru").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "байты",
      one: "{0} байт",
      few: "{0} байта",
      many: "{0} байт",
      other: "{0} байта",
    },
  },
);
Globalize.b1872708638 = unitFormatterFn(
  Globalize("ru").numberFormatter({}),
  Globalize("ru").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "кБ",
      one: "{0} кБ",
      few: "{0} кБ",
      many: "{0} кБ",
      other: "{0} кБ",
    },
  },
);
Globalize.b1634320463 = unitFormatterFn(
  Globalize("ru").numberFormatter({}),
  Globalize("ru").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "МБ",
      one: "{0} МБ",
      few: "{0} МБ",
      many: "{0} МБ",
      other: "{0} МБ",
    },
  },
);
Globalize.a1310009255 = unitFormatterFn(
  Globalize("ru").numberFormatter({}),
  Globalize("ru").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "ГБ",
      one: "{0} ГБ",
      few: "{0} ГБ",
      many: "{0} ГБ",
      other: "{0} ГБ",
    },
  },
);
Globalize.a212973918 = unitFormatterFn(
  Globalize("sk").numberFormatter({}),
  Globalize("sk").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "bajty",
      one: "{0} bajt",
      few: "{0} bajty",
      many: "{0} bajtu",
      other: "{0} bajtov",
    },
  },
);
Globalize.b1635069971 = unitFormatterFn(
  Globalize("sk").numberFormatter({}),
  Globalize("sk").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "kB",
      one: "{0} kB",
      few: "{0} kB",
      many: "{0} kB",
      other: "{0} kB",
    },
  },
);
Globalize.b1396681796 = unitFormatterFn(
  Globalize("sk").numberFormatter({}),
  Globalize("sk").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "MB",
      one: "{0} MB",
      few: "{0} MB",
      many: "{0} MB",
      other: "{0} MB",
    },
  },
);
Globalize.a1547647922 = unitFormatterFn(
  Globalize("sk").numberFormatter({}),
  Globalize("sk").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "GB",
      one: "{0} GB",
      few: "{0} GB",
      many: "{0} GB",
      other: "{0} GB",
    },
  },
);
Globalize.b1864235425 = unitFormatterFn(
  Globalize("sl").numberFormatter({}),
  Globalize("sl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} na {1}",
    unitProperties: {
      displayName: "bajt",
      one: "{0} bajt",
      two: "{0} bajta",
      few: "{0} bajti",
      other: "{0} bajtov",
    },
  },
);
Globalize.b1623753844 = unitFormatterFn(
  Globalize("sl").numberFormatter({}),
  Globalize("sl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "kB",
      one: "{0} kB",
      two: "{0} kB",
      few: "{0} kB",
      other: "{0} kB",
    },
  },
);
Globalize.b1385365669 = unitFormatterFn(
  Globalize("sl").numberFormatter({}),
  Globalize("sl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "MB",
      one: "{0} MB",
      two: "{0} MB",
      few: "{0} MB",
      other: "{0} MB",
    },
  },
);
Globalize.a1558964049 = unitFormatterFn(
  Globalize("sl").numberFormatter({}),
  Globalize("sl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "GB",
      one: "{0} GB",
      two: "{0} GB",
      few: "{0} GB",
      other: "{0} GB",
    },
  },
);
Globalize.b1064685448 = unitFormatterFn(
  Globalize("sr-Cyrl").numberFormatter({}),
  Globalize("sr-Cyrl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "бајтови",
      one: "{0} бајт",
      few: "{0} бајта",
      other: "{0} бајтова",
    },
  },
);
Globalize.b826099437 = unitFormatterFn(
  Globalize("sr-Cyrl").numberFormatter({}),
  Globalize("sr-Cyrl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "kB",
      one: "{0} kB",
      few: "{0} kB",
      other: "{0} kB",
    },
  },
);
Globalize.b587711262 = unitFormatterFn(
  Globalize("sr-Cyrl").numberFormatter({}),
  Globalize("sr-Cyrl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "MB",
      one: "{0} MB",
      few: "{0} MB",
      other: "{0} MB",
    },
  },
);
Globalize.b1938348840 = unitFormatterFn(
  Globalize("sr-Cyrl").numberFormatter({}),
  Globalize("sr-Cyrl").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "GB",
      one: "{0} GB",
      few: "{0} GB",
      other: "{0} GB",
    },
  },
);
Globalize.b463658761 = unitFormatterFn(
  Globalize("sr-Latn").numberFormatter({}),
  Globalize("sr-Latn").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "bajtovi",
      one: "{0} bajt",
      few: "{0} bajta",
      other: "{0} bajtova",
    },
  },
);
Globalize.b1577238540 = unitFormatterFn(
  Globalize("sr-Latn").numberFormatter({}),
  Globalize("sr-Latn").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "kB",
      one: "{0} kB",
      few: "{0} kB",
      other: "{0} kB",
    },
  },
);
Globalize.b1338850365 = unitFormatterFn(
  Globalize("sr-Latn").numberFormatter({}),
  Globalize("sr-Latn").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "MB",
      one: "{0} MB",
      few: "{0} MB",
      other: "{0} MB",
    },
  },
);
Globalize.a1605479353 = unitFormatterFn(
  Globalize("sr-Latn").numberFormatter({}),
  Globalize("sr-Latn").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "GB",
      one: "{0} GB",
      few: "{0} GB",
      other: "{0} GB",
    },
  },
);
Globalize.b1161492375 = unitFormatterFn(
  Globalize("sv").numberFormatter({}),
  Globalize("sv").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} per {1}",
    unitProperties: { displayName: "byte", one: "{0} byte", other: "{0} byte" },
  },
);
Globalize.b1510592574 = unitFormatterFn(
  Globalize("sv").numberFormatter({}),
  Globalize("sv").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.b1272204399 = unitFormatterFn(
  Globalize("sv").numberFormatter({}),
  Globalize("sv").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a1672125319 = unitFormatterFn(
  Globalize("sv").numberFormatter({}),
  Globalize("sv").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.b2114312838 = unitFormatterFn(
  Globalize("th").numberFormatter({}),
  Globalize("th").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}ต่อ{1}",
    unitProperties: { displayName: "ไบต์", other: "{0} ไบต์" },
  },
);
Globalize.b1318218415 = unitFormatterFn(
  Globalize("th").numberFormatter({}),
  Globalize("th").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", other: "{0} kB" },
  },
);
Globalize.b1079830240 = unitFormatterFn(
  Globalize("th").numberFormatter({}),
  Globalize("th").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", other: "{0} MB" },
  },
);
Globalize.a1864499478 = unitFormatterFn(
  Globalize("th").numberFormatter({}),
  Globalize("th").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", other: "{0} GB" },
  },
);
Globalize.b1411569788 = unitFormatterFn(
  Globalize("tr").numberFormatter({}),
  Globalize("tr").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "bayt", one: "{0} bayt", other: "{0} bayt" },
  },
);
Globalize.b1205057145 = unitFormatterFn(
  Globalize("tr").numberFormatter({}),
  Globalize("tr").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", one: "{0} kB", other: "{0} kB" },
  },
);
Globalize.b966668970 = unitFormatterFn(
  Globalize("tr").numberFormatter({}),
  Globalize("tr").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", one: "{0} MB", other: "{0} MB" },
  },
);
Globalize.a1977660748 = unitFormatterFn(
  Globalize("tr").numberFormatter({}),
  Globalize("tr").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", one: "{0} GB", other: "{0} GB" },
  },
);
Globalize.a275013532 = unitFormatterFn(
  Globalize("uk").numberFormatter({}),
  Globalize("uk").pluralGenerator({}),
  {
    compoundUnitPattern: "{0} на {1}",
    unitProperties: {
      displayName: "байти",
      one: "{0} байт",
      few: "{0} байти",
      many: "{0} байтів",
      other: "{0} байта",
    },
  },
);
Globalize.b933470097 = unitFormatterFn(
  Globalize("uk").numberFormatter({}),
  Globalize("uk").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "кбайт",
      one: "{0} кБ",
      few: "{0} кБ",
      many: "{0} кБ",
      other: "{0} кБ",
    },
  },
);
Globalize.b695081922 = unitFormatterFn(
  Globalize("uk").numberFormatter({}),
  Globalize("uk").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "Мбайт",
      one: "{0} МБ",
      few: "{0} МБ",
      many: "{0} МБ",
      other: "{0} МБ",
    },
  },
);
Globalize.b2045719500 = unitFormatterFn(
  Globalize("uk").numberFormatter({}),
  Globalize("uk").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: {
      displayName: "Гбайт",
      one: "{0} ГБ",
      few: "{0} ГБ",
      many: "{0} ГБ",
      other: "{0} ГБ",
    },
  },
);
Globalize.a165484729 = unitFormatterFn(
  Globalize("vi").numberFormatter({}),
  Globalize("vi").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "byte", other: "{0} byte" },
  },
);
Globalize.b605302414 = unitFormatterFn(
  Globalize("vi").numberFormatter({}),
  Globalize("vi").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", other: "{0} kB" },
  },
);
Globalize.b366914239 = unitFormatterFn(
  Globalize("vi").numberFormatter({}),
  Globalize("vi").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", other: "{0} MB" },
  },
);
Globalize.b1717551817 = unitFormatterFn(
  Globalize("vi").numberFormatter({}),
  Globalize("vi").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", other: "{0} GB" },
  },
);
Globalize.b1657704125 = unitFormatterFn(
  Globalize("yue").numberFormatter({}),
  Globalize("yue").pluralGenerator({}),
  {
    compoundUnitPattern: "每 {1} {0}",
    unitProperties: { displayName: "byte", other: "{0} byte" },
  },
);
Globalize.a1393245992 = unitFormatterFn(
  Globalize("yue").numberFormatter({}),
  Globalize("yue").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", other: "{0} kB" },
  },
);
Globalize.a1631634167 = unitFormatterFn(
  Globalize("yue").numberFormatter({}),
  Globalize("yue").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", other: "{0} MB" },
  },
);
Globalize.a280996589 = unitFormatterFn(
  Globalize("yue").numberFormatter({}),
  Globalize("yue").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", other: "{0} GB" },
  },
);
Globalize.a915494743 = unitFormatterFn(
  Globalize("zh-Hans").numberFormatter({}),
  Globalize("zh-Hans").pluralGenerator({}),
  {
    compoundUnitPattern: "每{1}{0}",
    unitProperties: { displayName: "字节", other: "{0}字节" },
  },
);
Globalize.a1506542996 = unitFormatterFn(
  Globalize("zh-Hans").numberFormatter({}),
  Globalize("zh-Hans").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "千字节", other: "{0}千字节" },
  },
);
Globalize.a1744931171 = unitFormatterFn(
  Globalize("zh-Hans").numberFormatter({}),
  Globalize("zh-Hans").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "兆字节", other: "{0}兆字节" },
  },
);
Globalize.a394293593 = unitFormatterFn(
  Globalize("zh-Hans").numberFormatter({}),
  Globalize("zh-Hans").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "吉字节", other: "{0}吉字节" },
  },
);
Globalize.b1803561142 = unitFormatterFn(
  Globalize("zh-Hans-SG").numberFormatter({}),
  Globalize("zh-Hans-SG").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "字节", other: "{0}字节" },
  },
);
Globalize.b692157055 = unitFormatterFn(
  Globalize("zh-Hans-SG").numberFormatter({}),
  Globalize("zh-Hans-SG").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "千字节", other: "{0}千字节" },
  },
);
Globalize.b453768880 = unitFormatterFn(
  Globalize("zh-Hans-SG").numberFormatter({}),
  Globalize("zh-Hans-SG").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "兆字节", other: "{0}兆字节" },
  },
);
Globalize.b1804406458 = unitFormatterFn(
  Globalize("zh-Hans-SG").numberFormatter({}),
  Globalize("zh-Hans-SG").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "吉字节", other: "{0}吉字节" },
  },
);
Globalize.b1161714600 = unitFormatterFn(
  Globalize("zh-Hant").numberFormatter({}),
  Globalize("zh-Hant").pluralGenerator({}),
  {
    compoundUnitPattern: "每{1} {0}",
    unitProperties: { displayName: "byte", other: "{0} byte" },
  },
);
Globalize.a1517859123 = unitFormatterFn(
  Globalize("zh-Hant").numberFormatter({}),
  Globalize("zh-Hant").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", other: "{0} kB" },
  },
);
Globalize.a1756247298 = unitFormatterFn(
  Globalize("zh-Hant").numberFormatter({}),
  Globalize("zh-Hant").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", other: "{0} MB" },
  },
);
Globalize.a405609720 = unitFormatterFn(
  Globalize("zh-Hant").numberFormatter({}),
  Globalize("zh-Hant").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", other: "{0} GB" },
  },
);
Globalize.b2118418344 = unitFormatterFn(
  Globalize("zh-Hant-HK").numberFormatter({}),
  Globalize("zh-Hant-HK").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}每{1}",
    unitProperties: { displayName: "位元組", other: "{0} 位元組" },
  },
);
Globalize.a1900565811 = unitFormatterFn(
  Globalize("zh-Hant-HK").numberFormatter({}),
  Globalize("zh-Hant-HK").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", other: "{0} kB" },
  },
);
Globalize.a2138953986 = unitFormatterFn(
  Globalize("zh-Hant-HK").numberFormatter({}),
  Globalize("zh-Hant-HK").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", other: "{0} MB" },
  },
);
Globalize.a788316408 = unitFormatterFn(
  Globalize("zh-Hant-HK").numberFormatter({}),
  Globalize("zh-Hant-HK").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", other: "{0} GB" },
  },
);
Globalize.b1682222089 = unitFormatterFn(
  Globalize("zh-Hant-MO").numberFormatter({}),
  Globalize("zh-Hant-MO").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}每{1}",
    unitProperties: { displayName: "位元組", other: "{0} 位元組" },
  },
);
Globalize.b595137292 = unitFormatterFn(
  Globalize("zh-Hant-MO").numberFormatter({}),
  Globalize("zh-Hant-MO").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "kB", other: "{0} kB" },
  },
);
Globalize.b356749117 = unitFormatterFn(
  Globalize("zh-Hant-MO").numberFormatter({}),
  Globalize("zh-Hant-MO").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "MB", other: "{0} MB" },
  },
);
Globalize.b1707386695 = unitFormatterFn(
  Globalize("zh-Hant-MO").numberFormatter({}),
  Globalize("zh-Hant-MO").pluralGenerator({}),
  {
    compoundUnitPattern: "{0}/{1}",
    unitProperties: { displayName: "GB", other: "{0} GB" },
  },
);

export default Globalize;
