{
  "_comment": "[CI-LOCKED] El contingut d'aquest fitxer està bloquejat. Les aportacions són benvingudes, però la validació dels canvis es pot retardar.",
  "ACCESS_KEY_ALT": "Alt Maj $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control Opció $1",
  "ACTIVITY_BOT_SAID_ALT": "El bot $1 ha dit:",
  "ACTIVITY_YOU_SAID_ALT": "Heu dit:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Bot adjuntat:",
  "ACTIVITY_ERROR_BOX_TITLE": "Missatge d'error",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Feu clic per interactuar.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "El missatge és interactiu.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Un o més enllaços del missatge.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "L'enviament ha fallat.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Heu adjuntat:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 fitxer adjunt.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 fitxers adjunts.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 fitxers adjunts.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 fitxers adjunts.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 fitxers adjunts.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "L'enviament ha fallat. [Torna-ho a provar][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "S'està enviant",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "S'ha enviat a les $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Ara mateix",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Fa una hora",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Fa un minut",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Avui",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Ahir",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Error d'anàlisi de la targeta adaptativa",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Error de representació de la targeta adaptativa",
  "ATTACHMENT_AUDIO": "Un clip d’àudio.",
  "ATTACHMENT_CARD": "Una targeta: $1 $2 $3",
  "ATTACHMENT_FILE": "Un fitxer: $1",
  "ATTACHMENT_IMAGE": "Una imatge",
  "ATTACHMENT_TEXT": "Un text: $1",
  "ATTACHMENT_VIDEO": "Un clip de vídeo",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Següent",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Anterior",
  "CITATION_MODEL_DIALOG_ALT": "Cita",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Connectat",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "S'està connectant…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "No es pot connectar.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "S'ha produït una interrupció de la xarxa. S'està reconnectant…",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Error de representació. Consulteu la consola o poseu-vos en contacte amb el desenvolupador del bot.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "S'està trigant més de l'habitual per establir una connexió.",
  "CONNECTIVITY_STATUS_ALT": "Estat de la connectivitat: $1",
  "FILE_CONTENT_ALT": "\"$1\"",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Baixa el fitxer \"$1\"",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Baixa el fitxer \"$1\" amb una mida de $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "\"$1\" amb una mida de $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Introduïu una clau",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Accediu als elements del missatge",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Historial de xat",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Tecla Esc",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Missatge de sortida",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tecla de tabulació\nTecla Maj + tabulació",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Permet desplaçar-se entre els elements d'un missatge",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Tecla fletxa amunt\nTecla fletxa avall",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Permet desplaçar-se entre els missatges",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Introduïu una clau",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Executa l'acció",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tecla de tabulació\nTecla Maj + tabulació",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Permet desplaçar-se entre els elements de la finestra de xat",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Finestra del xat",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Tanca",
  "KEYBOARD_HELP_HEADER": "Controls de teclat",
  "MARKDOWN_EXTERNAL_LINK_ALT": "S’obre en una finestra nova; externa.",
  "RECEIPT_CARD_TAX": "Impost",
  "RECEIPT_CARD_TOTAL": "Total",
  "RECEIPT_CARD_VAT": "IVA",
  "REFERENCE_LIST_HEADER_ONE": "$1 referència",
  "REFERENCE_LIST_HEADER_FEW": "$1 referències",
  "REFERENCE_LIST_HEADER_MANY": "$1 referències",
  "REFERENCE_LIST_HEADER_OTHER": "$1 referències",
  "REFERENCE_LIST_HEADER_TWO": "$1 referències",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "No es pot enviar un missatge buit.",
  "SPEECH_INPUT_LISTENING": "Escoltant…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Micròfon desactivat",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Micròfon activat",
  "SPEECH_INPUT_STARTING": "Iniciant…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Següent",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Anterior",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Accions suggerides",
  "TEXT_INPUT_ALT": "Quadre d'entrada del missatge",
  "TEXT_INPUT_PLACEHOLDER": "Escriviu el missatge",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Envia",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Parlar",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Carrega el fitxer",
  "TOAST_ACCORDION_FEW": "$1 notificacions: feu clic aquí per veure detalls",
  "TOAST_ACCORDION_MANY": "$1 notificacions: feu clic aquí per veure detalls",
  "TOAST_ACCORDION_OTHER": "$1 notificacions: feu clic aquí per veure detalls",
  "TOAST_ACCORDION_TWO": "$1 notificacions: feu clic aquí per veure detalls",
  "TOAST_ALT_ERROR": "Error",
  "TOAST_ALT_INFO": "Informació",
  "TOAST_ALT_SUCCESS": "Correcta",
  "TOAST_ALT_WARN": "Advertiment",
  "TOAST_DISMISS_BUTTON": "Descarta",
  "TOAST_TITLE_ALT": "Notificació",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Historial de xat, premeu les tecles amunt/avall per desplaçar-vos.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "historial de xat",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "El missatge és interactiu. Premeu la tecla de tabulació entre 2 i 3 vegades per canviar a l'historial de xat. A continuació, feu clic al missatge per interactuar.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Un o més enllaços al missatge. Premeu la tecla de tabulació entre 2 i 3 vegades per canviar a l'historial de xat. A continuació, feu clic al missatge per interactuar.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "El missatge ha suggerit accions. Premeu la tecla de tabulació entre 2 i 3 vegades per seleccionar-les.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "El missatge ha suggerit accions. Premeu $1 per seleccionar-les.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "No s'ha pogut enviar el missatge.",
  "TRANSCRIPT_MORE_MESSAGES": "Més missatges",
  "TRANSCRIPT_NEW_MESSAGES": "Missatges nous",
  "TRANSCRIPT_TERMINATOR_TEXT": "Fi de l'historial de xat",
  "TYPING_INDICATOR_ALT": "S'està mostrant l'indicador d'escriptura",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 està escrivint.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 i altres persones estan escrivint.",
  "VOTE_DISLIKE_ALT": "No m'agrada",
  "VOTE_LIKE_ALT": "M'agrada"
}