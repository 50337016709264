{
  "_comment": "[CI-LOCKED] Вміст цього файлу заблоковано. Внески вітаються, але перевірка змін може зайняти певний час.",
  "ACCESS_KEY_ALT": "Alt + Shift + $1",
  "ACCESS_KEY_FOR_MAC_ALT": "Control + Option + $1",
  "ACTIVITY_BOT_SAID_ALT": "Бот $1 каже:",
  "ACTIVITY_YOU_SAID_ALT": "Ви кажете:",
  "ACTIVITY_BOT_ATTACHED_ALT": "Бот вкладає:",
  "ACTIVITY_ERROR_BOX_TITLE": "Повідомлення про помилку",
  "ACTIVITY_INTERACTIVE_FOOTNOTE_ALT": "Клацніть, щоб взаємодіяти.",
  "ACTIVITY_INTERACTIVE_REASON_INTERACTIVE_CONTENT_ALT": "Повідомлення інтерактивне.",
  "ACTIVITY_INTERACTIVE_REASON_LINK_ALT": "Повідомлення містить одне або кілька посилань.",
  "ACTIVITY_INTERACTIVE_REASON_SEND_FAILED_ALT": "Помилка надсилання.",
  "ACTIVITY_YOU_ATTACHED_ALT": "Ви вкладаєте:",
  "ACTIVITY_NUM_ATTACHMENTS_ONE_ALT": "1 вкладення.",
  "ACTIVITY_NUM_ATTACHMENTS_FEW_ALT": "$1 вкладення.",
  "ACTIVITY_NUM_ATTACHMENTS_MANY_ALT": "$1 вкладення.",
  "ACTIVITY_NUM_ATTACHMENTS_OTHER_ALT": "$1 вкладення.",
  "ACTIVITY_NUM_ATTACHMENTS_TWO_ALT": "$1 вкладення.",
  "ACTIVITY_STATUS_SEND_FAILED_RETRY": "Не вдалося надіслати. [Повторити][RETRY].",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENDING": "Надсилання",
  "ACTIVITY_STATUS_SEND_STATUS_ALT_SENT_AT": "Надіслано о $1",
  "ACTIVITY_STATUS_TIMESTAMP_JUST_NOW": "Щойно",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_HOUR_AGO": "Годину тому",
  "ACTIVITY_STATUS_TIMESTAMP_ONE_MINUTE_AGO": "Хвилину тому",
  "ACTIVITY_STATUS_TIMESTAMP_TODAY": "Сьогодні",
  "ACTIVITY_STATUS_TIMESTAMP_YESTERDAY": "Учора",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_PARSE": "Помилка аналізу адаптивної картки",
  "ADAPTIVE_CARD_ERROR_BOX_TITLE_RENDER": "Помилка відображення адаптивної картки",
  "ATTACHMENT_AUDIO": "Аудіокліп.",
  "ATTACHMENT_CARD": "Картка: $1 $2 $3",
  "ATTACHMENT_FILE": "Файл: $1",
  "ATTACHMENT_IMAGE": "Зображення",
  "ATTACHMENT_TEXT": "Текст: $1",
  "ATTACHMENT_VIDEO": "Відеокліп",
  "CAROUSEL_FLIPPER_NEXT_ALT": "Далі",
  "CAROUSEL_FLIPPER_PREVIOUS_ALT": "Назад",
  "CITATION_MODEL_DIALOG_ALT": "Цитування",
  "CONNECTIVITY_STATUS_ALT_CONNECTED": "Підключено",
  "CONNECTIVITY_STATUS_ALT_CONNECTING": "Підключення…",
  "CONNECTIVITY_STATUS_ALT_FATAL": "Не вдалося підключити.",
  "CONNECTIVITY_STATUS_ALT_RECONNECTING": "Підключення до мережі перервано. Відновлення підключення...",
  "CONNECTIVITY_STATUS_ALT_RENDER_ERROR": "Помилка відображення. Перевірте консоль або зверніться до розробника бота.",
  "CONNECTIVITY_STATUS_ALT_SLOW_CONNECTION": "Підключення триває більше часу, ніж зазвичай.",
  "CONNECTIVITY_STATUS_ALT": "Стан підключення: $1",
  "FILE_CONTENT_ALT": "'$1'",
  "FILE_CONTENT_DOWNLOADABLE_ALT": "Завантажити файл '$1'",
  "FILE_CONTENT_DOWNLOADABLE_WITH_SIZE_ALT": "Завантажити файл \"$1\" завбільшки $2",
  "FILE_CONTENT_WITH_SIZE_ALT": "\"$1\" завбільшки $2",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_BODY": "Enter",
  "KEYBOARD_HELP_CHAT_HISTORY_ACCESS_ITEMS_IN_MESSAGE_HEADER": "Перейти до елементів у повідомленні",
  "KEYBOARD_HELP_CHAT_HISTORY_HEADER": "Історія чату",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_BODY": "Esc",
  "KEYBOARD_HELP_CHAT_HISTORY_LEAVE_MESSAGE_HEADER": "Повідомлення про вихід",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_BODY": "Tab\nShift+Tab",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_ITEMS_HEADER": "Перейти між елементами повідомлення",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_BODY": "Стрілка вгору\nСтрілка вниз",
  "KEYBOARD_HELP_CHAT_HISTORY_MOVE_BETWEEN_MESSAGES_HEADER": "Перейти між повідомленнями",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_BODY": "Enter",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_DO_ACTION_HEADER": "Виконати дію",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_BODY": "Tab\nShift+Tab",
  "KEYBOARD_HELP_CHAT_WINDOW_BODY_MOVE_BETWEEN_ITEMS_HEADER": "Перейти між елементами у вікні чату",
  "KEYBOARD_HELP_CHAT_WINDOW_HEADER": "Вікно чату",
  "KEYBOARD_HELP_CLOSE_BUTTON_ALT": "Закрити",
  "KEYBOARD_HELP_HEADER": "Елементи керування клавіатурою",
  "MARKDOWN_EXTERNAL_LINK_ALT": "Відкривається в новому вікні; зовнішнє.",
  "RECEIPT_CARD_TAX": "Податок",
  "RECEIPT_CARD_TOTAL": "Усього",
  "RECEIPT_CARD_VAT": "ПДВ",
  "REFERENCE_LIST_HEADER_ONE": "$1 посилання",
  "REFERENCE_LIST_HEADER_FEW": "$1 посилань",
  "REFERENCE_LIST_HEADER_MANY": "$1 посилань",
  "REFERENCE_LIST_HEADER_OTHER": "$1 посилання",
  "REFERENCE_LIST_HEADER_TWO": "$1 посилання",
  "SEND_BOX_IS_EMPTY_TOOLTIP_ALT": "Не можна надсилати пусті повідомлення.",
  "SPEECH_INPUT_LISTENING": "Слухання…",
  "SPEECH_INPUT_MICROPHONE_BUTTON_CLOSE_ALT": "Мікрофон вимкнуто",
  "SPEECH_INPUT_MICROPHONE_BUTTON_OPEN_ALT": "Мікрофон увімкнуто",
  "SPEECH_INPUT_STARTING": "Початок…",
  "SUGGESTED_ACTIONS_FLIPPER_NEXT_ALT": "Далі",
  "SUGGESTED_ACTIONS_FLIPPER_PREVIOUS_ALT": "Назад",
  "SUGGESTED_ACTIONS_LABEL_ALT": "Рекомендовані дії",
  "TEXT_INPUT_ALT": "Вікно вводу повідомлень",
  "TEXT_INPUT_PLACEHOLDER": "Введіть повідомлення",
  "TEXT_INPUT_SEND_BUTTON_ALT": "Надіслати",
  "TEXT_INPUT_SPEAK_BUTTON_ALT": "Говорити",
  "TEXT_INPUT_UPLOAD_BUTTON_ALT": "Передати файл",
  "TOAST_ACCORDION_FEW": "Сповіщень: $1. Натисніть нижче, щоб побачити відомості",
  "TOAST_ACCORDION_MANY": "Сповіщень: $1. Натисніть нижче, щоб побачити відомості",
  "TOAST_ACCORDION_OTHER": "Сповіщень: $1. Натисніть нижче, щоб побачити відомості",
  "TOAST_ACCORDION_TWO": "Сповіщень: $1. Натисніть нижче, щоб побачити відомості",
  "TOAST_ALT_ERROR": "Помилка",
  "TOAST_ALT_INFO": "Відомості",
  "TOAST_ALT_SUCCESS": "Виконано",
  "TOAST_ALT_WARN": "Попередження!",
  "TOAST_DISMISS_BUTTON": "Закрити",
  "TOAST_TITLE_ALT": "Сповіщення",
  "TRANSCRIPT_ARIA_LABEL_ALT": "Історія чату: натискайте клавіші зі стрілками, щоб переходити між елементами.",
  "TRANSCRIPT_ARIA_ROLE_ALT": "історія чату",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_LABEL_ALT": "Це інтерактивне повідомлення. Натисніть клавіші Shift+Tab 2–3 рази, щоб перейди до історії чату. Потім клацніть повідомлення, щоб взаємодіяти.",
  "TRANSCRIPT_LIVE_REGION_INTERACTIVE_WITH_LINKS_LABEL_ALT": "Повідомлення містить одне або кілька посилань. Натисніть клавіші Shift+Tab 2–3 рази, щоб перейди до історії чату. Потім клацніть повідомлення, щоб взаємодіяти.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_LABEL_ALT": "Повідомлення містить рекомендовані дії. Натисніть клавіші Shift + Tab 2–3 рази, щоб вибрати їх.",
  "TRANSCRIPT_LIVE_REGION_SUGGESTED_ACTIONS_WITH_ACCESS_KEY_LABEL_ALT": "Повідомлення містить рекомендовані дії. Натисніть клавішу $1, щоб вибрати їх.",
  "TRANSCRIPT_LIVE_REGION_SEND_FAILED_ALT": "Не вдалося надіслати повідомлення.",
  "TRANSCRIPT_MORE_MESSAGES": "Інші повідомлення",
  "TRANSCRIPT_NEW_MESSAGES": "Нові повідомлення",
  "TRANSCRIPT_TERMINATOR_TEXT": "Кінець історії чату",
  "TYPING_INDICATOR_ALT": "Показує індикатор набору",
  "TYPING_INDICATOR_SINGLE_TEXT": "$1 вводить повідомлення.",
  "TYPING_INDICATOR_MULTIPLE_TEXT": "$1 та інші вводять повідомлення.",
  "VOTE_DISLIKE_ALT": "Не подобається",
  "VOTE_LIKE_ALT": "Подобається"
}