import { getMaximizeBtnTranslated } from '../services/languages/translateUI';

const MaximizeChatButton = ({ maximizeChatBox, isActive, newMessage, chatLanguage }) => {
    const buttonLabel = getMaximizeBtnTranslated(chatLanguage);
    return (
        <button className="maximize" onClick={maximizeChatBox} aria-label="Help" title="Help">
            <div>
                <span className={isActive ? 'ms-Icon ms-Icon--MessageFill' : 'ms-Icon ms-Icon--Message'} />
                {newMessage && <span className="ms-Icon ms-Icon--CircleShapeSolid red-dot" />}
                <span className="call-to-action">{buttonLabel}</span>
            </div>
        </button>
    );
}

export default MaximizeChatButton;